import {
  copyObjectWithoutNulls
} from "../../util/object-utils";
import { BaseSelector } from "./base-selector";
import {
  ISelectorProperties
} from "./selector.properties.interface";

export class StyleSelector extends BaseSelector {

  protected _styles: { [type: string]: any; } = {};

  public override styles(properties: ISelectorProperties): {[type: string]: any} {
    if (!properties || !properties.id) {
      // Can't do anything so just return an empty array of styles.
      return [];
    }
    if (properties.id === "*") {
      return copyObjectWithoutNulls(this.config);
    }
    const lookupKey = properties.id;
    if (this._styles.hasOwnProperty(lookupKey)) {
      return copyObjectWithoutNulls(this._styles[lookupKey]);
    }
    const tStyle = this.config[lookupKey];
    this._styles[lookupKey] = tStyle;
    return copyObjectWithoutNulls(this._styles[lookupKey]);
  }
}
