import { TranslateService } from "@ngx-translate/core";
import * as Config from "../../util/config.constants";
import { formattedTimeString } from "../../util/utils";
import { LocaleService } from "../locale/locale.service";
import { Logger } from "../logging/logger";
import { BusinessDateChangeBasedFormatter } from "./business-date-change-based.formatter";
import { BusinessDateFormatter } from "./business-date.formatter";
import { DateFormatter } from "./date-formatter";
import { DefaultExtentFormatter } from "./default-extent-formatter";
import { DefaultFormatter } from "./default-formatter";
import { RoundedExtentFormatter } from "./rounded-extent-formatter";
import { SimpleValueFormatter } from "./simple-value-formatter";
import { ThresholdBasedFormatter } from "./threshold-based-formatter";
import { ValueBasedFormatter } from "./value-based-formatter";
import { FunctionBasedFormatter } from "./function-based-formatter";
import { PropertyBasedFormatter } from "./property-based-formatter";
import { CompositeFormatter } from "./composite-formatter";
import { LoggingService } from "../logging/logging.service";
import * as i0 from "@angular/core";
import * as i1 from "../locale/locale.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../logging/logging.service";
const VALID_CATEGORIES = [Config.FORMATTER, Config.EXTENT];
const LOG_NAMESPACE = "services.formatter";
export class FormatterService {
    constructor(_localeService, _translateService, _loggingService) {
        this._localeService = _localeService;
        this._translateService = _translateService;
        this._loggingService = _loggingService;
        this._formatters = {};
        this._formatterConfig = {
            default: {
                type: Config.DEFAULT
            }
        };
        this._formatterClassMap = {
            dateFormat: DateFormatter,
            businessDateFormat: BusinessDateFormatter,
            businessDateChangeBasedFormat: BusinessDateChangeBasedFormatter,
            propertyBasedFormatter: PropertyBasedFormatter,
            compositeFormatter: CompositeFormatter,
            simpleValueFormat: SimpleValueFormatter,
            thresholdBasedFormatter: ThresholdBasedFormatter,
            valueBasedFormatter: ValueBasedFormatter,
            functionBasedFormatter: FunctionBasedFormatter,
            default: DefaultFormatter
        };
        this._extentFormatters = {};
        this._extentFormatterConfig = {
            default: {
                type: Config.DEFAULT
            }
        };
        this._extentFormatterClassMap = {
            roundedExtent: RoundedExtentFormatter,
            default: DefaultExtentFormatter
        };
        this._logger = new Logger(LOG_NAMESPACE, _loggingService);
    }
    init(formatterConfig) {
        this._logger.info(formattedTimeString() + " Initializing Formatter Service...");
        this._logger.info("Formatter service starts with formatterconfig of count '" + formatterConfig.length + "'", { config: formatterConfig });
        for (const fmt of formatterConfig) {
            const category = fmt.category || Config.FORMATTER;
            this._validateFormatterCategory(category);
            const fmtConfig = {
                id: fmt.id,
                type: fmt.type,
                // tslint:disable-next-line:object-literal-shorthand
                category: category
            };
            if (fmt.config) {
                fmtConfig[Config.CONFIG] = JSON.parse(fmt[Config.CONFIG]);
                fmtConfig[Config.CONFIG][Config.ID] = fmt.id;
            }
            this._getFormatterConfig(category)[fmt.id] = fmtConfig;
        }
        this._logger.info("Completed Setting Config to Formatter Service " + formattedTimeString());
    }
    /*
    * This function will return the instance of the IFormatter.
    * @param string - data format type
    * @return - IFormatter instance
    */
    formatter(code) {
        code = null != code ? code : Config.DEFAULT;
        return this._instantiateFormatterBasedOnConfiguredCode(code);
    }
    formatters(codes) {
        return codes.map((code) => {
            code = null != code ? code : Config.DEFAULT;
            return this._instantiateFormatterBasedOnConfiguredCode(code);
        });
    }
    applyFormatters(formatters, value) {
        formatters.forEach((formatter) => {
            value = formatter.formatData(value);
        });
        return value;
    }
    /*
    * This function will return the instance of the IExtent.
    * @param string - data format type
    * @return - IExtent instance
    */
    extent(code) {
        if (!code || code === "") {
            code = Config.DEFAULT;
        }
        return this._instantiateExtentFormatter(code);
    }
    instantiateFormatter(formatterConfig) {
        const formatterTypeName = formatterConfig[Config.TYPE];
        const formatterType = this._formatterTypeFromName(formatterTypeName);
        const logger = new Logger(formatterType.name, this._loggingService);
        this._logger.debug("Instantiating formatter service of type " + formatterTypeName);
        const instance = new formatterType(this._localeService.locale, formatterConfig.config, this._translateService, this, logger);
        instance.formatData = instance.formatData.bind(instance);
        return instance;
    }
    /*
    * This function will return the instance of IFormatter  by format type.
    * @param string - formatter type
    * @return - IFormatter
    */
    _instantiateFormatterBasedOnConfiguredCode(code) {
        if (null != this._formatters[code]) {
            return this._formatters[code];
        }
        const formatterConfig = this._formatterConfig[code];
        if (null == formatterConfig) {
            throw new Error("Formatter config '" + code + "' not found!");
        }
        const instance = this.instantiateFormatter(formatterConfig);
        this._formatters[code] = instance;
        return instance;
    }
    /*
    * This function will return the instance IExtent by extent format type.
    * @param string - extent formatter type
    * @return - IExtent
    */
    _instantiateExtentFormatter(code) {
        if (null != this._extentFormatters[code]) {
            return this._extentFormatters[code];
        }
        const formatterConfig = this._extentFormatterConfig[code];
        if (null == formatterConfig) {
            throw new Error("Extent formatter config '" + code + "' not found!");
        }
        const type = formatterConfig.type;
        if (null == this._extentFormatterClassMap[type]) {
            throw new TypeError("Type '" + type + "' is not a registered extent formatter Type!");
        }
        const logWrapper = new Logger(this._extentFormatterClassMap[type].name, this._loggingService);
        const instance = new this._extentFormatterClassMap[type](formatterConfig.config, logWrapper);
        this._extentFormatters[type] = instance;
        return instance;
    }
    _getFormatterConfig(category) {
        switch (category) {
            case Config.FORMATTER:
                return this._formatterConfig;
            case Config.EXTENT:
                return this._extentFormatterConfig;
        }
    }
    _formatterTypeFromName(type) {
        if (null == this._formatterClassMap[type]) {
            throw new TypeError("Type '" + type + "' is not a registered formatter Type!");
        }
        else {
            return this._formatterClassMap[type];
        }
    }
    _validateFormatterCategory(category) {
        if ((VALID_CATEGORIES.indexOf(category) === -1)) {
            // tslint:disable-next-line:max-line-length
            throw new Error("Category '" + category + "' is not a valid category.  Categories must come from the the list [" + VALID_CATEGORIES.join(", ") + "] (not case sensitive)");
        }
    }
}
FormatterService.ɵfac = function FormatterService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || FormatterService)(i0.ɵɵinject(i1.LocaleService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.LoggingService)); };
FormatterService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FormatterService, factory: FormatterService.ɵfac });
