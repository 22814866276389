import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty, Any } from "json2typescript";
let SelectorConfig = class SelectorConfig {
    constructor() {
        this.token = undefined;
        this.checkPath = undefined;
        this.path = undefined;
        this.subPath = "";
        this.default = null;
        this.value = null;
        this.function = null;
        this.includePriorPeriods = false;
        this.periodType = "YearAgoWeek";
        this.pathIndex = 0;
        this.mappings = null;
    }
};
__decorate([
    JsonProperty("token", String, false),
    __metadata("design:type", String)
], SelectorConfig.prototype, "token", void 0);
__decorate([
    JsonProperty("check-path", String, true),
    __metadata("design:type", String)
], SelectorConfig.prototype, "checkPath", void 0);
__decorate([
    JsonProperty("path", String, false),
    __metadata("design:type", String)
], SelectorConfig.prototype, "path", void 0);
__decorate([
    JsonProperty("sub-path", String, true),
    __metadata("design:type", String)
], SelectorConfig.prototype, "subPath", void 0);
__decorate([
    JsonProperty("default", Any, true),
    __metadata("design:type", Object)
], SelectorConfig.prototype, "default", void 0);
__decorate([
    JsonProperty("value", Any, true),
    __metadata("design:type", Object)
], SelectorConfig.prototype, "value", void 0);
__decorate([
    JsonProperty("function", String, true),
    __metadata("design:type", String)
], SelectorConfig.prototype, "function", void 0);
__decorate([
    JsonProperty("include-prior-periods", Boolean, true),
    __metadata("design:type", Boolean)
], SelectorConfig.prototype, "includePriorPeriods", void 0);
__decorate([
    JsonProperty("period-type", String, true),
    __metadata("design:type", String)
], SelectorConfig.prototype, "periodType", void 0);
__decorate([
    JsonProperty("path-index", Number, true),
    __metadata("design:type", Number)
], SelectorConfig.prototype, "pathIndex", void 0);
__decorate([
    JsonProperty("mappings", Any, true),
    __metadata("design:type", Object)
], SelectorConfig.prototype, "mappings", void 0);
SelectorConfig = __decorate([
    JsonObject("SelectorConfig")
], SelectorConfig);
export { SelectorConfig };
