import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from "json2typescript";
import * as GQL from "../../util/graphql-tags";
import { copyObjectWithoutGraphQLMetaKeys } from "../../util/object-utils";
import { Reference } from "../reference.model";
let Event = class Event {
    constructor() {
        this.guid = undefined;
        this.id = undefined;
        this.nameRef = undefined;
        this.year = undefined;
        this.month = undefined;
        this.quarter = undefined;
        this.week = undefined;
        this.day = undefined;
    }
    static ToValidContextObject(toClean) {
        if (null == toClean) {
            return null;
        }
        return copyObjectWithoutGraphQLMetaKeys(toClean);
    }
    static ToValidInputObject(toStrip) {
        if (null == toStrip) {
            return null;
        }
        const ret = copyObjectWithoutGraphQLMetaKeys(toStrip);
        if (null != ret) {
            delete ret[GQL.REFERENCE];
            delete ret[GQL.SCALAR_NAME_REF];
        }
        return ret;
    }
};
__decorate([
    JsonProperty(GQL.SCALAR_GUID, String, true),
    __metadata("design:type", String)
], Event.prototype, "guid", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_ID, String, false),
    __metadata("design:type", String)
], Event.prototype, "id", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_NAME_REF, String, true),
    __metadata("design:type", String)
], Event.prototype, "nameRef", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_YEAR, Number, false),
    __metadata("design:type", Number)
], Event.prototype, "year", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_MONTH, Number, true),
    __metadata("design:type", Number)
], Event.prototype, "month", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_QUARTER, Number, true),
    __metadata("design:type", Number)
], Event.prototype, "quarter", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_WEEK, Number, true),
    __metadata("design:type", Number)
], Event.prototype, "week", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_DAY, Number, true),
    __metadata("design:type", Number)
], Event.prototype, "day", void 0);
__decorate([
    JsonProperty(GQL.REFERENCES, Reference, true),
    __metadata("design:type", Reference)
], Event.prototype, "Reference", void 0);
Event = __decorate([
    JsonObject("Event")
], Event);
export { Event };
