import {
  Alert
} from "../../model/alert/alert.model";
import {
  AlertsUXFacade
} from "../../model/alert/alerts-ux-facade";
import {
  object2String
} from "../../util/object-utils";
import {
  COLON_SPACE,
  SEMI_COLON,
  SPACE
} from "../../util/string-constants";
import { TooltipBase } from "./base-tooltip";

const _ALERTS = "alerts";
const _NAME = "name";
const _TYPE = "type";

const _DEFAULT_MAX_ALERTS_IN_TOOLTIP = 3;

const _CUSTOM_POPUP_STYLE = "custom-popup-styles";
const _TEXT_CONTAINER_STYLE = "container-style";
const _TEXT_STYLE = "text-style";
const _LEAFLET_STYLES = "leaflet-styles";

const _COLOUR_WHITE = "#fff";
const _INLINE_ALERTS_ICON_STYLE = "line-height: 1; width: 24px; height: 24px; text-align: center; border: 1px solid; padding-top: 5px;";
const _INLINE_ALERTS_TEXT_STYLE = "font-size: 14px; line-height: 1.1; padding-left: 5px;";

export class MapTooltip extends TooltipBase {

  public override generateTooltipContent(data: any, params?: any): string {
    const uxFacade = params as AlertsUXFacade;
    this._logService.debug("Generating Map Tooltip of data " , {tooltipData: data, parameter: params});

    const locationName = data[_NAME];
    const locationTypeTranslatedName = this._translateService.instant(data[_TYPE].nameRef);
    const textContinerStyle = object2String(this._config[_CUSTOM_POPUP_STYLE][_TEXT_CONTAINER_STYLE], COLON_SPACE, SEMI_COLON);
    const textStyle = object2String(this._config[_CUSTOM_POPUP_STYLE][_TEXT_STYLE], COLON_SPACE, SEMI_COLON);
    let popupHTML: string = `
    <table style="width: 270px; max-width: 270px;">
      <tbody style="${textContinerStyle}">
        <tr>
          <td colspan="2" style="padding-bottom: 7px !important; ${textStyle}">
            <div>${locationName}</div>
            <div style="font-size: 11px; margin-left: 2px;">${locationTypeTranslatedName}</div>
          </td>
        </tr>
    `;

    if (!this._config["showAlerts"] || data[_ALERTS] === undefined) {
      popupHTML += '</tbody></table>';
      return popupHTML;
    }

    const alertsTitle = this._translateService.instant(this._config["translations"].alerts);

    const maxAlertsInTooltip = this._config["maxAlertsInTooltip"] || _DEFAULT_MAX_ALERTS_IN_TOOLTIP;
    const alerts = data[_ALERTS];
    const alertsToRender = Math.min(alerts.length, maxAlertsInTooltip);
    popupHTML += `<tr><td colspan="2" style="padding-bottom: 4px !important; ${textStyle}">${alertsTitle}</td></tr>`;
    for (let i = 0; i < alertsToRender; i++) {
      const alert: Alert = alerts[i];

      const containerStyles = object2String(uxFacade.alertContainerStyles(alert), COLON_SPACE, SEMI_COLON);
      const textStyles = object2String(uxFacade.alertTextStyles(alert), COLON_SPACE, SEMI_COLON);
      const icon = uxFacade.alertIcon(alert);
      const alertTitle = uxFacade.alertTitle(alert);
      const alertBody = uxFacade.alertBody(alert);

      popupHTML += `
      <tr>
        <td style="width: 26px;">
          <div style="${_INLINE_ALERTS_ICON_STYLE} ${containerStyles}">
            <div><i style="color: ${_COLOUR_WHITE};" class="fa ${icon}"></i></div>
          </div>
        </td>
        <td nowrap="wrap" width="233px" style="${textStyles}; ${_INLINE_ALERTS_TEXT_STYLE}">
          <span >${alertTitle}</span>
        </td>
      </tr>`;
    }

    const showMoreAlertsMessage = alerts.length > maxAlertsInTooltip;
    if (showMoreAlertsMessage) {
      const moreAlertsMessage = this._translateService.instant(
        this._config["translations"].moreAlerts,
        {
          alertCount: alerts.length - maxAlertsInTooltip
        }
      );
      popupHTML +=
      // tslint:disable-next-line:max-line-length
      `<tr>
        <td></td>
        <td nowrap="wrap" width="233px" style="padding-left: 4px; padding-top: 4px; font-size: 11px; font-style: italic; color:${_COLOUR_WHITE};">
          ${moreAlertsMessage}
        </td>
      </tr>`;
    }

    popupHTML += '</tbody></table>';
    return popupHTML;
  }

  public override getLeafletStyles(): {[type: string]: any} {
    return this._config[_LEAFLET_STYLES];
  }

  public override getMapToolTipProperties(): {[type: string]: any} {
    return {showAlerts: this._config["showAlerts"], showCloseButton: this._config["showCloseButton"]};
  }
}
