import { TranslateService } from "@ngx-translate/core";
import { FeatureKey } from "../../model/user/feature-key.enum";
import { json2ts } from "../../util/json-2ts";
import { COMMA_SPACE } from "../../util/string-constants";
import { formattedTimeString } from "../../util/utils";
import { Logger } from "../logging/logger";
import { LoggingService } from "../logging/logging.service";
import { SupportServiceConfiguration } from "./support-configuration.model";
import { ZendeskSupportWidget } from "./zendesk/zendesk-support-widget";
import * as i0 from "@angular/core";
import * as i1 from "../logging/logging.service";
import * as i2 from "@ngx-translate/core";
const LOG_NAMESPACE = "services.support";
const WIDGET_CLASS_MAP = {
    zendesk: ZendeskSupportWidget
};
export class SupportService {
    async init(supportServiceConfig) {
        this._logger.info(formattedTimeString() + " Initializing Support Service...");
        const sConfig = json2ts(JSON.parse(supportServiceConfig), SupportServiceConfiguration);
        const provider = sConfig.provider.toLowerCase();
        if (!(provider in WIDGET_CLASS_MAP)) {
            this._logger.info(`Provider '${provider}' is not registered.  Provider must be one of '${WIDGET_CLASS_MAP["keys"]().join(COMMA_SPACE)}'.  Not instantiating a support service.`);
            return;
        }
        if (null == this._supportWidget) {
            const clazz = WIDGET_CLASS_MAP[provider];
            const instance = new clazz();
            await instance.init(sConfig, this._translateService, this._loggingService);
            this._supportWidget = instance;
        }
    }
    async onAuthenticationComplete(userDetails) {
        if (null == this._supportWidget) {
            return;
        }
        this._userSupportWidgetFeatureEnabled = userDetails.profile.Features.feature(FeatureKey.ShowSupportWidget, false, false);
        if (this._userSupportWidgetFeatureEnabled) {
            await this._supportWidget.onAuthenticationComplete(userDetails);
        }
        else {
            this._logger.warn("User support widget feature is disabled.  No support widget will appear.");
        }
    }
    constructor(_loggingService, _translateService) {
        this._loggingService = _loggingService;
        this._translateService = _translateService;
        this._supportWidget = null;
        this._userSupportWidgetFeatureEnabled = true;
        this._logger = new Logger(LOG_NAMESPACE, _loggingService);
        this._logger.warn("Support Service instantiated");
    }
}
SupportService.ɵfac = function SupportService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || SupportService)(i0.ɵɵinject(i1.LoggingService), i0.ɵɵinject(i2.TranslateService)); };
SupportService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SupportService, factory: SupportService.ɵfac });
