import {
  APP_INITIALIZER,
  InjectionToken,
  ModuleWithProviders,
  NgModule
} from "@angular/core";
import { Level } from "../../../packages/ngx-toolkit/logger/level.model";

import {
  LoggingService
} from "./logging.service";
// import { setupLoggerDecorator } from "@ngx-toolkit/logger/esm2015/lib/logger.module";

export const LOGGING_LEVEL = new InjectionToken<Level>("LOGGING_LEVEL");

@NgModule()
export class LoggingModule {
  public static forRoot(level: Level = null): ModuleWithProviders<any> {
    return {
      ngModule: LoggingModule,
      providers: [
        {
          provide: LOGGING_LEVEL,
          useValue: level
        },
        {
          provide: LoggingService,
          useClass: LoggingService
        },
        // {
        //   provide: APP_INITIALIZER,
        //   useFactory: setupLoggerDecorator,
        //   deps: [LoggingService],
        //   multi: true
        // }
      ]
    };
  }
}
