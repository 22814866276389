import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from "json2typescript";
import { Series } from "../dashboard/components/config/series";
import * as GQL from "../util/graphql-tags";
import { DOT } from "../util/string-constants";
import { Entity } from "./entity.model";
import { LocationType } from "./location-type.model";
import { Location } from "./location.model";
import { NameTypeDefaults } from "./name-type.enum";
import { Person } from "./person.model";
import { Region } from "./region.model";
import { Snapshot } from "./snapshots/snapshot.model";
const _ID = "ID";
let Reference = class Reference {
    constructor() {
        this.Entity = null;
        this.Region = null;
        this.Location = null;
        this.LocationType = null;
        this.Person = null;
        this.Series = null;
        this.Snapshot = null;
        this._nameRef = null;
        this._rawScope = null;
        this._scope = null;
    }
    icon(translateService) {
        if (null == this._nameRef) {
            throw Error("Attempt to call icon method on a Reference object when the nameRef property was not set on that object!");
        }
        return translateService.instant(this._nameRef + NameTypeDefaults.Icon);
    }
    name(translateService, nameType) {
        if (null == this._nameRef) {
            throw Error("Attempt to call name method on a Reference object when the nameRef property was not set on that object!");
        }
        return translateService.instant(this._nameRef + nameType);
    }
    get referenceName() {
        return this._getRawScope().name;
    }
    scope(translateService, translations, nameType) {
        if (null !== this._scope) {
            return this._scope;
        }
        const rawScope = this._getRawScope();
        const translation = translations.Translation(rawScope.type);
        const prefix = translation.prefix;
        const suffix = translation.Suffixes.Suffix(nameType);
        const translationKey = prefix + rawScope.type + DOT + rawScope.id.toUpperCase() + suffix;
        const translatedScope = translateService.instant(translationKey);
        if (translatedScope === translationKey) {
            // No translation so just use the value we were given.
            this._scope = rawScope.name;
        }
        else {
            this._scope = translatedScope;
        }
        return this._scope;
    }
    toReferenceInput() {
        const rs = this._getRawScope();
        const ret = {
            type: rs.type,
            id: rs.id
        };
        return ret;
    }
    _getRawScope() {
        if (null !== this._rawScope) {
            return this._rawScope;
        }
        let scope = {};
        if (null !== this.Entity) {
            scope.id = this.Entity.id;
            scope.name = this.Entity.name;
            scope.type = GQL.ENTITY;
        }
        else if (null !== this.Region) {
            scope.id = this.Region.id;
            scope.name = this.Region.name;
            scope.type = GQL.REGION;
        }
        else if (null !== this.LocationType) {
            scope.id = this.LocationType.id;
            scope.name = this.LocationType.nameRef;
            scope.type = GQL.LOCATION_TYPE;
        }
        else if (null !== this.Location) {
            scope.id = this.Location.id;
            scope.name = this.Location.name;
            scope.type = GQL.LOCATION;
        }
        else if (null !== this.Person) {
            scope.id = this.Person.id;
            scope.name = this.Person.displayName;
            scope.type = GQL.PERSON;
        }
        else if (null !== this.Series) {
            scope.id = this.Series.id;
            scope.name = this.Series.name;
            scope.type = GQL.SERIES;
        }
        else if (null !== this.Snapshot) {
            scope.id = this.Snapshot.guid;
            scope.name = this.Snapshot.description;
            scope.type = GQL.SNAPSHOT;
        }
        else {
            scope = undefined;
        }
        if (scope !== undefined) {
            scope.translationKey = scope.type.toUpperCase();
            scope.contextKey = scope.type.toLowerCase() + _ID;
        }
        this._rawScope = scope;
        return this._rawScope;
    }
};
__decorate([
    JsonProperty(GQL.ENTITY, Entity, true),
    __metadata("design:type", Entity)
], Reference.prototype, "Entity", void 0);
__decorate([
    JsonProperty(GQL.REGION, Region, true),
    __metadata("design:type", Region)
], Reference.prototype, "Region", void 0);
__decorate([
    JsonProperty(GQL.LOCATION, Location, true),
    __metadata("design:type", Location)
], Reference.prototype, "Location", void 0);
__decorate([
    JsonProperty(GQL.LOCATION_TYPE, LocationType, true),
    __metadata("design:type", LocationType)
], Reference.prototype, "LocationType", void 0);
__decorate([
    JsonProperty(GQL.PERSON, Person, true),
    __metadata("design:type", Person)
], Reference.prototype, "Person", void 0);
__decorate([
    JsonProperty(GQL.SERIES, Series, true),
    __metadata("design:type", Series)
], Reference.prototype, "Series", void 0);
__decorate([
    JsonProperty(GQL.SNAPSHOT, Snapshot, true),
    __metadata("design:type", Snapshot)
], Reference.prototype, "Snapshot", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_NAME_REF, String, true),
    __metadata("design:type", String)
], Reference.prototype, "_nameRef", void 0);
Reference = __decorate([
    JsonObject("Reference")
], Reference);
export { Reference };
