import { __decorate, __metadata } from "tslib";
// tslint:disable:max-classes-per-file
import { JsonObject, JsonProperty } from "json2typescript";
import { ContextualQuery } from "./context-sensitive";
import { MutationTypeArrayConverter } from "./mutation-type.converter";
let DataRegistrationConfig = class DataRegistrationConfig {
    constructor() {
        this.refreshOnMutationTypes = [];
        this.default = null;
        this.contextualQueries = null;
    }
};
__decorate([
    JsonProperty("refresh-on-mutation-types", MutationTypeArrayConverter, true),
    __metadata("design:type", Array)
], DataRegistrationConfig.prototype, "refreshOnMutationTypes", void 0);
__decorate([
    JsonProperty("default", ContextualQuery, true),
    __metadata("design:type", ContextualQuery)
], DataRegistrationConfig.prototype, "default", void 0);
__decorate([
    JsonProperty("context-sensitive", [ContextualQuery], true),
    __metadata("design:type", Array)
], DataRegistrationConfig.prototype, "contextualQueries", void 0);
DataRegistrationConfig = __decorate([
    JsonObject("DataRegistrationConfig")
], DataRegistrationConfig);
export { DataRegistrationConfig };
