import { AssertionError } from "../../util/assertion-error";
import {
  JsonConverter,
  JsonCustomConvert,
  JsonObject
} from "json2typescript";

import { SettingKey } from "./setting-key.enum";
import * as constants from "../../util/string-constants";

@JsonObject("Settings")
export class Settings {

  private _data: any;

  constructor(data: any = {}) {
    try {
      this._data = JSON.parse(data);
    } catch (e) {
      this._data = data;
    }
  }

  public serialize(): any {
    return this._data || {};
  }

  public setting(key: SettingKey, throwOnMissing: boolean = true, defaultValueIfMissing: any = null, keySuffix: string = null): any {
    const suffixedKey: string = key + (null != keySuffix ? constants.DOT + keySuffix : "");
    if (null != this._data && suffixedKey in this._data) {
      return this._data[suffixedKey];
    } else {
      if (throwOnMissing) {
        throw new AssertionError(
          {
            message: `Key '${suffixedKey}' was not present in the settings.`,
            expected: "A value",
            actual: "No value"
          }
        );
      } else {
        return defaultValueIfMissing;
      }
    }
  }

}

// tslint:disable-next-line: max-classes-per-file
@JsonConverter
export class SettingsConverter implements JsonCustomConvert<Settings> {
  public serialize(data: Settings): any {
    return data ? data.serialize() : null;
  }

  public deserialize(val: any): Settings {
    if (null == val) {
      return null;
    }
    return new Settings(val);
  }
}
