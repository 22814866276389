import { Actions, createEffect, ofType } from "@ngrx/effects";
import { DataService } from "../services/data/data.service";
import { Noop } from "./effects";
import { MUTATION_SUCCESSFUL } from "./mutation.actions";
import { resetApolloClientStore } from '../services/data/apollo-client';
import { switchMap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../services/data/data.service";
const _LOG_ID = "mutation.effects";
export class MutationEffects {
    constructor(_action$, _dataService) {
        this._action$ = _action$;
        this._dataService = _dataService;
        this.mutationSuccessful$ = createEffect(() => this._action$
            .pipe(ofType(MUTATION_SUCCESSFUL), switchMap((action) => {
            return this._refreshAfterMutation(action);
        })));
    }
    _refreshAfterMutation(action) {
        this._clearClientCache();
        this._dataService.refreshAfterMutation(...action.payload);
        return Promise.resolve(new Noop());
    }
    async _clearClientCache() {
        try {
            const resetOK = await resetApolloClientStore();
            if (resetOK) {
                console.log("APOLLO CLIENT STORE SUCCESSFULLY RESET");
            }
            else {
                console.log("APOLLO CLIENT STORE RESET DID NOT SUCCEED!");
            }
        }
        catch (err) {
            console.log(`APOLLO CLIENT STORE RESET DID NOT SUCCEED!:

      ${err}`);
            return new Noop();
        }
    }
}
MutationEffects.ɵfac = function MutationEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || MutationEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.DataService)); };
MutationEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: MutationEffects, factory: MutationEffects.ɵfac });
