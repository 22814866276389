import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as fromRouterStore from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import * as jp from "jsonpath";
import { switchMap, withLatestFrom } from "rxjs/operators";
import { AppRoutes } from "../approutes.enum";
import { LayoutRoutes } from "../layout/layoutroutes.enum";
import { BusinessDateRange } from "../model/business-dates/business-date-range.model";
import { Event } from "../model/events/event.model";
import { DashboardService } from "../services/dashboard/dashboard.service";
import { Logger } from "../services/logging/logger";
import { LoggingService } from "../services/logging/logging.service";
import * as GQL from "../util/graphql-tags";
import { SLASH, URL_PARAM_START, LS_PREFIX } from "../util/string-constants";
import { Noop } from "./effects";
import { BusinessDateRangeAndTemporalAggregationChanged, SAVE_TEMPORAL_CONTEXT, SaveTemporalContext, RESTORE_TEMPORAL_CONTEXT } from "./temporal.actions";
import { DASHBOARD_ACTIVATED, DASHBOARD_DEACTIVATED, DashboardActivated, DashboardDeactivated } from "./ux.actions";
import { DateType, TemporalAggregation } from "../model/business-dates/temporal-aggregation.enum";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "@ngrx/store";
import * as i3 from "../services/logging/logging.service";
import * as i4 from "../services/dashboard/dashboard.service";
const LOG_NAMESPACE = "effects.ux";
const _DASHBOARD_URL_PATH = SLASH + AppRoutes.Application + SLASH + LayoutRoutes.Dashboard + SLASH;
const _DASHBOARD_URL_PATH_LENGTH = _DASHBOARD_URL_PATH.length;
const _SAVE_TEMPORAL_CONTEXT_LS_KEY = LS_PREFIX + "temporal.saved";
export class UXEffects {
    async _handleStateChange(dashboardID, context, jsonPathSuffix) {
        const spec = await this._dashboardService.dashboardSpecification(dashboardID);
        const scConf = jp.query(spec, `$.${GQL.ON_STATE_CHANGE}.${jsonPathSuffix}`);
        if (scConf.length === 0) {
            return new Noop();
        }
        const stateChangeDetail = scConf[0];
        let newRange = BusinessDateRange.clone(context.temporal.range);
        let newAggregation;
        let dateType;
        if (null != stateChangeDetail) {
            if (null != stateChangeDetail.Actions) {
                try {
                    const actions = stateChangeDetail.Actions.map((action) => JSON.parse(action));
                    actions.forEach((action) => {
                        this._store$.dispatch(action);
                    });
                }
                catch (err) {
                    this._logger.error("Error in running Actions", err);
                }
                return;
            }
            else if (true === stateChangeDetail.clearEvents) {
                // The other side of the horrible temporary hack to make switching contexts work.
                // This will eventually be done by snapshots!
                this._restoreTemporalContextToLocalStorage(context);
                return;
            }
            else if (null != stateChangeDetail.ActivateEvent) {
                this._saveTemporalContextToLocalStorage(dashboardID, context);
                const activateEventDetails = stateChangeDetail.ActivateEvent;
                const activateEvent = Event.ToValidInputObject(activateEventDetails.Event);
                if (newRange[GQL.FROM]) {
                    newRange[GQL.FROM][GQL.EVENT] = activateEvent;
                    delete newRange[GQL.LAST];
                }
                if (newRange[GQL.TO]) {
                    newRange[GQL.TO][GQL.EVENT] = activateEvent;
                    delete newRange[GQL.NEXT];
                }
                newAggregation = activateEventDetails.aggregation || TemporalAggregation.Year;
                dateType = activateEventDetails.dateType || DateType.DEFAULT;
            }
            this._logger.warn("Have changed business date range to this: ", newRange);
            const _action = new BusinessDateRangeAndTemporalAggregationChanged(newRange, newAggregation, undefined, undefined, dateType);
            this._store$.dispatch(_action);
        }
    }
    _getSavedTemporalContextFromLocalStorage(remove = false) {
        const valueInLocalStorage = localStorage.getItem(_SAVE_TEMPORAL_CONTEXT_LS_KEY);
        const ret = valueInLocalStorage != null ? JSON.parse(valueInLocalStorage) : null;
        if (remove) {
            localStorage.removeItem(_SAVE_TEMPORAL_CONTEXT_LS_KEY);
        }
        return ret;
    }
    dashboardIDFromURL(url) {
        if (!url.startsWith(_DASHBOARD_URL_PATH)) {
            return null;
        }
        let ret = url.substring(_DASHBOARD_URL_PATH_LENGTH);
        const paramPos = ret.indexOf(URL_PARAM_START);
        if (paramPos >= 0) {
            ret = ret.substring(0, paramPos);
        }
        this._logger.debug(`Extracted dashboard ID ${ret} from supplied URL ${url} based on required URL start ${_DASHBOARD_URL_PATH}`);
        return ret;
    }
    async _handleDeactivateDashboard() {
        if (null != this._activeDashboard) {
            this._logger.warn(`Asking for dashboard deactivation for active dashboard: ${this._activeDashboard}`);
            this._lastDeActivatedDashboard = this._activeDashboard;
            return new DashboardDeactivated(this._activeDashboard);
        }
        else {
            return new Noop();
        }
    }
    async _handleDashboardActivation(dashboardID) {
        if (null != dashboardID) {
            return new DashboardActivated(dashboardID);
        }
    }
    _saveTemporalContextToLocalStorage(dashboardID, context) {
        if (null == this._lastDeActivatedDashboard) {
            return;
        }
        // Total hack for now.  Storing the current temporal date range in local storage.
        // This will eventually be done using snapshots!
        const currentSaved = this._getSavedTemporalContextFromLocalStorage();
        if (null == currentSaved) {
            // If we are reloading a dashboard with a saved temporal context then we don't want to
            // save to local storage.  We only do that if it's empty or it wasn't saved from THIS dashboard.
            const toSave = {
                dashboard: dashboardID,
                range: context.temporal.range,
                aggregation: context.temporal.aggregation,
                dateType: context.temporal.dateType
            };
            localStorage.setItem(_SAVE_TEMPORAL_CONTEXT_LS_KEY, JSON.stringify(toSave));
        }
        else {
        }
    }
    _restoreTemporalContextToLocalStorage(context) {
        const storedContext = this._getSavedTemporalContextFromLocalStorage(true);
        if (null == storedContext) {
            return;
        }
        let newRange = BusinessDateRange.clone(context.temporal.range);
        newRange = storedContext.range;
        const newAggregation = storedContext.aggregation || context.temporal.aggregation;
        const dateType = storedContext.dateType || context.temporal.dateType;
        const _action = new BusinessDateRangeAndTemporalAggregationChanged(newRange, newAggregation, undefined, undefined, dateType);
        this._store$.dispatch(_action);
    }
    constructor(_action$, _store$, loggingService, _dashboardService) {
        this._action$ = _action$;
        this._store$ = _store$;
        this._dashboardService = _dashboardService;
        // @Effect({dispatch: true})
        this.routerNavigation$ = createEffect(() => this._action$
            .pipe(ofType(fromRouterStore.ROUTER_NAVIGATION), switchMap(async (action) => {
            const dashboardID = this.dashboardIDFromURL(action.payload.event.url);
            if (null == dashboardID) {
                return new Noop();
            }
            return this._handleDeactivateDashboard();
        })), { dispatch: true });
        // @Effect({dispatch: true})
        this.routerNavigated$ = createEffect(() => this._action$
            .pipe(ofType(fromRouterStore.ROUTER_NAVIGATED), switchMap(async (action) => {
            const dashboardID = this.dashboardIDFromURL(action.payload.event.url);
            if (null == dashboardID) {
                return new Noop();
            }
            this._logger.warn(`Marking dashboard: ${dashboardID} as the active dashboard.`);
            this._activeDashboard = dashboardID;
            this._logger.warn(`Asking for dashboard activation for dashboard: ${dashboardID}`);
            return this._handleDashboardActivation(dashboardID);
        })), { dispatch: true });
        // @Effect({dispatch: false})
        this.dashboardActivated$ = createEffect(() => this._action$
            .pipe(ofType(DASHBOARD_ACTIVATED), withLatestFrom(this._store$), switchMap(async ([action, context]) => {
            this._logger.warn("Activating Dashboard", action);
            const dashboardID = action.payload;
            this._dashboardService.logDashboardNavigation(this._lastDeActivatedDashboard || "", dashboardID);
            return this._handleStateChange(dashboardID, context, GQL.ACTIVATE);
        })), { dispatch: false });
        // @Effect({dispatch: false})
        this.dashboardDeActivated$ = createEffect(() => this._action$
            .pipe(ofType(DASHBOARD_DEACTIVATED), withLatestFrom(this._store$), switchMap(async ([action, context]) => {
            this._logger.warn("De-activating Dashboard", action);
            const dashboardID = action.payload;
            return this._handleStateChange(dashboardID, context, GQL.DEACTIVATE);
        })), { dispatch: false });
        // @Effect({dispatch: false})
        this.saveTemporalContext$ = createEffect(() => this._action$
            .pipe(ofType(SAVE_TEMPORAL_CONTEXT), withLatestFrom(this._store$), switchMap(async ([action, context]) => {
            this._logger.warn("Saving temporal context", action);
            const payload = action;
            return this._saveTemporalContextToLocalStorage(payload.dashboardID, context);
        })), { dispatch: false });
        // @Effect({dispatch: false})
        this.restoreTemporalContext$ = createEffect(() => this._action$
            .pipe(ofType(RESTORE_TEMPORAL_CONTEXT), withLatestFrom(this._store$), switchMap(async ([action, context]) => {
            this._logger.warn("Saving temporal context", action);
            const payload = action;
            return this._restoreTemporalContextToLocalStorage(context);
        })), { dispatch: false });
        this._logger = new Logger(LOG_NAMESPACE, loggingService);
    }
}
UXEffects.ɵfac = function UXEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || UXEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.LoggingService), i0.ɵɵinject(i4.DashboardService)); };
UXEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UXEffects, factory: UXEffects.ɵfac });
