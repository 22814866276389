import { copyObjectWithoutNulls } from "../../util/object-utils";
import { BORDER } from "../../util/string-constants";
import { BaseSelector } from "./base-selector";
const ICON = "icon";
const ICON_UNICODE = "icon-unicode";
const DEFAULT = "DEFAULT";
const CLOSE = "close";
const REMOVE_ON_CLICK = "removeOnClick";
const FONT_COLOR = "font-color";
const FONT_WEIGHT = "font-weight";
const FONT_TYPE = "font-type";
const FONT_VARIANT = "font-variant";
const FONT_SIZE = "font-size";
const OPACITY = "opacity";
const BORDER_RADIUS = "border-radius";
const BORDER_COLOR = "border-color";
const BACKGROUND_COLOR = "background-color";
const CONTAINER_STYLE = "CONTAINER_STYLE";
const TEXT_STYLE = "TEXT_STYLE";
const ALERT_ICON = "ALERT_ICON";
const ALERT_ICON_UNICODE = "ALERT_ICON_UNICODE";
const ALERT_PROPERTIES = "ALERT_PROPERTIES";
const COLOR = "color";
export class AlertConfigSelector extends BaseSelector {
    constructor() {
        super(...arguments);
        this._alertStyles = {
            CONTAINER_STYLE: {},
            TEXT_STYLE: {},
            ALERT_ICON: {},
            ALERT_ICON_UNICODE: {},
            ALERT_PROPERTIES: {}
        };
    }
    alertContainerStyle(properties) {
        this._logger.info("ContainerStyle properties ", { props: properties });
        if (this._alertStyles[CONTAINER_STYLE].hasOwnProperty(properties.Alert.Type.code)) {
            const ret = this._alertStyles[CONTAINER_STYLE][properties.Alert.Type.code];
            return ret;
        }
        const styles = this._baseReturnStyles(properties);
        const style = {};
        this._updateStylesForKey(BORDER_RADIUS, BORDER_RADIUS, style, styles);
        this._updateStylesForKey(BORDER, BORDER, style, styles);
        this._updateStylesForKey(BACKGROUND_COLOR, BACKGROUND_COLOR, style, styles);
        this._alertStyles[CONTAINER_STYLE][properties.Alert.Type.code] = style;
        return style;
    }
    alertTextStyle(properties) {
        this._logger.info("AlertTextStyle properties ", { props: properties });
        if (this._alertStyles[TEXT_STYLE].hasOwnProperty(properties.Alert.Type.code)) {
            return this._alertStyles[TEXT_STYLE][properties.Alert.Type.code];
        }
        const styles = this._baseReturnStyles(properties);
        const returnStyles = {};
        this._updateStylesForKey(COLOR, FONT_COLOR, returnStyles, styles);
        this._updateStylesForKey(FONT_WEIGHT, FONT_WEIGHT, returnStyles, styles);
        this._updateStylesForKey(FONT_TYPE, FONT_TYPE, returnStyles, styles);
        this._updateStylesForKey(FONT_VARIANT, FONT_VARIANT, returnStyles, styles);
        this._updateStylesForKey(FONT_SIZE, FONT_SIZE, returnStyles, styles);
        this._alertStyles[TEXT_STYLE][properties.Alert.Type.code] = returnStyles;
        return returnStyles;
    }
    alertIcon(properties) {
        this._logger.info("AlertIcon properties ", { props: properties });
        if (this._alertStyles[ALERT_ICON].hasOwnProperty(properties.Alert.Type.code)) {
            return this._alertStyles[ALERT_ICON][properties.Alert.Type.code];
        }
        this._checkForRequiredProperties(properties);
        const alertIcon = this.config[properties.Alert.Type.Category.code][properties.Alert.Type.code][ICON] ?
            this.config[properties.Alert.Type.Category.code][properties.Alert.Type.code][ICON] :
            this.config[properties.Alert.Type.Category.code][DEFAULT][ICON];
        this._alertStyles[ALERT_ICON][properties.Alert.Type.code] = alertIcon;
        return alertIcon;
    }
    alertIconUnicode(properties) {
        this._logger.info("AlertIconUnicode properties ", { props: properties });
        if (this._alertStyles[ALERT_ICON_UNICODE].hasOwnProperty(properties.Alert.Type.code)) {
            return this._alertStyles[ALERT_ICON_UNICODE][properties.Alert.Type.code];
        }
        this._checkForRequiredProperties(properties);
        const alertIcon = this.config[properties.Alert.Type.Category.code][properties.Alert.Type.code][ICON_UNICODE] ?
            this.config[properties.Alert.Type.Category.code][properties.Alert.Type.code][ICON_UNICODE] :
            this.config[properties.Alert.Type.Category.code][DEFAULT][ICON_UNICODE];
        this._alertStyles[ALERT_ICON_UNICODE][properties.Alert.Type.code] = alertIcon;
        return alertIcon;
    }
    alertProperties(properties) {
        this._logger.info("Alert properties ", { props: properties });
        if (this._alertStyles[ALERT_PROPERTIES].hasOwnProperty(properties.Alert.Type.code)) {
            const ret = this._alertStyles[ALERT_PROPERTIES][properties.Alert.Type.code];
            return ret;
        }
        this._checkForRequiredProperties(properties);
        const alertSpecificConfig = this.config[properties.Alert.Type.Category.code][properties.Alert.Type.code];
        const returnStyles = copyObjectWithoutNulls(this.config[properties.Alert.Type.Category.code][DEFAULT]);
        for (const key in alertSpecificConfig) {
            if (alertSpecificConfig.hasOwnProperty(key)) {
                returnStyles[key] = alertSpecificConfig[key];
            }
        }
        const alertProperties = {
            close: returnStyles[CLOSE],
            removeOnClick: returnStyles[REMOVE_ON_CLICK]
        };
        this._alertStyles[ALERT_PROPERTIES][properties.Alert.Type.code] = alertProperties;
        return alertProperties;
    }
    _updateStylesForKey(cssKey, configKey, stylesCollection, stylesConfiguration) {
        configKey = configKey ? configKey : cssKey;
        this._logger.info("Updating styles for key '" + configKey + "'");
        if (stylesConfiguration[configKey]) {
            stylesCollection[cssKey] = stylesConfiguration[configKey];
        }
    }
    _checkForRequiredProperties(properties) {
        if (undefined === properties.Alert) {
            // tslint:disable-next-line:max-line-length
            throw new Error(`You have passed an ISelectorProperties object which did not contain an Alert property.  You passed ${properties} which as you can see, is sadly missing an Alert property!`);
        }
        const alertCategoryCode = properties.Alert.Type.Category.code;
        if (!(alertCategoryCode in this.config)) {
            throw new Error(`Alert category code ${alertCategoryCode} not configured in this selector!`);
        }
        const alertTypeCode = properties.Alert.Type.code;
        if (!(alertTypeCode in this.config[alertCategoryCode])) {
            throw new Error(`Alert category code ${alertCategoryCode} does not contain any configuration for alert type code ${alertTypeCode}!`);
        }
    }
    _baseReturnStyles(properties) {
        this._checkForRequiredProperties(properties);
        let alertSpecificConfig = this.config[properties.Alert.Type.Category.code][properties.Alert.Type.code];
        const ret = copyObjectWithoutNulls(this.config[properties.Alert.Type.Category.code][DEFAULT]);
        if (!alertSpecificConfig) {
            alertSpecificConfig = [];
        }
        for (const key in alertSpecificConfig) {
            if (alertSpecificConfig.hasOwnProperty(key)) {
                ret[key] = alertSpecificConfig[key];
            }
        }
        return ret;
    }
}
