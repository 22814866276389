
import * as GQL from "../util/graphql-tags";
import { Any, JsonObject, JsonProperty } from "json2typescript";
import { avatarURL } from "./model.utils";

@JsonObject("Person")
export class Person {
  @JsonProperty("RelatedPeople", [Person], true)
  public RelatedPeople: Person[] = [];

  @JsonProperty("DirectMinus1", [Person], true)
  public DirectMinus1: Person[] = [];

  @JsonProperty(GQL.SCALAR_ID, String, true)
  public id: string = null;

  @JsonProperty(GQL.SCALAR_DISPLAY_NAME, String, false)
  public displayName: string = null;

  @JsonProperty("additionalData", String, true)
  private __additionalData: string = null;

  private __parsedAdditionalData: Any = null;

  public get parsedAdditionalData () {
    if (null == this.__parsedAdditionalData) {
      if (null == this.__additionalData) {
        // console.log("IS NULL", this.id);
        this.__parsedAdditionalData = {};
      } else {
        // console.log("PARSING", this.id)
        this.__parsedAdditionalData = JSON.parse(this.__additionalData);
      }
    }
    // console.log("RETURNING", this.id);
    return this.__parsedAdditionalData;
  }
  public set parsedAdditionalData(data: any) {
    this.__parsedAdditionalData = JSON.parse(JSON.stringify(data));
  }

  @JsonProperty(GQL.SCALAR_AVATAR_URL, String, true)
  private __avatarURL: string = null;

  public get avatarURL(): string {
    if (null == this.__avatarURL) {
      this.__avatarURL = avatarURL(this.displayName, this.displayName);
    }
    return this.__avatarURL;
  }

  @JsonProperty(GQL.SCALAR_EXISTS, Boolean, true)
  public exists: boolean = null;

  public static clone(toClone: Person): Person {
    if (null == toClone) {
      return null;
    }
    const ret = new Person();
    ret.id = toClone.id;
    ret.displayName = toClone.displayName;
    ret.__avatarURL = toClone.__avatarURL;
    ret.__additionalData = toClone.__additionalData
    return ret;
  }

  public static ToValidInputObject(data: Person): any {
    if (null == data) {
      return null;
    }
    return {
      id: data.id,
      name: data.displayName
    };
  }

}
