import { __decorate, __metadata } from "tslib";
import * as GQL from "../util/graphql-tags";
import { JsonObject, JsonProperty } from 'json2typescript';
let Entity = class Entity {
    constructor() {
        this.id = undefined;
        this.name = undefined;
    }
};
__decorate([
    JsonProperty(GQL.SCALAR_ID, String, false),
    __metadata("design:type", String)
], Entity.prototype, "id", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_NAME, String, false),
    __metadata("design:type", String)
], Entity.prototype, "name", void 0);
Entity = __decorate([
    JsonObject("Entity")
], Entity);
export { Entity };
