import { Logger } from "../logging/logger";
import { Store } from "@ngrx/store";
import jsPDF from "jspdf";
import * as domtoimage from "dom-to-image";
import { COMMA, NEWLINE, EMPTY, SPACE, DASH, CLASS, DIV_TAG, WHITE, LOCATION_ID, LOCATION_TYPE_ID, REGION_ID, ENTITY_ID, CSV, PDF, ID } from "../../util/string-constants";
import { CSVExporterConfig } from "../../dashboard/components/exporter/csv/config/csv.exporter.config";
import { json2ts } from "../../util/json-2ts";
import { formattedTimeString } from "../../util/utils";
import { LoggingService } from "../logging/logging.service";
import { take } from "rxjs/operators";
import { FormatterService } from "../formatter/formatter.service";
import * as i0 from "@angular/core";
import * as i1 from "../logging/logging.service";
import * as i2 from "../formatter/formatter.service";
import * as i3 from "@ngrx/store";
const DEFAULT_SCALE = 2;
const DASHBOARD = "Dashboard";
const FMT_A4 = "a4";
const IMG_FMT_PNG = "PNG";
const PORTRAIT = "p";
const SELECTOR_ACTIVE_SIDEBAR = "#sidebar-dashboard .active";
const SELECTOR_TEMPORAL_DETAILS = ".temporal-display-container";
const TAG_ANCHOR = "a";
const TAG_DDC_COMPONENT_CONTAINER = "ddc-component-container";
const TRANSFORM = "scale(" + DEFAULT_SCALE + ")";
const TRANSFORM_ORIGIN = "top left";
const UNIT_MM = "mm";
const DEFAULT_WIDTH_OFFSET = 5;
const DEFAULT_HEIGHT_OFFSET = 10;
const DEFAULT_DASHBOARD_FILENAME = "dashboard";
const DEFAULT_IMAGE_FILENAME = "ddc-image.jpeg";
const FONT_FAMILY = "helvetica";
const FONT_STYLE = "normal";
const FONT_SIZE = 10;
const CENTER = "center";
const WIDTH_THRESHOLD = 85;
const IMAGE_QUALITY = 1;
const CSS_CLASS_DDC_GROUP = ".ddc-group";
const LOCATIONS = "Locations";
const LOCATION_TYPES = "Location Types";
const AREAS = "Areas";
const ENTITIES = "Entities";
const LOCATIONS_SELECTOR = '.select2-hidden-accessible [label="Locations"]';
const AREAS_SELECTOR = '.select2-hidden-accessible [label="Areas"]';
const LOCATION_TYPES_SELECTOR = '.select2-hidden-accessible [label="Location Types"]';
const ENTITIES_SELECTOR = '.select2-hidden-accessible [label="Entities"]';
const REFERENCE_CONTEXT_KEY = "ddc.context.reference";
const BLOB = "blob";
const HUNDRED = 100;
const LOG_NAMESPACE = "services.exporter";
export class ExporterService {
    constructor(loggingService, _formatterService, store) {
        this._csvExporterConfigurations = {};
        this._pdfExporterConfigurations = {};
        this.store = store;
        this._formatterService = _formatterService;
        this._logger = new Logger(LOG_NAMESPACE, loggingService);
    }
    init(config) {
        this._logger.warn(formattedTimeString() + " Initializing Exporter Service...");
        config = JSON.parse(config);
        const csvExporters = config[CSV];
        for (const curr of csvExporters) {
            const id = curr[ID];
            this._csvExporterConfigurations[id] = json2ts(curr, CSVExporterConfig);
        }
        const pdfExporters = config[PDF];
        for (const curr of pdfExporters) {
            const id = curr[ID];
            this._pdfExporterConfigurations[id] = curr;
        }
    }
    csvExportConfiguration(code) {
        if (null == this._csvExporterConfigurations[code]) {
            throw new Error(`Could not find CSV export code '${code}' in configured CSV exports!`);
        }
        else {
            return this._csvExporterConfigurations[code];
        }
    }
    pdfExportConfiguration(code) {
        if (null == this._pdfExporterConfigurations[code]) {
            throw new Error(`Could not find PDF export code '${code}' in configured PDF exports!`);
        }
        else {
            return this._pdfExporterConfigurations[code];
        }
    }
    async exportPDF() {
        const htmlNodes = this._getAllNodes();
        const base64EncodedImages = this._getDOMImages(htmlNodes);
        const imageUrls = await Promise.all(base64EncodedImages);
        const images = this._createHTMLImgs(imageUrls);
        const layout = this._getPageLayout(htmlNodes);
        this._generatePDFWithImages(htmlNodes, images, layout);
    }
    async exportImage(domElement) {
        const image = await Promise.all(this._getDOMImages([domElement]));
        const link = document.createElement(TAG_ANCHOR);
        link.download = DEFAULT_IMAGE_FILENAME;
        link.href = image[0];
        link.click();
    }
    downloadCSV(rows, fileName = "data", options) {
        if (null != options && options.download === false) {
            return rows;
        }
        const csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.map(((d) => {
            if (null == d) {
                return "";
            }
            if (isNaN(d) && d[0] === "-") {
                d = d.replace("-", "[-]");
            }
            return `"${d}"`;
        })).join(COMMA)).join(NEWLINE);
        const replacedStr = csvContent.replace(/#/g, "").replace(/\&nbsp\;/g, " ");
        const encodedUri = encodeURI(replacedStr);
        const downloadLink = document.createElement("a");
        downloadLink.href = encodedUri;
        if (null != options && null != options.fileName && null != options.fileName.appendCurrentPeriod) {
            let period;
            this.store.select("temporal").pipe(take(1)).subscribe(temporal => {
                const formatter = this._formatterService.formatter(options.fileName.appendCurrentPeriod);
                period = formatter.formatData(temporal.range.From);
                fileName += `-${period}`;
            });
        }
        downloadLink.download = `${fileName}.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    _getAllNodes() {
        const nodes = document.querySelectorAll(TAG_DDC_COMPONENT_CONTAINER);
        const nodeList = Object.values(nodes);
        // Ignoring the temporal selector components.
        nodeList.shift();
        return nodeList;
    }
    _getDOMImages(nodes) {
        const base64EncodedImages = [];
        nodes.map(element => {
            let domElement = element.children[0];
            return base64EncodedImages.push(this._convertDOMtoImage(domElement));
        });
        return base64EncodedImages;
    }
    _convertDOMtoImage(domElement) {
        return domtoimage.toJpeg(domElement, { quality: IMAGE_QUALITY, bgcolor: WHITE,
            width: domElement.clientWidth * DEFAULT_SCALE,
            height: domElement.clientHeight * DEFAULT_SCALE,
            style: {
                transform: TRANSFORM,
                transformOrigin: TRANSFORM_ORIGIN
            } });
    }
    _createHTMLImgs(imgUrls) {
        return imgUrls.map(imgUrl => {
            let img = new Image();
            img.src = imgUrl;
            return img;
        });
    }
    _convertPixelToMM(pixelVal) {
        return pixelVal * 0.2645833333;
    }
    _getNewHeight(width, height, newWidth) {
        return (height / width) * newWidth;
    }
    _getPageLayout(htmlNodes) {
        let layoutList = [];
        let i = 1;
        htmlNodes.forEach(htmlNode => {
            var colValString = htmlNode.closest(DIV_TAG).getAttribute(CLASS);
            if (htmlNode.closest(CSS_CLASS_DDC_GROUP)) {
                colValString = htmlNode.closest(CSS_CLASS_DDC_GROUP).getAttribute(CLASS);
            }
            var colVal = this._getColValues(colValString);
            if (colVal) {
                layoutList.push(parseInt(colVal[1]));
            }
            i++;
        });
        return layoutList;
    }
    _getColValues(colVal) {
        let xl_pattern = /\bcol-xl-(\w+)/g.exec(colVal);
        if (xl_pattern) {
            return xl_pattern;
        }
        let lg_pattern = /\bcol-lg-(\w+)/g.exec(colVal);
        if (lg_pattern) {
            return lg_pattern;
        }
    }
    _generatePDFWithImages(htmlNodes, images, layout) {
        let doc = new jsPDF(PORTRAIT, UNIT_MM, FMT_A4);
        let pageWidth = doc.internal.pageSize.width - 10;
        let pageHeight = doc.internal.pageSize.height;
        let widthOffset = DEFAULT_WIDTH_OFFSET;
        let heightOffset = DEFAULT_HEIGHT_OFFSET;
        let ignoreNext = 0;
        this._addPageTitle(doc);
        htmlNodes.forEach((htmlNode, index) => {
            // check if the node is part of the layout.
            // if so get sibling children and remove them from them html nodelist as they are arranged in sequential
            // order.
            if (htmlNode.closest(CSS_CLASS_DDC_GROUP) && ignoreNext == 0) {
                let layoutChildren = htmlNode.closest(CSS_CLASS_DDC_GROUP).querySelectorAll(TAG_DDC_COMPONENT_CONTAINER);
                ignoreNext += layoutChildren.length;
            }
            let pDiv = htmlNode.children[0];
            let domHeight = this._convertPixelToMM(pDiv.clientHeight);
            let domWidth = this._convertPixelToMM(pDiv.clientWidth);
            let newDomWidth = pageWidth * (layout[index] / 12);
            let newDomHeight = this._getNewHeight(domWidth, domHeight, newDomWidth);
            if (heightOffset + newDomHeight >= pageHeight) {
                doc.addPage();
                widthOffset = DEFAULT_WIDTH_OFFSET;
                heightOffset = DEFAULT_HEIGHT_OFFSET;
                this._addPageTitle(doc);
            }
            doc.addImage(images[index], IMG_FMT_PNG, widthOffset, heightOffset, newDomWidth, newDomHeight);
            if (ignoreNext == 1 && widthOffset + newDomWidth / pageWidth <= WIDTH_THRESHOLD / 100) {
                heightOffset = DEFAULT_HEIGHT_OFFSET;
            }
            if (ignoreNext > 0) {
                heightOffset += newDomHeight;
                ignoreNext -= 1;
                if (ignoreNext == 0) {
                    if (widthOffset / pageWidth <= WIDTH_THRESHOLD / HUNDRED) {
                        heightOffset = DEFAULT_HEIGHT_OFFSET;
                        widthOffset += newDomWidth;
                    }
                }
            }
            else {
                widthOffset += newDomWidth;
            }
            if (widthOffset / pageWidth >= WIDTH_THRESHOLD / HUNDRED) {
                widthOffset = DEFAULT_WIDTH_OFFSET;
                heightOffset += newDomHeight + 5;
            }
        });
        // doc.save(DEFAULT_DASHBOARD_FILENAME);
        doc.setProperties({
            title: DEFAULT_DASHBOARD_FILENAME
        });
        window.open(URL.createObjectURL(doc.output(BLOB)));
    }
    _addPageTitle(doc) {
        let activeSideBar = document.querySelector(SELECTOR_ACTIVE_SIDEBAR);
        let perioddDetails = document.querySelector(SELECTOR_TEMPORAL_DETAILS);
        let title = activeSideBar ? activeSideBar.textContent + SPACE + DASHBOARD : DASHBOARD;
        let period = perioddDetails ? perioddDetails.textContent : EMPTY;
        let titleContent = title + SPACE + DASH + SPACE + period;
        let selectionInfo = this._getUserSelectionInfo();
        if (selectionInfo) {
            titleContent += SPACE + DASH + SPACE + selectionInfo;
        }
        doc.setFont(FONT_FAMILY);
        doc.setFontStyle(FONT_STYLE);
        doc.setFontSize(FONT_SIZE);
        doc.text(titleContent, 105, 7, null, null, CENTER);
    }
    _getFullNameFromID(value, type) {
        if (type == AREAS) {
            return document.querySelectorAll(AREAS_SELECTOR)[0].querySelector('[value="regionID|' + value + '"]').textContent;
        }
        if (type == LOCATIONS) {
            return document.querySelectorAll(LOCATIONS_SELECTOR)[0].querySelector('[value="locationID|' + value + '"]').textContent;
        }
        if (type == LOCATION_TYPES) {
            return document.querySelectorAll(LOCATION_TYPES_SELECTOR)[0].querySelector('[value="locationTypeID|' + value + '"]').textContent;
        }
        if (type == ENTITIES) {
            return document.querySelectorAll(ENTITIES_SELECTOR)[0].querySelector('[value="entityID|' + value + '"]').textContent;
        }
    }
    _getUserSelectionInfo() {
        let title = EMPTY;
        let referenceContext = localStorage.getItem(REFERENCE_CONTEXT_KEY);
        if (referenceContext) {
            referenceContext = JSON.parse(referenceContext);
            let locationID = referenceContext[LOCATION_ID];
            let locationTypeID = referenceContext[LOCATION_TYPE_ID];
            let entityID = referenceContext[ENTITY_ID];
            let regionID = referenceContext[REGION_ID];
            if (locationID.length > 0) {
                let regionName = this._getFullNameFromID(locationID[0], LOCATIONS);
                if (regionName) {
                    title = regionName;
                    return title;
                }
            }
            if (locationTypeID.length > 0) {
                let locationTypeName = this._getFullNameFromID(locationTypeID[0], LOCATION_TYPES);
                if (locationTypeName) {
                    title += locationTypeName;
                }
            }
            if (entityID.length > 0) {
                let entityName = this._getFullNameFromID(entityID[0], ENTITIES);
                if (title) {
                    title += COMMA + SPACE + entityName;
                }
                else {
                    title = entityName;
                }
            }
            if (regionID.length > 0) {
                let regionName = this._getFullNameFromID(regionID[0], AREAS);
                if (regionName) {
                    if (title) {
                        title += COMMA + SPACE + regionName;
                    }
                    else {
                        title = regionName;
                    }
                }
            }
        }
        return title;
    }
}
ExporterService.ɵfac = function ExporterService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || ExporterService)(i0.ɵɵinject(i1.LoggingService), i0.ɵɵinject(i2.FormatterService), i0.ɵɵinject(i3.Store)); };
ExporterService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ExporterService, factory: ExporterService.ɵfac });
