import {
  JsonObject,
  JsonProperty
} from "json2typescript";
import { ThresholdConfig } from "./threshold-config.model";


@JsonObject("ThresholdFormatterConfig")
export class ThresholdFormatterConfig {

  @JsonProperty("default", ThresholdConfig, true)
  public default: ThresholdConfig = undefined;

  @JsonProperty("thresholds", [ThresholdConfig], false)
  public thresholds: [ThresholdConfig] = undefined;

  @JsonProperty("null-default", Boolean, true)
  nullDefault ?: boolean = true;
}

