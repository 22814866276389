import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import * as GQL from "../../util/graphql-tags";
let SupportServiceConfiguration = class SupportServiceConfiguration {
    constructor() {
        this.provider = null;
        this.config = {};
    }
};
__decorate([
    JsonProperty(GQL.SCALAR_PROVIDER, String, false),
    __metadata("design:type", String)
], SupportServiceConfiguration.prototype, "provider", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_CONFIG, Any, true),
    __metadata("design:type", Object)
], SupportServiceConfiguration.prototype, "config", void 0);
SupportServiceConfiguration = __decorate([
    JsonObject("SupportServiceConfiguration")
], SupportServiceConfiguration);
export { SupportServiceConfiguration };
