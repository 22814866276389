import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject("Geo")
export class Geo {

  @JsonProperty("lat", Number, false)
  public lat: number = undefined;

  @JsonProperty("lng", Number, false)
  public lng: number = undefined;

  @JsonProperty("zoom", Number, true)
  public zoom ?: number = undefined;
}
