import { Level } from "../../../packages/ngx-toolkit/logger/level.model";

import {
  LOG_LEVEL,
  NAMESPACE,
  MESSAGE
} from "../../util/string-constants";
import {
  LoggingService
} from "./logging.service";

export class Logger {

  private _logLevel: Level;

  constructor(
    private _namespace: string,
    private _logService: LoggingService
  ) {
  }

  set namespace(name: string) {
      this._namespace = name;
  }

  set logLevel(logLevel: Level) {
    this._logLevel = logLevel;
  }

  public info(message: string, ...optionalParams: any[]) {
    this._logService.info(this._addNamespaceAndLevel(message), ...optionalParams);
  }

  public debug(message: string, ...optionalParams: any[]) {
    this._logService.debug(this._addNamespaceAndLevel(message), ...optionalParams);
  }

  public error(message: string, ...optionalParams: any[]) {
    this._logService.error(this._addNamespaceAndLevel(message), ...optionalParams);
  }

  public warn(message: string, ...optionalParams: any[]) {
    this._logService.warn(this._addNamespaceAndLevel(message), ...optionalParams);
  }

  private _addNamespaceAndLevel(message?: any) {
    const ret = {};
    ret[MESSAGE] = message;
    ret[NAMESPACE] = this._namespace;
    if (null != this._logLevel) {
      ret[LOG_LEVEL] = this._logLevel;
    }
    return ret;
  }

}
