import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("SeriesValues")
export class SeriesValues {
    @JsonProperty("series-id", String, false)
    public seriesID: string = undefined;

    @JsonProperty("path", String, false)
    public path: string = undefined;

    @JsonProperty("type", String, true)
    public type: string = undefined;

    @JsonProperty("sub-type", String, true)
    public subType: string = null;

    @JsonProperty("cumulative", Boolean, true)
    public cumulative: boolean = false;

    @JsonProperty("series-name-path", String, true)
    public seriesNamePath: string = undefined;

    @JsonProperty("series-key", Number, true)
    public seriesKey: number = undefined;

    @JsonProperty("includes-prior-periods", Boolean, true)
    public includesPriorPeriods: boolean = undefined;

    @JsonProperty("display-prior-periods", Boolean, true)
    public displayPriorPeriods: boolean = undefined;

    @JsonProperty("axis", Number, true)
    public axis: number = undefined;

}
