import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { switchMap, withLatestFrom } from "rxjs/operators";
import { PeriodOnPeriodType } from "../model/business-dates/temporal-aggregation.enum";
import { SettingKey } from "../model/user/setting-key.enum";
import { Logger } from "../services/logging/logger";
import { LoggingService } from "../services/logging/logging.service";
import { NotificationsService } from "../services/notifications/notifications.service";
import * as TemporalActions from "./temporal.actions";
import * as UXActions from "./ux.actions";
import * as MutationActions from "./mutation.actions";
import { TWO_BRS } from "../util/string-constants";
import { MutationType } from "../dashboard/components/config/mutation-type.enum";
import { NotificationType } from "../services/notifications/notification-type.enum";
import { copyToClipboard } from "../util/utils";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "@ngrx/store";
import * as i3 from "../services/notifications/notifications.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../services/logging/logging.service";
const TKEY_NOTIFICATIONS_ROOT = "UX.PLACEHOLDERS.";
const _TKEY_L4L_PREFIX = `${TKEY_NOTIFICATIONS_ROOT}LIKE-FOR-LIKE.`;
const _TKEY_L4L_ON = `${_TKEY_L4L_PREFIX}ON`;
const _TKEY_L4L_OFF = `${_TKEY_L4L_PREFIX}OFF`;
const _TKEY_MUTATION_PREFIX = `${TKEY_NOTIFICATIONS_ROOT}MUTATIONS.`;
const _TKEY_MUTATION_SUCCEEDED = "SUCCEEDED";
const _TKEY_MUTATION_FAILED = "FAILED";
const _TKEY_FAILED_OPTIONS_PREFIX = `${_TKEY_MUTATION_PREFIX}FAILED-OPTIONS.`;
const _TKEY_FAILED_COPY_TO_CLIPBOARD = `${_TKEY_FAILED_OPTIONS_PREFIX}COPY-TO-CLIPBOARD`;
const _TKEY_FAILED_COPIED_TO_CLIPBOARD = `${_TKEY_FAILED_OPTIONS_PREFIX}COPIED-TO-CLIPBOARD`;
const _TKEY_FAILED_CLOSE = `${_TKEY_FAILED_OPTIONS_PREFIX}CLOSE`;
const _TKEY_FAILED_ERROR_DETAILS_PREFIX = `${_TKEY_FAILED_OPTIONS_PREFIX}ERROR-DETAILS-PREFIX`;
const _DEFAULT_HIDE_AFTER_SUCCESS = 3;
const _DEFAULT_HIDE_AFTER_ERROR = null;
const _DEFAULT_HIDE_AFTER_INFO = 3;
export class NotificationEffects {
    constructor(_actions$, _store$, _notificationsService, _translationService, _loggingService) {
        this._actions$ = _actions$;
        this._store$ = _store$;
        this._notificationsService = _notificationsService;
        this._translationService = _translationService;
        // @Effect({ dispatch: false })
        this.l4lChanged$ = createEffect(() => this._actions$
            .pipe(ofType(TemporalActions.L4L_CHANGED), withLatestFrom(this._store$), switchMap(async ([action, context]) => {
            const enabled = context.user.profile.Settings.setting(SettingKey.UXShowL4LChangedNotifications, false, true);
            if (!enabled) {
                this._logger.info("L4L Notifications disabled.");
                return;
            }
            let notifyUser = action.notifyUser;
            if (null == notifyUser) {
                notifyUser = true;
            }
            if (!notifyUser) {
                this._logger.info("L4L change not for user notification, notification suppressed.");
                return;
            }
            let tKey = null;
            switch (action.payload) {
                case PeriodOnPeriodType.YearAgoPeriod: {
                    tKey = _TKEY_L4L_OFF;
                    break;
                }
                case PeriodOnPeriodType.YearAgoPeriodL4L: {
                    tKey = _TKEY_L4L_ON;
                    break;
                }
            }
            if (null != tKey) {
                const translated = this._translationService.instant(tKey);
                // If the translated value is the translation key then there is no translation
                // so there should be no notification.
                if (translated !== tKey) {
                    this._notificationsService.post({
                        message: translated,
                        type: NotificationType.Info,
                        hideAfter: _DEFAULT_HIDE_AFTER_INFO
                    });
                }
            }
        })), { dispatch: false });
        // @Effect({ dispatch: false })
        this.queryErrored$ = createEffect(() => this._actions$
            .pipe(ofType(UXActions.QUERY_ERROR), withLatestFrom(this._store$), switchMap(async ([action, context]) => {
            const message = null != action.context && null != action.context.message ?
                action.context.message :
                "An error occurred while exporting CSV.";
            this._notificationsService.post({
                message: this._translationService.instant(message),
                type: NotificationType.Error,
                actions: action.context.actions
            });
        })), { dispatch: false });
        // @Effect({ dispatch: false })
        this.mutationOccured$ = createEffect(() => this._actions$
            .pipe(ofType(MutationActions.MUTATION_SUCCESSFUL, MutationActions.MUTATION_FAILED), switchMap(async (action) => {
            console.log("NOTIF ACTION", action);
            let tKey;
            const mutationTypes = action.payload;
            const wasSuccessful = action instanceof MutationActions.MutationSuccessful;
            const tSuffix = wasSuccessful ? action.translationSuffix != null ? action.translationSuffix : _TKEY_MUTATION_SUCCEEDED : _TKEY_MUTATION_FAILED;
            const notificationType = wasSuccessful ? NotificationType.Success : NotificationType.Error;
            const errorDetails = !wasSuccessful ? action.err : null;
            for (const mt of mutationTypes) {
                const mutationType = null != action.typeName ? action.typeName : MutationType[mt].toUpperCase();
                tKey = `${_TKEY_MUTATION_PREFIX}${mutationType}.${tSuffix}`;
                console.log("TKEY", tKey);
                const translation = this._translationService.instant(tKey);
                // Clipboard message doesn't include any of the HTML tags which are used to make the message prettier in displayMessage.
                const clipboardMessage = wasSuccessful ? null : translation + "  " + this._translationService.instant(_TKEY_FAILED_ERROR_DETAILS_PREFIX) + errorDetails;
                const displayMessage = wasSuccessful ? translation : translation + TWO_BRS + this._translationService.instant(_TKEY_FAILED_ERROR_DETAILS_PREFIX) + TWO_BRS + errorDetails;
                // If a translation is missing (translated value is the same as the translation key) then we don't do anything.
                if (translation !== tKey) {
                    const msg = this._notificationsService.post({
                        message: displayMessage,
                        hideAfter: wasSuccessful ? _DEFAULT_HIDE_AFTER_SUCCESS : _DEFAULT_HIDE_AFTER_ERROR,
                        type: notificationType,
                        showCloseButton: !wasSuccessful,
                        actions: wasSuccessful ? null : {
                            copyToClipboard: {
                                label: this._translationService.instant(_TKEY_FAILED_COPY_TO_CLIPBOARD),
                                action: async () => {
                                    copyToClipboard(clipboardMessage);
                                    msg.update({
                                        message: this._translationService.instant(_TKEY_FAILED_COPIED_TO_CLIPBOARD),
                                        hideAfter: _DEFAULT_HIDE_AFTER_INFO,
                                        actions: null,
                                        showCloseButton: false
                                    });
                                }
                            },
                            close: {
                                label: this._translationService.instant(_TKEY_FAILED_CLOSE),
                                action: async () => msg.hide()
                            }
                        }
                    });
                }
            }
        })), { dispatch: false });
        this._logger = new Logger("effects.notifications", _loggingService);
    }
}
NotificationEffects.ɵfac = function NotificationEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || NotificationEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.NotificationsService), i0.ɵɵinject(i4.TranslateService), i0.ɵɵinject(i5.LoggingService)); };
NotificationEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: NotificationEffects, factory: NotificationEffects.ɵfac });
