/* tslint:disable max-classes-per-file */
import { TemporalEffects } from "./temporal.effects";
import { DemoEffects } from "./demo.effects";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { RouterEffects } from "./router.effects";
import { CommentEffects } from "./comment.effects";
import { MutationEffects } from "./mutation.effects";
import { SnapshotEffects } from "./snapshot.effects";
import { switchMap } from "rxjs/operators";
import { NotificationEffects } from "./notification.effects";
import { ZendeskEffects } from "./zendesk-effects";
import { UXEffects } from "./ux.effects";
import { TagEffects } from "./tag.effects";
import { UserEffects } from "./user.effects";
import { TaskEffects } from "./task.effects";
import { PersonEffects } from "./person.effects";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
export const NOOP = "NOOP";
export class Noop {
    constructor() {
        this.type = NOOP;
    }
}
export class NoopEffects {
    constructor(_action$) {
        this._action$ = _action$;
        // @Effect({dispatch: false})
        this.noopEffect$ = createEffect(() => this._action$.pipe(ofType(NOOP), switchMap(() => of(null))), { dispatch: false });
    }
}
NoopEffects.ɵfac = function NoopEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || NoopEffects)(i0.ɵɵinject(i1.Actions)); };
NoopEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: NoopEffects, factory: NoopEffects.ɵfac });
export const Effects = [
    TemporalEffects,
    DemoEffects,
    NoopEffects,
    RouterEffects,
    CommentEffects,
    TaskEffects,
    SnapshotEffects,
    MutationEffects,
    NotificationEffects,
    ZendeskEffects,
    UXEffects,
    TagEffects,
    UserEffects,
    PersonEffects
];
