import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
export class DataResolver {
    resolve(route, state) {
        return of({ res: 'I am data' });
    }
}
DataResolver.ɵfac = function DataResolver_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || DataResolver)(); };
DataResolver.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: DataResolver, factory: DataResolver.ɵfac });
/**
 * An array of services to resolve routes with data.
 */
export const APP_RESOLVER_PROVIDERS = [
    DataResolver
];
