import {
  Injectable
} from "@angular/core";
import {
  Level
} from "../../../packages/ngx-toolkit/logger/level.model";
import {
  ConsoleLoggerService
} from "../../../packages/ngx-toolkit/logger/console-logger.service";

const _DEFAULT_LOG_LEVEL = Level.WARN;

@Injectable()
export class LoggingService extends ConsoleLoggerService {
  private _logLevel: number;

  constructor() {
    super(_DEFAULT_LOG_LEVEL);
    this._logLevel = _DEFAULT_LOG_LEVEL;
  }

  public override info(message?: any, ...optionalParams: any[]) {
    if (this._shouldLog(Level.INFO)) {
      super.info(message, ...optionalParams);
    }
  }

  public override debug(message?: any, ...optionalParams: any[]) {
    if (this._shouldLog(Level.DEBUG)) {
      super.debug(message, ...optionalParams);
    }
  }

  public override warn(message?: any, ...optionalParams: any[]) {
    if (this._shouldLog(Level.WARN)) {
      super.warn(message, ...optionalParams);
    }
  }

  public override error(message?: any, ...optionalParams: any[]) {
    if (this._shouldLog(Level.ERROR)) {
      super.error(message, ...optionalParams);
    }
  }

  public override log(message?: any, ...optionalParams: any[]) {
    if (this._shouldLog(Level.LOG)) {
      super.log(message, ...optionalParams);
    }
  }

  private _shouldLog(level: Level): boolean {
    return level <= this._logLevel;
  }

}
