var BusinessDateRange_1;
import { __decorate, __metadata } from "tslib";
import { BusinessDate } from './business-date.model';
import { BusinessDateOffset } from './business-date-offset.model';
import { JsonProperty, JsonObject } from 'json2typescript';
import * as GQL from "../../util/graphql-tags";
import { json2ts } from "../../util/json-2ts";
import { Order } from "../order.enum";
import { BusinessDateRangeCompareElements } from "./business-date-range-compare-elements.enum";
let BusinessDateRange = BusinessDateRange_1 = class BusinessDateRange {
    constructor() {
        this.From = undefined;
        this.To = undefined;
        this.Last = undefined;
        this.Next = undefined;
    }
    static ToValidInputObject(toStrip, removeFromToIfLastNext = true) {
        if (null == toStrip) {
            return null;
        }
        // console.log("TVIO starting with", toStrip);
        const ret = BusinessDateRange_1.clone(toStrip);
        [GQL.FROM, GQL.TO].forEach((tag) => {
            const toClean = ret[tag];
            if (null != toClean) {
                // delete toClean[GQL.CALENDAR_DATE_RANGE];
                ret[tag] = BusinessDate.ToValidInputObject(toClean);
            }
        });
        // console.log("TVIO after TVIO on members", ret);
        if (!removeFromToIfLastNext) {
            return ret;
        }
        if (BusinessDateOffset.IsValidOffset(ret.Last)) {
            delete ret[GQL.FROM];
        }
        else {
            delete ret[GQL.LAST];
        }
        if (BusinessDateOffset.IsValidOffset(ret.Next)) {
            delete ret[GQL.TO];
        }
        else {
            delete ret[GQL.NEXT];
        }
        return ret;
    }
    static ToValidContextObject(toClean, convertToAbsolute = false) {
        let ret = BusinessDateRange_1.clone(toClean);
        if (convertToAbsolute) {
            ret = BusinessDateRange_1.toFromTo(ret);
        }
        return ret;
    }
    static ToValidContextObjects(toClean) {
        if (null == toClean) {
            return null;
        }
        return Object.keys(toClean).reduce((dict, curr) => {
            dict[curr] = BusinessDateRange_1.ToValidContextObject(toClean[curr]);
            return dict;
        }, {});
    }
    static toFromTo(range) {
        if (null == range) {
            return null;
        }
        return {
            From: range.From,
            To: range.To
        };
    }
    static toLastNext(range) {
        return {
            Last: range.Last,
            Next: range.Next
        };
    }
    static clone(toClone) {
        if (null == toClone) {
            return null;
        }
        return json2ts(JSON.parse(JSON.stringify(toClone)), BusinessDateRange_1);
    }
    static IsValid(range) {
        return (BusinessDateOffset.IsValidOffset(range.Last) || BusinessDate.IsValid(range.From)) &&
            (BusinessDateOffset.IsValidOffset(range.Next) || BusinessDate.IsValid(range.To));
    }
    static IsAbsolute(range) {
        if (null == range) {
            return false;
        }
        return (!BusinessDateOffset.IsValidOffset(range.Last) || !BusinessDateOffset.IsValidOffset(range.Next))
            && BusinessDate.IsValid(range.From) && BusinessDate.IsValid(range.To);
    }
    static compare(a, b, sortOrder = Order.Ascending, compareElements = BusinessDateRangeCompareElements.FromTo) {
        if (null == b && null != a) {
            // Something comes before nothing (unless sorting backwards).
            return 1 * sortOrder;
        }
        else if (null != b && null == a) {
            // Something comes before nothing unless sorting backwards.
            return -1 * sortOrder;
        }
        else if (null == a && null == b) {
            return 0;
        }
        let compare;
        if (compareElements === BusinessDateRangeCompareElements.LastNext || compareElements === BusinessDateRangeCompareElements.All) {
            compare = BusinessDateOffset.compare(a.Last, b.Last, sortOrder);
            if (compare !== 0) {
                return compare;
            }
            compare = BusinessDateOffset.compare(a.Next, b.Next, sortOrder);
            if (compare !== 0) {
                return compare;
            }
        }
        if (compareElements === BusinessDateRangeCompareElements.FromTo || compareElements === BusinessDateRangeCompareElements.All) {
            compare = BusinessDate.compare(a.From, b.From, sortOrder);
            if (compare !== 0) {
                return compare;
            }
            return BusinessDate.compare(a.To, b.To, sortOrder);
        }
        return 0;
    }
    static equals(a, b, compareElements = BusinessDateRangeCompareElements.All) {
        if (null == a || null == b) {
            return false;
        }
        return BusinessDateRange_1.compare(a, b, Order.Ascending, compareElements) === 0;
    }
};
__decorate([
    JsonProperty(GQL.FROM, BusinessDate, true),
    __metadata("design:type", BusinessDate)
], BusinessDateRange.prototype, "From", void 0);
__decorate([
    JsonProperty(GQL.TO, BusinessDate, true),
    __metadata("design:type", BusinessDate)
], BusinessDateRange.prototype, "To", void 0);
__decorate([
    JsonProperty(GQL.LAST, BusinessDateOffset, true),
    __metadata("design:type", BusinessDateOffset)
], BusinessDateRange.prototype, "Last", void 0);
__decorate([
    JsonProperty(GQL.NEXT, BusinessDateOffset, true),
    __metadata("design:type", BusinessDateOffset)
], BusinessDateRange.prototype, "Next", void 0);
BusinessDateRange = BusinessDateRange_1 = __decorate([
    JsonObject("BusinessDateRange")
], BusinessDateRange);
export { BusinessDateRange };
