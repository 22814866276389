import {
  copyObjectWithoutNulls
} from "../../util/object-utils";
import { BaseSelector } from "./base-selector";
import {
  ISelectorProperties
} from "./selector.properties.interface";
import { StyleSelector } from "./style-selector";

export class BackgroundGradientStyleSelector extends StyleSelector {

  public override styles(properties: ISelectorProperties): {[type: string]: any} {
    let styles;
    if (!properties || !properties.id) {
      // Can't do anything so just return an empty array of styles.
      return [];
    }
    if (properties.id === "*") {
      return copyObjectWithoutNulls(this.config);
    }
    const lookupKey = properties.id;
    if (!this._styles.hasOwnProperty(lookupKey)) {
      const tStyle = this.config[lookupKey];
      this._styles[lookupKey] = tStyle;
    }
    styles = copyObjectWithoutNulls(this._styles[lookupKey]);

    let value: any = properties.value;
    if (typeof value === "string") {
      value = value.replace(/[!@#$%^&*£$,]/g, "");
    }
    value = parseFloat(value);
    if (null != properties.values && null != value && !isNaN(value)) {
      Object.keys(styles).forEach((style: string) => {
        if ((styles[style] as string).startsWith("linear-gradient(")) {
          styles[style] = this.calculatePercentGradient(styles[style], properties.values[0], properties.values[1], value);
        }
      })
    }
    return styles;
  }

  calculatePercentGradient(str: string, min: number, max: number, value: number) {
    const scale = max - min;
    const negativeDistancePct = min < 0 ? Math.abs(min) / scale * 100 : 0;

    // gradient calculation
      let valueScalePct = (value - min) / scale * 100;
      const widths = []; // white, red, green, white
      if (value < 0) {
        widths.push(valueScalePct);
        widths.push(negativeDistancePct);
        widths.push(negativeDistancePct);
      } else {
        widths.push(negativeDistancePct);
        widths.push(negativeDistancePct);
        widths.push(valueScalePct);
      }
      for (let i = 0 ; i < widths.length ; i++) {
        const findStr = `$\{widths\[${i}\]\}`;
        str = this.replaceAll(str, findStr, widths[i].toFixed(4));
      }
      return str;
    }

    replaceAll(str: string, pattern: string, replacement: string) {
      while (str.indexOf(pattern) !== -1) {
        str = str.replace(pattern, replacement);
      }
      return str;
    }
}
