import { NgxZendeskWebwidgetConfig } from './ngx-zendesk-webwidget.model';
import * as i0 from "@angular/core";
import * as i1 from "./ngx-zendesk-webwidget.model";
function getWindow() {
    return window;
}
export class NgxZendeskWebwidgetService {
    constructor(ngxZendeskWebwidgetConfig) {
        this.ngxZendeskWebwidgetConfig = ngxZendeskWebwidgetConfig;
        this.initialized = false;
        if (!this.ngxZendeskWebwidgetConfig.accountUrl) {
            throw new Error('Missing accountUrl. Please set in app config via ZendeskWidgetProvider');
        }
        this.window = getWindow();
        if (!this.ngxZendeskWebwidgetConfig.lazyLoad) {
            this.initZendesk();
        }
    }
    initZendesk() {
        const window = this.window;
        const config = this.ngxZendeskWebwidgetConfig;
        // tslint:disable
        window.zEmbed || function (e, t) {
            let n, o, d, i, s, a = [];
            let r = document.createElement("iframe");
            window.zEmbed = function () {
                a.push(arguments);
            };
            window.zE = window.zE || window.zEmbed;
            r.src = "javascript:false";
            r.title = "";
            r.style.cssText = "display: none";
            d = document.getElementsByTagName(config.injectionTag || "head");
            d = d[d.length - 1];
            d.parentNode.insertBefore(r, d);
            i = r.contentWindow;
            s = i.document;
            try {
                o = s;
            }
            catch (e) {
                n = document.domain;
                r.src = 'javascript:var d=document.open();d.domain="' + n + '";void(0);';
                o = s;
            }
            o.open()._l = function () {
                let e = this.createElement("script");
                n && (this.domain = n);
                e.id = "js-iframe-async";
                e.src = "https://static.zdassets.com/ekr/snippet.js";
                this.t += new Date;
                this.zendeskHost = config.accountUrl;
                this.zEQueue = a;
                this.body.appendChild(e);
            };
            o.write('<body onload="document._l();">');
            o.close();
        }();
        // tslint:enable
        return this.finishLoading();
    }
    finishLoading() {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                this.initialized = false;
                reject(Error('timeout'));
            }, this.ngxZendeskWebwidgetConfig.timeOut || 30000); // 30 seconds
            this.window.zE(() => {
                this.ngxZendeskWebwidgetConfig.callback(this.window.zE);
                this.initialized = true;
                this._zE = this.window.zE;
                clearTimeout(timeout);
                resolve(true);
            });
        });
    }
    get isInitialized() {
        return this.initialized;
    }
    get zE() {
        return this._zE;
    }
}
NgxZendeskWebwidgetService.ɵfac = function NgxZendeskWebwidgetService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || NgxZendeskWebwidgetService)(i0.ɵɵinject(i1.NgxZendeskWebwidgetConfig)); };
NgxZendeskWebwidgetService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: NgxZendeskWebwidgetService, factory: NgxZendeskWebwidgetService.ɵfac, providedIn: 'root' });
