import {
  TranslateService
} from "@ngx-translate/core";
import { json2ts } from "../../util/json-2ts";
import { ThresholdFormatterConfig } from "../../model/config/threshold-formatter-config.model";

import * as Config from "../../util/config.constants";
import { Logger } from "../logging/logger";
import { BaseFormatter } from "./base-formatter";
import {
  FormatterService
} from "./formatter.service";
import * as d3 from "d3";

export class ThresholdBasedFormatter extends BaseFormatter {

  thresholdFormatterConfig: ThresholdFormatterConfig;

  constructor(locale: d3.Locale, config: any, translateService: TranslateService, formatterService: FormatterService, logger: Logger) {

    super(locale, config, translateService, formatterService, logger);
    if (null == this.config) {
      throw new Error(`No configuration supplied for ThresholdBasedFormatter!
        Either configuration was missing or one of formatString / value option was not set!`);
    }
    this.thresholdFormatterConfig = json2ts(config, ThresholdFormatterConfig);
  }

  public override formatData(value: any): string {
    try {
      if (!(value instanceof Number)) {
        value = parseFloat(value);
      }
    } catch {
      return null == this.thresholdFormatterConfig.default ? value : this.thresholdFormatterConfig.default[Config.VALUE];
    }

    let formattedData = null == this.thresholdFormatterConfig.default ? value : this.thresholdFormatterConfig.default[Config.VALUE];
    if (isNaN(value)) {
      return formattedData;
    }

    for (const curr of this.thresholdFormatterConfig.thresholds) {
      if (value < curr.threshold) {
        break;
      }
      formattedData = curr[Config.VALUE];
    }

    return formattedData;
  }

}
