import { copyObjectWithoutNulls } from "../../util/object-utils";
import { BaseSelector } from "./base-selector";
export class StyleSelector extends BaseSelector {
    constructor() {
        super(...arguments);
        this._styles = {};
    }
    styles(properties) {
        if (!properties || !properties.id) {
            // Can't do anything so just return an empty array of styles.
            return [];
        }
        if (properties.id === "*") {
            return copyObjectWithoutNulls(this.config);
        }
        const lookupKey = properties.id;
        if (this._styles.hasOwnProperty(lookupKey)) {
            return copyObjectWithoutNulls(this._styles[lookupKey]);
        }
        const tStyle = this.config[lookupKey];
        this._styles[lookupKey] = tStyle;
        return copyObjectWithoutNulls(this._styles[lookupKey]);
    }
}
