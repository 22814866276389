import { Actions, createEffect, ofType } from "@ngrx/effects";
import { MutationType } from "../dashboard/components/config/mutation-type.enum";
import { Period } from "../model/alert/period.model";
import { DataService } from "../services/data/data.service";
import { copyObjectWithoutGraphQLMetaKeys } from "../util/object-utils";
import { ADD_COMMENT, ADD_TASK_COMMENT, DELETE_COMMENT, MutationFailed, MutationSuccessful } from "./mutation.actions";
import { Timestamp } from "../model/timestamp.model";
import { switchMap } from "rxjs/operators";
import { DELETED, UPDATED } from "../util/string-constants";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../services/data/data.service";
const _LOG_ID = "comment.effects";
// tslint:disable-next-line:max-line-length
const _ADD_COMMENT_GQL = `mutation ($authorID: String!, $primaryReference: ReferenceInput!, $additionalReferences: [ReferenceInput!], $timestamp:DateTimeInput!, $period: PeriodInput!, $comment:String!, $updateGUID: String) {
  AddComment(Author: {id: $authorID}, PrimaryReference: $primaryReference, AdditionalReferences: $additionalReferences, Timestamp: $timestamp, Period: $period, comment: $comment, updateGUID: $updateGUID) {
    guid
    id
    comment
    Author {
      id
      guid
      displayName
      avatarURL
    }
    PrimaryReference {
      Entity {
        id
        guid
        name
      }
      Location {
        id
        guid
        name
      }
      Region {
        id
        guid
        name
      }
    }
  }
}
`;
const _DELETE_COMMENT_GQL = `mutation ($timestamp:DateTimeInput!, $updateGUID: String!) {
  DeleteComment(Timestamp: $timestamp, updateGUID: $updateGUID)
}
`;
const _ADD_TASK_COMMENT_GQL = `mutation ($taskGUID: String!, $authorID: String!, $additionalReferences: [ReferenceInput!], $timestamp:DateTimeInput!, $comment:String!, $replyToGUID: String, $updateGUID: String) {
  AddTaskComment(taskGUID: $taskGUID, Author: {id: $authorID}, AdditionalReferences: $additionalReferences, Timestamp: $timestamp, comment: $comment, replyToGUID: $replyToGUID, updateGUID: $updateGUID) {
    guid
    id
    comment
    Author {
      id
      guid
      displayName
      avatarURL
    }
  }
}
`;
export class CommentEffects {
    constructor(_action$, _dataService) {
        this._action$ = _action$;
        this._dataService = _dataService;
        this.commentAdded$ = createEffect(() => this._action$
            .pipe(ofType(ADD_COMMENT), switchMap(async (action) => {
            return this.saveComment(action);
        })));
        this.taskCommentAdded$ = createEffect(() => this._action$
            .pipe(ofType(ADD_TASK_COMMENT), switchMap(async (action) => {
            return this.saveTaskComment(action);
        })));
        this.commentDeleted$ = createEffect(() => this._action$
            .pipe(ofType(DELETE_COMMENT), switchMap(async (action) => {
            console.log("DELETE CALLED");
            return this.deleteComment(action);
        })));
    }
    async saveComment(action) {
        const cmt = action.payload;
        const updateGUID = cmt.id;
        const translationSuffix = updateGUID != null ? UPDATED.toUpperCase() : null;
        const params = {
            authorID: cmt.Author.id,
            primaryReference: cmt.InputReferences.Primary,
            additionalReferences: cmt.InputReferences.Additional.length > 0 ? cmt.InputReferences.Additional : null,
            timestamp: Timestamp.ToValidInputObject(cmt.Timestamp),
            period: (copyObjectWithoutGraphQLMetaKeys((cmt.Period || Period.NO_PERIOD))),
            comment: cmt.comment,
            updateGUID: updateGUID
        };
        try {
            await this._dataService.mutate(_LOG_ID, _ADD_COMMENT_GQL, params);
            console.log("COMMENT SAVED");
            return new MutationSuccessful([MutationType.Comment], translationSuffix);
        }
        catch (err) {
            console.log(`COMMENT __NOT__ SAVED!:

      ${err}`);
            return new MutationFailed(err, [MutationType.Comment], translationSuffix);
        }
    }
    async saveTaskComment(action) {
        const cmt = action.payload;
        const updateGUID = cmt.id;
        const translationSuffix = updateGUID != null ? UPDATED.toUpperCase() : null;
        const params = {
            authorID: cmt.Author.id,
            taskGUID: cmt.InputReferences.Additional[0].id,
            additionalReferences: cmt.InputReferences.Additional.length > 0 ? cmt.InputReferences.Additional : null,
            timestamp: Timestamp.ToValidInputObject(cmt.Timestamp),
            comment: cmt.comment,
            updateGUID: updateGUID
        };
        console.log("SAVING THIS TASK COMMENT", params);
        try {
            await this._dataService.mutate(_LOG_ID, _ADD_TASK_COMMENT_GQL, params);
            console.log("TASK COMMENT SAVED");
            return new MutationSuccessful([MutationType.Comment], translationSuffix);
        }
        catch (err) {
            console.log(`TASK COMMENT __NOT__ SAVED!:

      ${err}`);
            return new MutationFailed(err, [MutationType.Comment], translationSuffix);
        }
    }
    async deleteComment(action) {
        const cmt = action.payload;
        const updateGUID = cmt.id;
        const translationSuffix = DELETED.toUpperCase();
        const params = {
            timestamp: Timestamp.ToValidInputObject(cmt.Timestamp),
            updateGUID: updateGUID
        };
        console.log("DELETE", JSON.stringify(params, null, 2));
        try {
            await this._dataService.mutate(_LOG_ID, _DELETE_COMMENT_GQL, params);
            console.log("COMMENT DELETED");
            return new MutationSuccessful([MutationType.Comment], translationSuffix);
        }
        catch (err) {
            console.log(`COMMENT __NOT__ DELETED!:

      ${err}`);
            return new MutationFailed(err, [MutationType.Comment], translationSuffix);
        }
    }
}
CommentEffects.ɵfac = function CommentEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || CommentEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.DataService)); };
CommentEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: CommentEffects, factory: CommentEffects.ɵfac });
