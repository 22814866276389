import { Injectable } from "@angular/core";
import {
  TranslateService
} from "@ngx-translate/core";

import {
  UserContext
} from "../../context/user.reducers";
import {
  FeatureKey
} from "../../model/user/feature-key.enum";
import { json2ts } from "../../util/json-2ts";
import {
  COMMA_SPACE
} from "../../util/string-constants";
import {
  formattedTimeString
} from "../../util/utils";
import { Logger } from "../logging/logger";
import {
  LoggingService
} from "../logging/logging.service";
import {
  SupportServiceConfiguration
} from "./support-configuration.model";
import {
  SupportWidget
} from "./support-widget.interface";
import {
  ZendeskSupportWidget
} from "./zendesk/zendesk-support-widget";

const LOG_NAMESPACE: string = "services.support";
const WIDGET_CLASS_MAP: {[type: string]: any} = {
  zendesk: ZendeskSupportWidget
};
@Injectable()
export class SupportService {

  private _logger: Logger;
  private _supportWidget: SupportWidget = null;
  private _userSupportWidgetFeatureEnabled: boolean = true;

  public async init(supportServiceConfig: any) {
    this._logger.info(formattedTimeString() + " Initializing Support Service...");
    const sConfig = json2ts(JSON.parse(supportServiceConfig), SupportServiceConfiguration);
    const provider = sConfig.provider.toLowerCase();
    if (!(provider in WIDGET_CLASS_MAP)) {
      this._logger.info(`Provider '${provider}' is not registered.  Provider must be one of '${WIDGET_CLASS_MAP["keys"]().join(COMMA_SPACE)}'.  Not instantiating a support service.`);
      return;
    }
    if (null == this._supportWidget) {
      const clazz = WIDGET_CLASS_MAP[provider]
      const instance: SupportWidget = new clazz();
      await instance.init(sConfig, this._translateService, this._loggingService);
      this._supportWidget = instance;
    }
  }

  public async onAuthenticationComplete(userDetails: UserContext) {
    if (null == this._supportWidget) {
      return;
    }
    this._userSupportWidgetFeatureEnabled =  userDetails.profile.Features.feature(FeatureKey.ShowSupportWidget, false, false);

    if (this._userSupportWidgetFeatureEnabled) {
      await this._supportWidget.onAuthenticationComplete(userDetails);
    } else {
      this._logger.warn("User support widget feature is disabled.  No support widget will appear.");
    }
  }

  public constructor(private _loggingService: LoggingService, private _translateService: TranslateService) {
    this._logger = new Logger(LOG_NAMESPACE, _loggingService);
    this._logger.warn("Support Service instantiated");
  }

}
