import {
  Series
} from "../../dashboard/components/config/series";
import {
  IFormatter
} from "../formatter/i-formatter.interface";
import { TooltipBase } from "./base-tooltip";
import { BusinessDate } from "../../model/business-dates/business-date.model";

import * as GQL from "../../util/graphql-tags";
import { translateUsingSuffix } from "../../util/translation-helpers";

const ORDER_BY_SERIES_KEY = "order-by-series-key";
const NO_VALUE = "NA";

export class StackedAreaTooltip extends TooltipBase {
  salesFormatter: IFormatter;
  override formatters: {} = {};

  public override generateTooltipContent(data: any, params?: any): string {
    this._logService.debug("Generating StackedArea Tooltip of data", {tooltipData: data, parameter: params});
    const dataKey = params["data-key"] ? params["data-key"] : "point";
    let businessDate: BusinessDate;
    if (null != data.data && null != null != data.data[GQL.BUSINESS_DATE]) {
      businessDate = data.data[GQL.BUSINESS_DATE];
    } else {
      try {
        businessDate = data["series"][0][dataKey][GQL.BUSINESS_DATE];
      } catch (e) {
        businessDate = data["series"][0]["data"][GQL.BUSINESS_DATE];
      }
    }
    const formatterConfig = this._config["formatters"];
    const headerFormatter = this._formatterService.formatter(formatterConfig.header);
    const header = headerFormatter.formatData(businessDate);
    this.salesFormatter = this._formatterService.formatter(formatterConfig.sales);
    let additionalValueFormatter: IFormatter;

    this._initializeFormattersFromUnits(params.units);

    if (formatterConfig.additionalValue) {
      additionalValueFormatter = this._formatterService.formatter(formatterConfig.additionalValue);
    }

    let popupHTML = '<table style="width: 95%; max-width: 300px; font-variant: small-caps;">';

    if (this._config["showHeader"]) {
      popupHTML += '<thead><tr> <td class="store-popup-title" colspan="3"> ' + header + "</td></tr></thead>";
    }
    popupHTML = this.getTitles(params, popupHTML);

    const seriesDefinitions: Series[] = data.seriesDefinitions;
    let total: number;
    let lastYearTotal: number;
    if (true === params["no-total"]) {
      total = data["series"].reduce((t: number, n: any) => t + n.value, 0);
    } else {
      total = data.series.length > 1 ? data["series"][data.series.length - 1]["value"] : undefined;
      if (params["includes-prior-period"]) {
        lastYearTotal = data["series"].map((seriesObj: any) => seriesObj[dataKey] ? seriesObj[dataKey].ly : null).reduce((ly1: any, ly2: any) => {
          if (ly2 == null) {
            return ly1;
          }
          return ly1 + ly2;
        });
      }
    }
    const legendClass = "legend-color-guide-line";
    if (null != data.meta) {
      const periodIndex = data.series[0].point.index;
      data.meta.forEach((metaDatum: any) => {
        metaDatum.value = metaDatum.values[periodIndex].y;
        if (metaDatum.type === "line") {
          popupHTML += this.generateAdditionalSeriesHTML(metaDatum, data, seriesDefinitions);
        } else {
          popupHTML += this.generateSeriesHTML(metaDatum, total, additionalValueFormatter,
            header, seriesDefinitions, legendClass, params);
        }
      });
    } else {
      const tooltipOrder = params[ORDER_BY_SERIES_KEY];
      if (tooltipOrder !== undefined && tooltipOrder.length > 0) {
        tooltipOrder.forEach((seriesKey: number) => {
          let values = data.series.filter((item: any) => {
            if (true === item.total) {
              return false;
            }
            return item.point.seriesKey === seriesKey;
          });
          if (values.length > 0) {
            values.forEach((value: any) => {
                popupHTML += this.generateSeriesHTML(value, total, additionalValueFormatter,
                      header, seriesDefinitions, legendClass, params);
            });
            } else {
              values = params.additionalData.filter((item: any) => item.seriesKey === seriesKey );
              if (values.length <= 0) {
                throw new Error("series and additional-data does not have seriesKey provided in " + ORDER_BY_SERIES_KEY);
              }
              values.forEach((value: any) => {
                popupHTML += this.generateAdditionalSeriesHTML(value, data, seriesDefinitions);
              });
            }
        });
      } else {
        if (params.additionalData != null) {
          params.additionalData.forEach((additionalSeries: any) => {
            popupHTML += this.generateAdditionalSeriesHTML(additionalSeries, data, seriesDefinitions);
          });
        }
        const rows: any[] = data.series;
        const mergedRows: any[] = [];

        if (params["merge-keys"]) {
          rows.forEach(row => {
            const foundRow = mergedRows.find(mergedRow => mergedRow.key === row.key);
            if (undefined === foundRow) {
              mergedRows.push(row);
              return;
            }
            foundRow.value += row.value;
          });
        }
          
        (mergedRows.length !== 0 ? mergedRows : rows).forEach((ele: any, idx: number) => {

          if (null != params.keyTranslations &&
              null != params.keyTranslations[idx] &&
              ele.total !== true
            ) {
            ele.key = params.keyTranslations[idx];
          }

          popupHTML += this.generateSeriesHTML(ele, total, additionalValueFormatter, header, seriesDefinitions, legendClass, params, lastYearTotal);
        });
      }
    }

    popupHTML += "</tbody></table>";
    return popupHTML;
  }

  private getLegendClass(data: any) {
    switch (data.type) {
      case "line":
        if (data.seriesDefinition && data.seriesDefinition.cssClass === "dashed") {
          return "legend-color-guide-dotted-line";
        }
        return "legend-color-guide-line";
      case "area":
      return "legend-color-guide-area";
      case "tooltip":
      case "empty":
      return "";
      default:
      return "legend-color-guide-dotted-line";
    }
  }

  private generateAdditionalSeriesHTML(additionalSeries: any, data: any, seriesDefinitions: any) {
    if (additionalSeries["values"][data["value"]]) {
      const val = additionalSeries["values"][data["value"]]["y"];
      const additionalLegendClass = this.getLegendClass(additionalSeries);
      let popupHTML = '<tr class="store-popup-text"><td align="right" class="legend-icon '
      + additionalLegendClass + '" style="vertical-align:middle;"><div';
      popupHTML += ' style="color:' + additionalSeries["colour"] + '"></div></td>';
      popupHTML += '<td class="key" colspan="2" style="color:#555555;text-align: left;">' + additionalSeries["key"] + "</td>";
      popupHTML +=  '<td class="value" colspan="2" style="color:#555555;">';
      if (val !== null) {
        const seriesId = seriesDefinitions.find((sd: any) => sd.seriesKey === additionalSeries.seriesKey).referenceSeriesID;
        popupHTML += this._getFormattedValue(val, seriesId);
      } else {
        popupHTML += NO_VALUE;
      }
      popupHTML += "</td></tr>";
      return popupHTML;
    } else {
      return ""
    }
  }

  private generateSeriesHTML(ele: any, total: any, additionalValueFormatter: any, header: any,
                             seriesDefinitions: any, legendClass: any, params: any, lastYearTotal?: number) {
    let val = ele["value"];
    let lyValue = ele.point ? ele.point.ly: null;

    let key = ele["key"];
    const translatedKey = translateUsingSuffix(key, params.dimensionNameTranslationSuffix, this._translateService);
    if (key === "TOTAL") {
      key = "<b>" + key + " " + header + "</b>";
      lyValue = lastYearTotal;
    }

    if (params["show-null-values"] === false && null == val) {
      return "";
    }
    const share: any = total ? additionalValueFormatter.formatData((val / total) * 100) : NO_VALUE;

    const lyShare: any = null != lastYearTotal && null != lyValue ? additionalValueFormatter.formatData((lyValue / lastYearTotal) * 100) : NO_VALUE;

    if (null != ele.data && null != params.units) {
      const seriesId = ele.data.seriesDefinition.seriesID;
      val = this._getFormattedValue(val, seriesId);
    } else {
      val = this._getFormattedValue(val, undefined);
      lyValue = this._getFormattedValue(lyValue, undefined);
    }
    const seriesColour = ele["color"];
    let hilightedStyle = "";
    if (ele["highlight"]) {
      hilightedStyle = "border-bottom-style: solid; border-top-style: solid; border-width:1px; border-color:" + seriesColour + ";";
    }

    let popupHTML = '<tr class="store-popup-text" style="' + hilightedStyle + '">';
    if (this._config["showColorCode"]) {
      legendClass = "legend-color-guide-area";
    }
    let colorProperty = "background-color";
    if (ele["data"]) {
      legendClass = this.getLegendClass(ele["data"]);
      colorProperty = ele["data"]["type"] === "line" ? "color" : "background-color";
    }

    popupHTML += '<td align="right" class="legend-icon ' + legendClass + '" style="vertical-align:middle;"><div';

    popupHTML += ` style="${colorProperty}:${seriesColour}"></div></td>`;

    if (this._config["showLabel"]) {
      popupHTML += '<td class="key" colspan="2" style="font-variant: small-caps;color:#555555;text-align: left;">' + translatedKey + "</td>";
    }

    popupHTML +=  '<td class="value" colspan="2" style="color:#555555;text-align:right;">';
    popupHTML += val !== null ? val : NO_VALUE;
    popupHTML += "</td>";


    if (undefined === params["show-share"] || params["show-share"]) {
      popupHTML += '<td colspan="2" class="value" style="color:#555555;text-align:right;">' + share + "</td>";
    }

    if (params["includes-prior-period"]) {
      popupHTML +=  '<td class="value" colspan="2" style="color:#555555;text-align:right;">';
      popupHTML += lyValue ? lyValue : "";
      popupHTML += "</td>";
      popupHTML +=  '<td class="value" colspan="2" style="color:#555555;text-align:right;">';
      popupHTML += lyShare;
      popupHTML += "</td>";
    }

    popupHTML += "</tr>";
    return popupHTML;
  }

  private _getFormattedValue(value: string, seriesId: string) {
    if (null != seriesId) {
      return this.formatters[seriesId] ? this.formatters[seriesId].formatData(value) :
        this.formatters["default"] ? this.formatters["default"].formatData(value) : this.salesFormatter.formatData(value);
    } else {
      return this.salesFormatter.formatData(value);
    }
  }

  private _initializeFormattersFromUnits(units: {}) {
    if (units) {
      Object.keys(units).forEach((seriesId) => {
        this.formatters[seriesId] = this._formatterService.formatter(units[seriesId]);
      });
    }
  }

  private getTitles(params: any, popupHTML: string) {

    if (params["includes-prior-period"]) {
      popupHTML += `<tr class="store-popup-text" style="">
        <td class="value" style="color:#555555;text-align: left;"></td>
        <td class="value" colspan="2" style="color:#555555;text-align: left;"></td>
        <td class="value" colspan="4" style="color:#555555;text-align: center;">This Year</td>
        <td class="value" colspan="4" style="color:#555555;text-align: center;">Last Year</td>
        </tr>`;
    }

    if (undefined === params["show-titles"] || params["show-titles"]) {
      popupHTML += `<tr class="store-popup-text" style="">
        <td class="value" style="color:#555555;text-align: left;"></td>
        <td class="value" colspan="2" style="color:#555555;text-align: left;"></td>`;

      const titlesMap = [0];
      if (params["includes-prior-period"]) {
        titlesMap.push(1);
      }

      titlesMap.forEach(() => {
        popupHTML += `<td class="value" colspan="2" style="color:#555555;">
          ${null != params.seriesLabel ? this._translateService.instant(params.seriesLabel) : "Sales"}
        </td>`;
        // If show-share key is not present, then it means we have to show or even if its true
        if (undefined === params["show-share"] || params["show-share"]) {
        popupHTML += `<td class="value" colspan="2" style="color:#555555;text-align:right;">
          ${null != params.shareLabel ? this._translateService.instant(params.shareLabel) : "Share"}
        </td>`;
        }
      });

      popupHTML += `</tr>`;
    }
    return popupHTML;
  }

}
