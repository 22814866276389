import { ThresholdFormatterConfig } from "../../model/config/threshold-formatter-config.model";
import {
  Logger
} from "../logging/logger";
import { BaseSelector } from "./base-selector";
import { BrandService } from "./brand.service";
import {
  ISelectorProperties
} from "./selector.properties.interface";
import { json2ts } from "../../util/json-2ts";
import * as jp from "jsonpath";

export class DynamicThresholdSelector extends BaseSelector {

  thresholdFormatterConfig: ThresholdFormatterConfig;

  public constructor(_code: string, _config: any, _brandService: BrandService, _logger: Logger) {
    super(_code, _config, _brandService, _logger);

    this.thresholdFormatterConfig = json2ts(_config, ThresholdFormatterConfig);
  }

  // unadulterated function from the answer in -
  // 'https://stackoverflow.com/questions/65896453/generating-a-list-of-evenly-distributed-round-numbers-within-a-range-from-min-to'
  private getThresholdRange(min: number, max: number, steps: number) {
    // minimum step size
    let stepsize = (max - min) / steps;
    // increase the step size to a nice boundary
    // for example, 1/10th of the 10^n range that includes it
    const pow = Math.trunc(Math.log10(stepsize)) - 1;
    stepsize = Math.trunc(stepsize / 10 ** pow) * 10 ** pow;
    // round min to the same boundary
    const result = [min];
    min = Math.trunc(min / 10 ** pow) * 10 ** pow;
    for (let i = 0; i < steps - 1; i++) {
      min += stepsize;
      result.push(min);
    }
    result.push(max);
    return result;
  }

  private getThresholdIndex(value: number, thresholdRange: number[]): number {
    let thresholdIndex = -1;

    let _rangeIdx = 0;
    for (const rangeNumber of thresholdRange) {
      ++_rangeIdx;
      if (value < rangeNumber) {
        break;
      }
      thresholdIndex = _rangeIdx - 1;
    }
    return thresholdIndex;
  }

  private getThresholdRangeWithOffset(min: number, max: number, steps: number) {
    const fmin = min - 1;
    const fmax = max - 1;
    return this.getThresholdRange(fmin, fmax, steps);
  }

  public override styles(properties: ISelectorProperties): {[type: string]: any} {
    if (!properties || !properties.value || !properties.values) {
      return null != this.thresholdFormatterConfig.default ? this.thresholdFormatterConfig.default[properties.type || "styles"] : null;
    }
    const [min, max] = properties.values;
    const totalBuckets = this.thresholdFormatterConfig.thresholds.length + 1;
    const thresholdRange = this.getThresholdRangeWithOffset(min, max, totalBuckets - 2);
    this._logger.info("Styles of properties", {props: properties});

    let value: any = properties.value;

    if (null != properties.jsonPath && null != properties.jsonData) {
      // Get value by running JSON path on properties.jsonData
      value = jp.query(properties.jsonData, properties.jsonPath)[0];
    } else {
      value = properties.value;
      if (typeof value === "string") {
        value = value.replace(/[!@#$%^&*£$,]/g, "");
      }
    }

    let data = null == this.thresholdFormatterConfig.default ? value : this.thresholdFormatterConfig.default[properties.type];
    try {
      if (!(value instanceof Number)) {
        value = parseFloat(value);
      }
    } catch {
      return data;
    }

    if (isNaN(value)) {
      return data;
    }

    const bucketIndex = this.getThresholdIndex(value, thresholdRange);

    if (bucketIndex !== -1) {
      data = this.thresholdFormatterConfig.thresholds[bucketIndex][properties.type]
    }

    return data;
  }

}
