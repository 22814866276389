import { __decorate, __metadata } from "tslib";
import { AssertionError } from "../../util/assertion-error";
import { JsonConverter, JsonObject } from "json2typescript";
import * as constants from "../../util/string-constants";
let Settings = class Settings {
    constructor(data = {}) {
        try {
            this._data = JSON.parse(data);
        }
        catch (e) {
            this._data = data;
        }
    }
    serialize() {
        return this._data || {};
    }
    setting(key, throwOnMissing = true, defaultValueIfMissing = null, keySuffix = null) {
        const suffixedKey = key + (null != keySuffix ? constants.DOT + keySuffix : "");
        if (null != this._data && suffixedKey in this._data) {
            return this._data[suffixedKey];
        }
        else {
            if (throwOnMissing) {
                throw new AssertionError({
                    message: `Key '${suffixedKey}' was not present in the settings.`,
                    expected: "A value",
                    actual: "No value"
                });
            }
            else {
                return defaultValueIfMissing;
            }
        }
    }
};
Settings = __decorate([
    JsonObject("Settings"),
    __metadata("design:paramtypes", [Object])
], Settings);
export { Settings };
// tslint:disable-next-line: max-classes-per-file
let SettingsConverter = class SettingsConverter {
    serialize(data) {
        return data ? data.serialize() : null;
    }
    deserialize(val) {
        if (null == val) {
            return null;
        }
        return new Settings(val);
    }
};
SettingsConverter = __decorate([
    JsonConverter
], SettingsConverter);
export { SettingsConverter };
