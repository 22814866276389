export class AssertionError extends Error {
  actual: unknown;
  expected: unknown;
  operator: string;
  generatedMessage: boolean;
  code: "ERR_ASSERTION";
  constructor(options?: {
      /** If provided, the error message is set to this value. */
      message?: any;
      /** The `actual` property on the error instance. */
      actual?: unknown | undefined;
      /** The `expected` property on the error instance. */
      expected?: unknown | undefined;
      /** The `operator` property on the error instance. */
      operator?: string | undefined;
      /** If provided, the generated stack trace omits frames before this function. */
      // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
      stackStartFn?: Function | undefined;
  }) {
    super(options.message);
  };
}
