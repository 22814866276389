import { TranslateService } from "@ngx-translate/core";

import {
  Logger
} from "../logging/logger";
import { BaseFormatter } from "./base-formatter";
import {
  FormatterDictionary,
  FormatterService
} from "./formatter.service";
import {
  IFormatter
} from "./i-formatter.interface";

import * as Config from "../../util/config.constants";

export class PropertyBasedFormatter extends BaseFormatter {

  private _formattersByType: FormatterDictionary;
  private _defaultFormatter: IFormatter;
  private _propertyName: string;

  public constructor(locale: any, config: any, translateService: TranslateService, formatterService: FormatterService, logger: Logger) {
    super(locale, config, translateService, formatterService, logger);
    this._configure(config);
  }

  public override formatData(data: any, params?: any): string {
    const formatter = this._formatterForData(data, params);
    params = this._paramsForDownstreamFormatter(formatter, params);
    const ret =  formatter.formatData(data, params);
    return ret;
  }

  private _paramsForDownstreamFormatter(formatter: IFormatter, params?: any) {
    if (null != formatter[Config.PARAMS]) {
      return formatter[Config.PARAMS];
    } else {
      return params;
    }
  }

  private _propertyValueFromParams(data: any, params: any): string {
    if (null == params || null == params[Config.VALUE]) {
      if (null == data) {
        return "";
      }
      return data[this._propertyName];
    } else {
      return params[Config.VALUE];
    }
  }

  private _formatterForData(data: any, params: any): IFormatter {
    const propertyValue = this._propertyValueFromParams(data, params);
    let ret: IFormatter;
    if (propertyValue in this._formattersByType) {
      ret = this._formattersByType[propertyValue];
    } else {
      ret = this._defaultFormatter;
    }
    return ret;
  }

  private _configure(config: any): void {

    this._propertyName = config[Config.PROPERTY_NAME];
    if (null == this._propertyName) {
      // tslint:disable-next-line:max-line-length
      throw new Error(`No property name to determine which formatter to use defined in configuration ${JSON.stringify(config, null, 2)}.  Cannot continue!`);
    }
    const defaultFormatter = config[Config.DEFAULT];
    if (null == defaultFormatter) {
      throw new Error(`No default formatter defined in configuration ${JSON.stringify(config, null, 2)}.  Cannot continue!`);
    }

    const formatterConfig = config[Config.FORMATTERS];
    if (null == formatterConfig) {
      throw new Error(`No formatters defined in configuration ${JSON.stringify(config, null, 2)}.  Cannot continue!`);
    }

    this._formattersByType = Object.keys(formatterConfig).reduce((dict: FormatterDictionary, currType: string) => {
      const formatterNode = formatterConfig[currType];
      const formatterCode = formatterNode[Config.FORMATTER];
      let formatter: IFormatter;
      if (null != formatterCode) {
        formatter = this._formatterService.formatter(formatterCode);
      } else {
        formatter = this._formatterService.instantiateFormatter(formatterNode);
      }
      dict[currType] = formatter;
      formatter[Config.PARAMS] = formatterNode[Config.PARAMS];
      return dict;
    }, {});

    if (!(defaultFormatter in this._formattersByType)) {
      // tslint:disable-next-line:max-line-length
      throw new Error(`Default formatter for type based BusinessDateFormatter "${defaultFormatter}" was not one of the formatters specified in collection: ${JSON.stringify(this._formattersByType, null, 2)}.  Cannot continue!`);
    } else {
      this._defaultFormatter = this._formattersByType[defaultFormatter];
    }

  }

}
