import { TooltipBase } from "./base-tooltip";
import * as GQL from "../../util/graphql-tags";
const KEY = "key";
const VALUE = "value";
const DATA = "data";
const COLOUR = "color";
const TITLE = "title";
export class DiscreteBarTooltip extends TooltipBase {
    constructor() {
        super(...arguments);
        this._formatter = this._formatterService.formatter(this._config["formatters"]["business-date-formatter"]);
    }
    /*
    * Have to modify nvd3 code to tell us if the series isArea, on hover, so that
    * we can change the shape of bullet based on line type.
    */
    generateTooltipContent(data, params) {
        this._logService.debug("Generating DiscreteBar ToolTip of data: ", { tooltipData: data, parameter: params });
        data = data[DATA];
        const businessDate = data[KEY][GQL.BUSINESS_DATE];
        let title = "";
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                if (key === TITLE) {
                    const value = params[key];
                    title = this._translateService.instant(value);
                }
            }
        }
        const popupHTML = `
                      <table width="95%" style="font-variant: small-caps;">
                        <thead>
                          <tr>
                            <td colspan="3">${title}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td align="right" class="legend-icon legend-color-guide">
                              <div style="background-color: ${data[COLOUR]}"></div>
                            </td>
                            <td class="key" style="text-align: left;">${this._formatter.formatData(businessDate)}</td>
                            <td class="value">${data[VALUE]}</td>
                          </tr>
                        </tbody>
                      </table>`;
        return popupHTML;
    }
}
