import { __decorate } from "tslib";
import { JsonConverter } from "json2typescript";
import { TemporalAggregation } from "../../model/business-dates/temporal-aggregation.enum";
let TemporalAggregationConverter = class TemporalAggregationConverter {
    serialize(data) {
        return data ? data.toString() : "";
    }
    deserialize(val) {
        return TemporalAggregation[val];
    }
};
TemporalAggregationConverter = __decorate([
    JsonConverter
], TemporalAggregationConverter);
export { TemporalAggregationConverter };
// tslint:disable-next-line:max-classes-per-file
let TemporalAggregationArrayConverter = class TemporalAggregationArrayConverter {
    serialize(data) {
        if (!data) {
            return undefined;
        }
        return data.map((item) => item.toString());
    }
    deserialize(val) {
        if (!val) {
            return [];
        }
        const ret = val.map((item) => TemporalAggregation[item]);
        return ret.filter((item) => undefined !== item && null !== item);
    }
};
TemporalAggregationArrayConverter = __decorate([
    JsonConverter
], TemporalAggregationArrayConverter);
export { TemporalAggregationArrayConverter };
