import {
  NgxZendeskWebwidgetConfig,
  NgxZendeskWebwidgetService
} from "../../../../packages/ngx-zendesk-webwidget";

import {
  UserContext
} from "../../../context/user.reducers";
import { json2ts } from "../../../util/json-2ts";
import { Logger } from "../../logging/logger";
import {
  LoggingService
} from "../../logging/logging.service";
import {
  SupportServiceConfiguration
} from "../support-configuration.model";
import {
  SupportWidget
} from "../support-widget.interface";
import {
  ZendeskSupportWidgetConfiguration
} from "./zendesk-support-configuration.model";
import { ASTERISK } from "../../../util/string-constants";
import { TranslateService } from "@ngx-translate/core";
import * as Strings from "../../../util/string-constants"

const LOG_NAMESPACE = "ZendeskSupportWidget";

const _TARGET_WEB_WIDGET = "webWidget";
const _METHOD_PREFILL = "prefill";
const _METHOD_UPDATE_SETTINGS = "updateSettings";


export class ZendeskSupportWidget implements SupportWidget {

  private _logger: Logger;
  private _translateService: TranslateService;
  private _zendeskConfig: NgxZendeskWebwidgetConfig = null;
  private _zendeskWidget: NgxZendeskWebwidgetService;
  private _initialized: boolean = false;
  private _userDetails: UserContext = null;

  public async onAuthenticationComplete(userDetails: UserContext) {
    this._userDetails = userDetails;
    this._logger.debug("onAuthenticationComplete", this._initialized, this._userDetails);

    this._logger.info("Initializing Zendesk widget", this._zendeskConfig);
    this._zendeskWidget = new NgxZendeskWebwidgetService(this._zendeskConfig);
  }

  private _completeInitialization() {
    this._logger.info("Setting Pre-fill Fields");
    const userName: string = this._userDetails.profile.displayName;
    const userEmail: string = this._userDetails.profile.email;
    const zdUserDetails = {
      name: {
        value: userName,
        readOnly: true
      },
      email: {
        value: userEmail,
        readOnly: true
      }
    };

    this._logger.debug("Setting prefill fields on Zendesk support widget and marking read-only...", zdUserDetails);
    (window as any).zE(_TARGET_WEB_WIDGET, _METHOD_PREFILL, zdUserDetails);
  }


  private _replaceTranslations(toReplace: any) {
    for (const key of Object.keys(toReplace)) {
      const val = toReplace[key];
      if (ASTERISK === key) {
        const translated = this._translateService.instant(val);
        toReplace[key] = translated;
      } else if (toReplace.constructor instanceof Object) {
        // Recurse.
        toReplace[key] = this._replaceTranslations(val);
      }
    }
    return toReplace;
  }


  public async init(config: SupportServiceConfiguration, translateService: TranslateService, loggingService: LoggingService) {
    this._logger = new Logger(LOG_NAMESPACE, loggingService);
    this._translateService = translateService;
    this._logger.info("Initializing Zendesk Support Widget...");

    const zdConfig: ZendeskSupportWidgetConfiguration = json2ts(config.config, ZendeskSupportWidgetConfiguration);
    zdConfig.widgetConfig = this._replaceTranslations(zdConfig.widgetConfig);
    // tslint:disable-next-line: max-classes-per-file
    class ZendeskConfig extends NgxZendeskWebwidgetConfig {
      override lazyLoad = false;
      accountUrl = zdConfig.accountURL;
      override injectionTag = Strings.BODY.toLowerCase();
      callback(zE: any): void {
        zE(_TARGET_WEB_WIDGET, _METHOD_UPDATE_SETTINGS, zdConfig.widgetConfig);
        this._cb();
      }
      constructor(private _cb: () => void) {
        super();
      }
    }
    this._zendeskConfig  = new ZendeskConfig(this._completeInitialization.bind(this));

  }

}
