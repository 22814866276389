import { Level } from './level.model';
import { LOGGER_LEVEL } from './level.token';
import { LoggerService } from './logger.service';
import * as i0 from "@angular/core";
import * as i1 from "./level.model";
export class ConsoleLoggerService extends LoggerService {
    constructor(level) {
        super();
        if (level) {
            Object.keys(Level)
                .filter(s => isNaN(+s) && level >= Level[s])
                .forEach(levelName => {
                const methodName = levelName.toLowerCase();
                this[methodName] = console[methodName].bind(console);
            });
        }
    }
}
ConsoleLoggerService.ɵfac = function ConsoleLoggerService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || ConsoleLoggerService)(i0.ɵɵinject(LOGGER_LEVEL, 8)); };
ConsoleLoggerService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ConsoleLoggerService, factory: ConsoleLoggerService.ɵfac });
