import { __decorate, __metadata } from "tslib";
import { AssertionError } from "../../util/assertion-error";
import { JsonConverter, JsonObject } from "json2typescript";
let Preferences = class Preferences {
    constructor(data = {}) {
        try {
            this._data = JSON.parse(data);
        }
        catch (e) {
            this._data = data;
        }
    }
    serialize() {
        return this._data || {};
    }
    preference(key, throwOnMissing = true, defaultValueIfMissing = null) {
        if (null != this._data && key in this._data) {
            return this._data[key];
        }
        else {
            if (throwOnMissing) {
                throw new AssertionError({
                    message: `Key '${key}' was not present in the Preferences.`,
                    expected: "A value",
                    actual: "No value"
                });
            }
            else {
                return defaultValueIfMissing;
            }
        }
    }
};
Preferences = __decorate([
    JsonObject("Preferences"),
    __metadata("design:paramtypes", [Object])
], Preferences);
export { Preferences };
// tslint:disable-next-line: max-classes-per-file
let PreferencesConverter = class PreferencesConverter {
    serialize(data) {
        return data ? data.serialize() : null;
    }
    deserialize(val) {
        if (null == val) {
            return null;
        }
        return new Preferences(val);
    }
};
PreferencesConverter = __decorate([
    JsonConverter
], PreferencesConverter);
export { PreferencesConverter };
