var ContextualQuery_1;
import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from 'json2typescript';
import { Selectors } from './selectors';
let ContextualQuery = ContextualQuery_1 = class ContextualQuery {
    constructor() {
        this.query = ContextualQuery_1.EMPTY_QUERY;
        this.returnType = undefined;
        this.priority = null;
        this.pathToData = undefined;
        this.servicedPathToData = undefined;
        this.pathToAdditionalData = undefined;
        this.selectors = null;
    }
};
ContextualQuery.EMPTY_QUERY = null;
__decorate([
    JsonProperty("query", String, true),
    __metadata("design:type", String)
], ContextualQuery.prototype, "query", void 0);
__decorate([
    JsonProperty("return-type", String, true),
    __metadata("design:type", String)
], ContextualQuery.prototype, "returnType", void 0);
__decorate([
    JsonProperty("priority", Number, true),
    __metadata("design:type", Number)
], ContextualQuery.prototype, "priority", void 0);
__decorate([
    JsonProperty("path-to-data", String, true),
    __metadata("design:type", String)
], ContextualQuery.prototype, "pathToData", void 0);
__decorate([
    JsonProperty("serviced-path-to-data", String, true),
    __metadata("design:type", String)
], ContextualQuery.prototype, "servicedPathToData", void 0);
__decorate([
    JsonProperty("path-to-additional-data", String, true),
    __metadata("design:type", String)
], ContextualQuery.prototype, "pathToAdditionalData", void 0);
__decorate([
    JsonProperty("selectors", Selectors, true),
    __metadata("design:type", Selectors)
], ContextualQuery.prototype, "selectors", void 0);
ContextualQuery = ContextualQuery_1 = __decorate([
    JsonObject("ContextualQuery")
], ContextualQuery);
export { ContextualQuery };
