import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/forms";
export class ErrorComponent {
    constructor(router) {
        this.router = router;
    }
    searchResult() {
        this.router.navigate(['/app', 'dashboard']);
    }
}
ErrorComponent.ɵfac = function ErrorComponent_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || ErrorComponent)(i0.ɵɵdirectiveInject(i1.Router)); };
ErrorComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ErrorComponent, selectors: [["ddc-error"]], standalone: false, decls: 19, vars: 0, consts: [[1, "container"], ["id", "content", "role", "main", 1, "error-container"], [1, "row"], [1, "col-xl-4", "col-md-6", "col-xs-10", "offset-xl-4", "offset-md-3", "offset-xs-1"], [1, "error-container"], [1, "error-code"], [1, "error-info"], [1, "error-help", "mb"], ["method", "get", 3, "ngSubmit"], [1, "form-group"], ["type", "text", "placeholder", "Search Pages", 1, "form-control", "input-no-border"], ["type", "submit", 1, "btn", "btn-inverse"], [1, "fa", "fa-search", "text-warning", "ml-xs"], [1, "page-footer"]], template: function ErrorComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0)(1, "main", 1)(2, "div", 2)(3, "div", 3)(4, "div", 4)(5, "h1", 5);
        i0.ɵɵtext(6, "404");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "p", 6);
        i0.ɵɵtext(8, " Opps, it seems that this page does not exist. ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "p", 7);
        i0.ɵɵtext(10, " If you are sure it should, search for it. ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(11, "form", 8);
        i0.ɵɵlistener("ngSubmit", function ErrorComponent_Template_form_ngSubmit_11_listener() { return ctx.searchResult(); });
        i0.ɵɵelementStart(12, "div", 9);
        i0.ɵɵelement(13, "input", 10);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(14, "button", 11);
        i0.ɵɵtext(15, " Search ");
        i0.ɵɵelement(16, "i", 12);
        i0.ɵɵelementEnd()()()()()();
        i0.ɵɵelementStart(17, "footer", 13);
        i0.ɵɵtext(18, " 2016 \u00A9 Sing. Admin Dashboard Template. ");
        i0.ɵɵelementEnd()();
    } }, dependencies: [i2.ɵNgNoValidate, i2.NgControlStatusGroup, i2.NgForm], styles: ["\n\n\n\n\n\n.error-page[_ngcontent-%COMP%] {\n  background-color: #ddd;\n}\n\n.error-container[_ngcontent-%COMP%] {\n  padding-top: 5%;\n  text-align: center;\n}\n.error-container[_ngcontent-%COMP%]    > .btn[_ngcontent-%COMP%] {\n  padding-left: 35px;\n  padding-right: 35px;\n}\n\n.error-code[_ngcontent-%COMP%] {\n  margin: 20px;\n  font-size: 80px;\n  font-weight: 400;\n  color: #343434;\n}\n@media (min-width: 768px) {\n  .error-code[_ngcontent-%COMP%] {\n    font-size: 180px;\n  }\n}\n\n.error-info[_ngcontent-%COMP%] {\n  font-size: 20px;\n  color: #343434;\n}\n\n.error-help[_ngcontent-%COMP%] {\n  font-size: 14px;\n}\n\n.error-page[_ngcontent-%COMP%]   .page-footer[_ngcontent-%COMP%] {\n  position: absolute;\n  bottom: 30px;\n  left: 0;\n  right: 0;\n  width: 100%;\n  font-size: 13px;\n  color: var(--bs-secondary-color);\n  text-align: center;\n}"] });
