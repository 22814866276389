import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { switchMap, withLatestFrom } from "rxjs/operators";
import { PeriodOnPeriodType, TemporalAggregation } from "../model/business-dates/temporal-aggregation.enum";
import { TemporalService } from "../services/temporal/temporal.service";
import { ContextConfiguration } from "./config/context.config";
import { BUSINESS_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED, BUSINESS_DATE_RANGE_CHANGED, BusinessDateRangeAndTemporalAggregationChangeComplete, BusinessDateRangeChangeComplete, CALENDAR_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED, CalendarDateRangeAndTemporalAggregationChangeComplete, CurrentBusinessDateChangeComplete } from "./temporal.actions";
import { AUTHENTICATION_COMPLETED } from "./user.actions";
import { _isActivityThresholdReached } from "./utils";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../services/temporal/temporal.service";
import * as i3 from "@ngrx/store";
const _DEFAULT_TEMPORAL_AGGREGATION = TemporalAggregation.Week;
const _DEFAULT_BUSINESS_DATE_RANGE = { Last: { weeks: 0 }, Next: { weeks: 0 } };
const _DEFAULT_TEMPORAL_L4L = PeriodOnPeriodType.DEFAULT;
export class TemporalEffects {
    constructor(_action$, _temporalService, _store$) {
        this._action$ = _action$;
        this._temporalService = _temporalService;
        this._store$ = _store$;
        // @Effect()
        this.businessDateRangeAndTemporalAggregationChanged$ = createEffect(() => this._action$.pipe(ofType(BUSINESS_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED), withLatestFrom(this._store$), switchMap(async ([action, context]) => {
            console.log("Business Date Range AND Temporal Aggregation changed.", action, context);
            const config = ContextConfiguration.LOADED;
            const businessDateRange = action.businessDateRange
                || context.temporal.range;
            let baselineBusinessDate = action.baselineBusinessDate || context.temporal.current;
            const temporalOffset = action.temporalOffset;
            if (null != temporalOffset) {
                baselineBusinessDate = await this._temporalService.businessDateOffset(baselineBusinessDate, temporalOffset.offset, temporalOffset.temporalAggregation, temporalOffset.direction);
            }
            const temporalAggregation = action.temporalAggregation || context.temporal.aggregation;
            const dateType = action.dateType || context.temporal.dateType;
            const convertToAbsolute = action.convertToAbsolute || false;
            const newRange = await this._temporalService.HydratedBusinessDateRanges(businessDateRange, config.temporalAggregations, baselineBusinessDate, convertToAbsolute);
            return new BusinessDateRangeAndTemporalAggregationChangeComplete(newRange, temporalAggregation, dateType);
        })));
        // @Effect()
        this.calendarDateRangeAndTemporalAggregationChanged$ = createEffect(() => this._action$
            .pipe(ofType(CALENDAR_DATE_RANGE_AND_TEMPORAL_AGGREGATION_CHANGED), withLatestFrom(this._store$), switchMap(async ([action, context]) => {
            const config = ContextConfiguration.LOADED;
            const timestamp = action.timestamp;
            const temporalAggregation = action.temporalAggregation || context.temporal.aggregation;
            const dateType = action.dateType || context.temporal.dateType;
            const newRange = await this._temporalService.HydratedCalendarTimestamp(timestamp, config.temporalAggregations);
            return new CalendarDateRangeAndTemporalAggregationChangeComplete(newRange, temporalAggregation, dateType);
        })));
        // @Effect()
        this.init$ = createEffect(() => this._action$
            .pipe(ofType(AUTHENTICATION_COMPLETED), withLatestFrom(this._store$), switchMap(async ([, context]) => {
            let rangeAndAggregation;
            const config = ContextConfiguration.LOADED;
            const newUserContext = context && context.user ? context.user : null;
            if (null != newUserContext && true === _isActivityThresholdReached(context.user)) {
                const userProfile = newUserContext.profile;
                if (userProfile != null) {
                    rangeAndAggregation = { ...userProfile.DefaultContext.temporal };
                }
                else {
                    rangeAndAggregation = await this._getRangeAndAggregation(context);
                }
            }
            else {
                rangeAndAggregation = await this._getRangeAndAggregation(context);
            }
            const temporalContext = await this._temporalService.HydrateInitialBaselineDatesAndRanges(rangeAndAggregation.range, rangeAndAggregation.aggregation, config.temporalAggregations, rangeAndAggregation.L4L);
            return new CurrentBusinessDateChangeComplete(temporalContext);
        })));
        // @Effect()
        this.businessDateRangeChanged$ = createEffect(() => this._action$
            .pipe(ofType(BUSINESS_DATE_RANGE_CHANGED), withLatestFrom(this._store$), switchMap(async ([action, context]) => {
            const config = ContextConfiguration.LOADED;
            const newRange = action.payload;
            const newRanges = await this._temporalService.HydratedBusinessDateRanges(newRange, config.temporalAggregations, context.temporal.current);
            return new BusinessDateRangeChangeComplete(newRanges);
        })));
    }
    async _getRangeAndAggregation(context) {
        const temporal = context.temporal;
        const ret = {
            range: temporal.range || context.user.profile.DefaultContext.temporal.range,
            aggregation: temporal.aggregation || context.user.profile.DefaultContext.temporal.aggregation,
            L4L: temporal.L4L || context.user.profile.DefaultContext.temporal.L4L
        };
        ret.range = ret.range || _DEFAULT_BUSINESS_DATE_RANGE;
        ret.aggregation = ret.aggregation || _DEFAULT_TEMPORAL_AGGREGATION;
        ret.L4L = ret.L4L || _DEFAULT_TEMPORAL_L4L;
        // console.log("Getting the range after login.", ret);
        return ret;
    }
}
TemporalEffects.ɵfac = function TemporalEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TemporalEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.TemporalService), i0.ɵɵinject(i3.Store)); };
TemporalEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TemporalEffects, factory: TemporalEffects.ɵfac });
