import { InjectionToken, ModuleWithProviders } from "@angular/core";
import { LoggingService } from "./logging.service";
import * as i0 from "@angular/core";
// import { setupLoggerDecorator } from "@ngx-toolkit/logger/esm2015/lib/logger.module";
export const LOGGING_LEVEL = new InjectionToken("LOGGING_LEVEL");
export class LoggingModule {
    static forRoot(level = null) {
        return {
            ngModule: LoggingModule,
            providers: [
                {
                    provide: LOGGING_LEVEL,
                    useValue: level
                },
                {
                    provide: LoggingService,
                    useClass: LoggingService
                },
                // {
                //   provide: APP_INITIALIZER,
                //   useFactory: setupLoggerDecorator,
                //   deps: [LoggingService],
                //   multi: true
                // }
            ]
        };
    }
}
LoggingModule.ɵfac = function LoggingModule_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || LoggingModule)(); };
LoggingModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: LoggingModule });
LoggingModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({});
