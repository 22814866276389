import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from "json2typescript";
import * as GQL from "../../util/graphql-tags";
let SnapshotSuperType = class SnapshotSuperType {
    constructor() {
        this.guid = undefined;
        this.code = undefined;
        this._nameRef = undefined;
    }
    name(translateService, nameType) {
        return translateService.instant(this._nameRef + nameType);
    }
};
__decorate([
    JsonProperty(GQL.SCALAR_GUID, String, false),
    __metadata("design:type", String)
], SnapshotSuperType.prototype, "guid", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_CODE, String, false),
    __metadata("design:type", String)
], SnapshotSuperType.prototype, "code", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_NAME_REF, String, false),
    __metadata("design:type", String)
], SnapshotSuperType.prototype, "_nameRef", void 0);
SnapshotSuperType = __decorate([
    JsonObject("SnapshotSuperType")
], SnapshotSuperType);
export { SnapshotSuperType };
