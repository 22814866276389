import { __decorate, __metadata } from "tslib";
import { Timestamp } from '../timestamp.model';
import { JsonObject, JsonProperty } from 'json2typescript';
import * as GQL from "../../util/graphql-tags";
let CalendarDateRange = class CalendarDateRange {
    constructor() {
        this.From = undefined;
        this.To = undefined;
    }
};
__decorate([
    JsonProperty(GQL.FROM, Timestamp, true),
    __metadata("design:type", Timestamp)
], CalendarDateRange.prototype, "From", void 0);
__decorate([
    JsonProperty(GQL.TO, Timestamp, true),
    __metadata("design:type", Timestamp)
], CalendarDateRange.prototype, "To", void 0);
CalendarDateRange = __decorate([
    JsonObject("CalendarDateRange")
], CalendarDateRange);
export { CalendarDateRange };
