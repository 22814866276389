// tslint:disable:array-type
import {
  TranslateService
} from "@ngx-translate/core";
import * as d3 from "d3";

import {
  BusinessDate
} from "../../model/business-dates/business-date.model";
import {
  CalendarDateElement
} from "../../model/calendar-date-element.enum";
import {
  Timestamp
} from "../../model/timestamp.model";
import * as Config from "../../util/config.constants";
import {
  EMPTY, STRING
} from "../../util/string-constants";
import { Logger } from "../logging/logger";
import { BaseFormatter } from "./base-formatter";
import {
  FormatterService
} from "./formatter.service";

const _WEEK: string = "week";
const _DAY_OF_WEEK: string = "day-of-week";
const _MONTH: string = "month";
const _YEAR: string = "year";
const _DAY_MONTH: string = "day-month";
const _DEFAULT: string = "default";
const _WEEK_OF_YEAR_FORMAT = "%W";

const _DEFAULT_STARTING_FISCAL_MONTH: number = 4;

type FormatFunction = ((d: Date) => number|boolean);

export class BusinessDateFormatter extends BaseFormatter {

  private _formatFunction: d3.time.Format;
  private _notADateReturnValue: string = EMPTY;
  private _weekOfYearFunction: d3.time.Format = d3.time.format(_WEEK_OF_YEAR_FORMAT);
  private _startingFiscalMonth: number;
  private _defaultCalendarDateElementParams: any;
  private _businessDate: BusinessDate;

  constructor(locale: d3.Locale,
              config: any,
              translateService: TranslateService,
              formatterService: FormatterService,
              logger: Logger
            ) {
    super(locale, config, translateService, formatterService, logger);
    this._formatFunction = locale.timeFormat.multi(this._multiFormatConfig(config.formats));
    this._notADateReturnValue = this.translatePlaceholders((config[Config.NOT_A_DATE_DEFAULT_VALUE] || EMPTY));
    this._defaultCalendarDateElementParams = config[Config.USE_CALENDAR_DATE_ELEMENT];
    this._startingFiscalMonth = config[Config.STARTING_FISCAL_MONTH] || _DEFAULT_STARTING_FISCAL_MONTH;
  }

  public override formatData(data: any, params?: any): string {
    if (!(data instanceof BusinessDate)) {
      data = BusinessDate.ToValidContextObject(data);
    }
    if (data.year < 0 || data.month < 0 || data.day < 0) {
      return this._notADateReturnValue;
    }

    let d: Date;
    params = params || this._defaultCalendarDateElementParams;
    this._businessDate = data;
    const cDate = this._calendarDateTimestamp(data, params);
    if (null == cDate) {
      d = this._dateFromBusinessDate(data);
    } else {
      d = cDate.asDate;
    }
    if (null != params && null != params.incrementDays) {
      d.setDate(d.getDate() + params.incrementDays);
    }
    const ret =  this.translatePlaceholders(this._formatFunction(d));
    return ret;
  }

  private _calendarDateTimestamp(date: BusinessDate, params: any): Timestamp {
    if (null == date || null == params) {
      return null;
    }

    let ret: Timestamp;
    const type = typeof params === STRING ? params : params[Config.USE_CALENDAR_DATE_ELEMENT];

    switch (type) {
      case CalendarDateElement.From:
        ret = null == date.CalendarDateRange || null == date.CalendarDateRange.From ? null : date.CalendarDateRange.From;
        break;
      case CalendarDateElement.To:
        ret = null == date.CalendarDateRange || null == date.CalendarDateRange.To ? null : date.CalendarDateRange.To;
        break;
      default:
        ret = null;
    }
    return ret;
  }

  private _multiFormatConfig(formatConfig: object): [string, FormatFunction][] {
    this._logger.debug("BusinessDateFormatter loaded with config", {config: formatConfig});
    if (typeof formatConfig[_DEFAULT] !== STRING) {
      throw new Error("Default format missing from formats!");
    }
    const ret: [string, FormatFunction][] = [];
    this._addFormatFunctionPair(formatConfig, ret, _DAY_MONTH, this._defaultFunction);
    this._addFormatFunctionPair(formatConfig, ret, _WEEK, this._weekFunction);
    this._addFormatFunctionPair(formatConfig, ret, _MONTH, this._monthFunction);
    this._addFormatFunctionPair(formatConfig, ret, _YEAR, this._yearFunction);
    this._addFormatFunctionPair(formatConfig, ret, _DAY_OF_WEEK, this._dayOfWeekFunction);
    this._addFormatFunctionPair(formatConfig, ret, _DEFAULT, this._defaultFunction);

    return ret;
  }

  private _addFormatFunctionPair(formatConfig: object, list: [string, FormatFunction][], entry: string, func: FormatFunction): void {
    if (formatConfig[entry]) {
      list.push([formatConfig[entry], func.bind(this)]);
    }
  }

  private _weekFunction(date: Date): boolean {
    return date.getDate() !== 1 && this._weekFromDate(date) !== 1;
  }

  private _monthFunction(date: Date): boolean {
    return this._weekFromDate(date) !== 1;
  }

  private _yearFunction(date: Date): boolean {
    return this._weekFromDate(date) === 1;
  }

  private _dayOfWeekFunction(date: Date): boolean {
    return this._dayOfWeekFromDate(date);
  }

  private _defaultFunction(date: Date): boolean {
    return true;
  }

  private _weekFromDate(date: Date): number {
    return this._businessDate.week;
  }

  private _dayOfWeekFromDate(date: Date): boolean {
    return this._businessDate.type === "Day";
  }

  private _dateFromBusinessDate(businessDate: BusinessDate): Date {
    if (!businessDate) {
      return null;
    }

    const week: number = businessDate.week;
    const year: number = businessDate.year;
    const month: number = this._startingFiscalMonth;
    const ret: Date = new Date(year, 0, month, 0, 0, 0, 0);

    while (ret.getDay() !== 1) {
        ret.setDate(ret.getDate() - 1);
    }

    if (1 <= week && week <= 52) {
        ret.setDate(ret.getDate() + 7 * (week));
        return ret;
    }

    ret.setDate(ret.getDate() + 7 * (week - 1));
    if (week === 53 && ret.getDate() >= 22 && ret.getDate() <= 28) {
      return ret;
    }

    return null;
  }
}
