import { TranslateService } from "@ngx-translate/core";

import {
  Series
} from "../../dashboard/components/config/series";
import {
  BrandService
} from "../brand/brand.service";
import {
  FormatterService
} from "../formatter/formatter.service";
import {
  IFormatter
} from "../formatter/i-formatter.interface";
import {
  Logger
} from "../logging/logger";
import { TooltipBase } from "./base-tooltip";
import { BusinessDate } from '../../model/business-dates/business-date.model';

import * as GQL from "../../util/graphql-tags";
import { AREA, COLOR, DATA, TYPE, BAR } from '../../util/string-constants';

const DEFAULT_FORMATTER = "";

export class MultiChartTooltip extends TooltipBase {

  private _seriesFormatters: any = {};
  private _headerFormatter: IFormatter;

  public constructor(
    _config: {[type: string]: any},
    _formatterService: FormatterService,
    _brandService: BrandService,
    _translateService: TranslateService,
    _logService: Logger
  ) {
    super(_config, _formatterService, _brandService, _translateService, _logService);
    try {
      this._headerFormatter = this._formatterService.formatter(this._config["formatters"].header);
    } catch (e) {
      throw new TypeError("No header formatter configured in formatters.header");
    }

    const seriesConfig = this._config["formatters"].series;
    for (const key of Object.keys(seriesConfig)) {
      try {
        this._seriesFormatters[key] = this._formatterService.formatter(seriesConfig[key]);
      } catch (e) {
        throw new TypeError("Code '" + seriesConfig[key] + ' does not map to a valid formatter!');
      }
    }
    if (this._seriesFormatters[DEFAULT_FORMATTER] === undefined) {
      throw new TypeError('Tooltip configuration must contain default (empty key) entry in formatters.series!');
    }
  }

  /*
  * Have to modify nvd3 code to tell us if the series isArea, on hover, so that
  * we can change the shape of bullet based on line type.
  */

  public override generateTooltipContent(data: any, params?: any): string {
    this._logService.debug("Generating LineChart Tooltip of data", {tooltipData: data, parameter: params});
    const businessDate: BusinessDate = data['series'][0][GQL.SCALAR_DATA][GQL.BUSINESS_DATE];

    const header = this._headerFormatter.formatData(businessDate);
    let popupHTML: string = '<table width="95%" style="font-variant: small-caps;">';
    if (this._config["showHeader"]) {
      popupHTML += '<thead><tr> <td colspan="3"> ' + header + '</td></tr></thead>';
    }

    const seriesDefinitions: Series[] = data.seriesDefinitions;
    data.series.forEach((ele: any) => {
      const val = ele[GQL.SCALAR_VALUE];
      const seriesDefinition: Series = seriesDefinitions.find((seriesDef: Series) => {
        // If legends aren't enabled then the right axis hint isn't appended to the series
        // name for some obscure reason.
        if (data.legendEnabled === true) {
          return seriesDef.translatedNameWithAxisHint === ele.key;
        } else {
          return seriesDef.translatedName === ele.key;
        }
      });
      const seriesType = ele[DATA][TYPE];
      const seriesColour =  seriesType === AREA ? ele[COLOR] : ele[DATA][COLOR] ? ele[DATA][COLOR] : undefined;
      const lineColour =  seriesType === AREA || seriesType === BAR ? undefined : ele[COLOR];
      popupHTML += '<tr>';
      let legendClass: string = "";
      if (seriesDefinition.tooltipCSSClass != null) {
        legendClass = seriesDefinition.tooltipCSSClass;
      } else if (this._config["showColorCode"]) {
        if (seriesDefinition && seriesDefinition.benchmark) {
          legendClass = "legend-color-guide-dotted-line";
        } else {
          legendClass = seriesType === AREA || seriesType === BAR ? "legend-color-guide-area" : "legend-color-guide-line";
        }
      }

      popupHTML += '<td align="right" class="legend-icon ' + legendClass + '"><div';
      if (seriesColour !== undefined) {
        popupHTML += ' style="background-color:' + seriesColour + '"';
      } else if (lineColour !== undefined) {
        popupHTML += ' style="color:' + lineColour + '"';
      }
      popupHTML += '></div></td>';

      if (this._config["showLabel"]) {
        popupHTML += '<td class="key" style="text-align: left;">' + seriesDefinition.translatedNameWithAxisHint + '</td>';
      }

      popupHTML +=  '<td class="value">';
      if (val) {
        const formatter = this._seriesFormatter(seriesDefinition);
        const value = formatter.formatData(val);
        popupHTML += value;
      }
      popupHTML += '</td></tr>';
    });

    popupHTML += '</tbody></table>';
    return popupHTML;
  }

  private _seriesFormatter(seriesDefinition: any): IFormatter {
    const seriesID = seriesDefinition ? seriesDefinition.id.toLowerCase() : DEFAULT_FORMATTER;
    let ret = this._seriesFormatters[seriesID];

    if (undefined === ret) {
      ret = this._seriesFormatters[DEFAULT_FORMATTER];
    }
    return ret;
  }

}
