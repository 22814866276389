import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap } from "rxjs/operators";
import { MutationType } from "../dashboard/components/config/mutation-type.enum";
import { Person } from "../model/person.model";
import { DataService } from "../services/data/data.service";
import { PERSON } from "../util/graphql-tags";
import { ADDED, DELETED, UPDATED } from "../util/string-constants";
import { MutationFailed, MutationSuccessful } from "./mutation.actions";
import { PERSON_ADDITIONAL_DATA_CHANGED, PERSON_RELATIONSHIP_ADDED, PERSON_RELATIONSHIP_DELETED } from "./reference.actions";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../services/data/data.service";
const _LOG_ID = "task.effects";
const _PERSON_RELATIONSHIP = "PERSON-RELATIONSHIP";
// tslint:disable-next-line:max-line-length
const _ADD_PERSON_RELATIONSHIP_GQL = `mutation addPersonRelationship (
	$Person: PersonInput!
	$RelatedPerson: PersonInput!
	$relationshipType: String!
) {
	AddPersonRelationship (Person: $Person, RelatedTo: $RelatedPerson, relationshipType: $relationshipType)
}
`;
const _DELETE_PERSON_RELATIONSHIP_GQL = `mutation deletePersonRelationship (
	$Person: PersonInput!
	$RelatedPerson: PersonInput!
	$relationshipType: String!
) {
	DeletePersonRelationship (Person: $Person, RelatedTo: $RelatedPerson, relationshipType: $relationshipType)
}
`;
const _UPDATE_PERSON_ADDITIONAL_DATA_GQL = `
mutation updatePersonAdditionalData(
	$personID: String!,
	$additionalData: String!
) {
	UpdatePerson(id: $personID, additionalData: $additionalData) {
		id
		guid
		displayName
		avatarURL
	}
}
`;
export class PersonEffects {
    constructor(_action$, _dataService) {
        this._action$ = _action$;
        this._dataService = _dataService;
        // @Effect()
        this.personAdditionalDataChanged$ = createEffect(() => this._action$
            .pipe(ofType(PERSON_ADDITIONAL_DATA_CHANGED), switchMap(async (action) => {
            return this.updatePersonAdditionalData(action);
        })));
        // @Effect()
        this.personRelationshipAdded$ = createEffect(() => this._action$
            .pipe(ofType(PERSON_RELATIONSHIP_ADDED), switchMap(async (action) => {
            return this.personRelationshipAdded(action);
        })));
        // @Effect()
        this.personRelationshipDeleted$ = createEffect(() => this._action$
            .pipe(ofType(PERSON_RELATIONSHIP_DELETED), switchMap(async (action) => {
            return this.personRelationshipDeleted(action);
        })));
    }
    async personRelationshipAdded(action) {
        const person = Person.ToValidInputObject(action.person);
        const relatedPerson = Person.ToValidInputObject(action.relatedPerson);
        const relationshipType = action.relationshipType;
        console.log("NEED TO ADD PERSON RELATIONSHIPS AS FOLLOWS", action);
        const params = {
            Person: person,
            RelatedPerson: relatedPerson,
            relationshipType: relationshipType
        };
        try {
            await this._dataService.mutate(_LOG_ID, _ADD_PERSON_RELATIONSHIP_GQL, params);
            console.log("PERSON RELATIONSHIP ADDED");
            return new MutationSuccessful([MutationType.PersonRelationship], ADDED.toUpperCase(), _PERSON_RELATIONSHIP);
        }
        catch (err) {
            console.log(`PERSON RELATIONSHIP __NOT__ ADDED!:

      ${err}`);
            return new MutationFailed(err, [MutationType.PersonRelationship], ADDED.toUpperCase(), _PERSON_RELATIONSHIP);
        }
    }
    async personRelationshipDeleted(action) {
        const person = Person.ToValidInputObject(action.person);
        const relatedPerson = Person.ToValidInputObject(action.relatedPerson);
        const relationshipType = action.relationshipType;
        console.log("NEED TO DELETE PERSON RELATIONSHIPS AS FOLLOWS", action);
        const params = {
            Person: person,
            RelatedPerson: relatedPerson,
            relationshipType: relationshipType
        };
        try {
            await this._dataService.mutate(_LOG_ID, _DELETE_PERSON_RELATIONSHIP_GQL, params);
            console.log("PERSON RELATIONSHIP DELETED");
            return new MutationSuccessful([MutationType.PersonRelationship], DELETED.toUpperCase(), _PERSON_RELATIONSHIP);
        }
        catch (err) {
            console.log(`PERSON RELATIONSHIP __NOT__ DELETED!:

      ${err}`);
            return new MutationFailed(err, [MutationType.PersonRelationship], DELETED.toUpperCase(), _PERSON_RELATIONSHIP);
        }
    }
    async updatePersonAdditionalData(action) {
        const person = action.person;
        const personID = person.id;
        const additionalData = JSON.stringify(person.parsedAdditionalData);
        console.log("NEED TO UPDATE ADDITIONAL DATA FOR THIS PERSON", person);
        const params = {
            personID: personID,
            additionalData: additionalData
        };
        try {
            await this._dataService.mutate(_LOG_ID, _UPDATE_PERSON_ADDITIONAL_DATA_GQL, params);
            console.log("PERSON ADDITIONAL DATA SAVED");
            return new MutationSuccessful([MutationType.Person], UPDATED.toUpperCase(), PERSON.toUpperCase());
        }
        catch (err) {
            console.log(`PERSON ADDITIONAL DATA __NOT__ SAVED!:

      ${err}`);
            return new MutationFailed(err, [MutationType.Person], UPDATED.toUpperCase(), PERSON.toUpperCase());
        }
    }
}
PersonEffects.ɵfac = function PersonEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || PersonEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.DataService)); };
PersonEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PersonEffects, factory: PersonEffects.ɵfac });
