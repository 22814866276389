import { TranslateService } from "@ngx-translate/core";
import { formattedTimeString } from "../../util/utils";
import { BrandService } from "../brand/brand.service";
import { FormatterService } from "../formatter/formatter.service";
import { Logger } from "../logging/logger";
import { LoggingService } from "../logging/logging.service";
import { DimensionHorizontalBarChartTooltip } from "./dimensionHorizontalBarChartTooltip";
import { DiscreteBarTooltip } from "./discrete-bar-tooltip";
import { HistogramTooltip } from "./histogramTooltip";
import { HorizontalBarChartTooltip } from "./horizontalBarChartTooltip";
import { KioskTooltip } from "./kiosk-tooltip";
import { SalesVSCsatTooltip } from "./line-chart-sales-vs-csat-tooltip";
import { MapTooltip } from "./mapTooltip";
import { MultiBarTooltip } from "./multiBarTooltip";
import { MultiBarTooltipWithComponentData } from "./multiBarTooltipWithComponentData";
import { MultiChartTooltip } from "./multiChartTooltip";
import { MultiDimensionHorizontalBarTooltip } from "./multiDimensionHorizontalBarTooltip";
import { MultiDimensionHorizontalBarVerticalLayoutTooltip } from "./multiDimensionHorizontalBarVerticalLayoutTooltip";
import { MultiDimensionVerticalBarTooltip } from "./multiDimensionVerticalBarTooltip";
import { StackedAreaTooltip } from "./stacked-area-tooltip";
import { WeatherHorizontalBarChartTooltip } from "./weatherHorizontalBarChartTooltip";
import * as i0 from "@angular/core";
import * as i1 from "../formatter/formatter.service";
import * as i2 from "../brand/brand.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../logging/logging.service";
export class TooltipService {
    constructor(_formatterService, _brandService, _translateService, _loggingService) {
        this._formatterService = _formatterService;
        this._brandService = _brandService;
        this._translateService = _translateService;
        this._loggingService = _loggingService;
        // Lookup table for tooltip instances.
        this.tooltips = {};
        this._tooltipSelectorsClassMap = {
            multiChartTooltip: MultiChartTooltip,
            mapTooltip1: MapTooltip,
            horizontalBarChartTooltip: HorizontalBarChartTooltip,
            dimensionHorizontalBarChartTooltip: DimensionHorizontalBarChartTooltip,
            lcSalesvsCsatTooltip: SalesVSCsatTooltip,
            histogramTooltip: HistogramTooltip,
            discreteBarTooltip: DiscreteBarTooltip,
            multiBarTooltip: MultiBarTooltip,
            multiBarTooltipWithComponentData: MultiBarTooltipWithComponentData,
            stackedAreaTooltip: StackedAreaTooltip,
            multiDimensionHorizontalBarTooltip: MultiDimensionHorizontalBarTooltip,
            multiDimensionHorizontalBarVerticalLayoutTooltip: MultiDimensionHorizontalBarVerticalLayoutTooltip,
            multiDimensionVerticalBarTooltip: MultiDimensionVerticalBarTooltip,
            kioskTooltip: KioskTooltip,
            weatherHorizontalBarChartTooltip: WeatherHorizontalBarChartTooltip
        };
        this._tooltipConfig = {};
        this._logger = new Logger("TooltipService", _loggingService);
    }
    init(tooltipConfig) {
        this._logger.info(formattedTimeString() + " Initializing Tooltip Service...");
        this._logger.debug("Instantiating TooltipService with config", { config: tooltipConfig });
        for (const fmt of tooltipConfig) {
            const fmtConfig = {
                id: fmt.id,
                type: fmt.type
            };
            if (fmt.config) {
                fmtConfig["config"] = JSON.parse(fmt["config"]);
            }
            this._tooltipConfig[fmt.id] = fmtConfig;
        }
        this._logger.info("Completed Setting Config to Tooltip Service" + formattedTimeString());
    }
    /*
    * This function will return the instance of the ITooltip.
    * @param string - tooltip type
    * @return - ITooltip instance
    */
    getTooltip(type) {
        return this._instantiateTooltip(type);
    }
    /*
    * This function will return the instance of ITooltip by tooltip type.
    * @param string - tooltip type
    * @param Array - Array of arguments
    * @return - ITooltip instance
    */
    _instantiateTooltip(type) {
        const tooltipConfig = this._tooltipConfig[type];
        if (tooltipConfig === undefined || this._tooltipSelectorsClassMap[tooltipConfig.type] === undefined) {
            throw new TypeError("Type '" + type + "' not found. ");
        }
        if (this.tooltips[type] !== undefined) {
            return this.tooltips[type];
        }
        this._logger.info("Mapped with the Tooltip class class of type '" + type + "'");
        const instance = new this._tooltipSelectorsClassMap[tooltipConfig.type](tooltipConfig.config, this._formatterService, this._brandService, this._translateService, new Logger(this._tooltipSelectorsClassMap[tooltipConfig.type].name, this._loggingService));
        this.tooltips[type] = instance;
        return instance;
    }
}
TooltipService.ɵfac = function TooltipService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TooltipService)(i0.ɵɵinject(i1.FormatterService), i0.ɵɵinject(i2.BrandService), i0.ɵɵinject(i3.TranslateService), i0.ɵɵinject(i4.LoggingService)); };
TooltipService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TooltipService, factory: TooltipService.ɵfac });
