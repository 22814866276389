import * as i0 from "@angular/core";
export class AppState {
    constructor() {
        this._state = {};
    }
    // already return a clone of the current state
    get state() {
        return this._state = this._clone(this._state);
    }
    // never allow mutation
    set state(value) {
        throw new Error('do not mutate the `.state` directly');
    }
    get(prop) {
        // use our state getter for the clone
        const state = this.state;
        return state.hasOwnProperty(prop) ? state[prop] : state;
    }
    set(prop, value) {
        // internally mutate our state
        return this._state[prop] = value;
    }
    _clone(object) {
        // simple object clone
        return JSON.parse(JSON.stringify(object));
    }
}
AppState.ɵfac = function AppState_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || AppState)(); };
AppState.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AppState, factory: AppState.ɵfac });
