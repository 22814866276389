import * as d3 from "d3";
import {
  ISelectorProperties
} from "./selector.properties.interface";
import { BaseSelector } from "./base-selector";

export class IndexBasedSelector extends BaseSelector {

  protected _colours: string[];

  private _d3Color20A: string[] = d3.scale.category20().range();
  private _d3Color20B: string[] = d3.scale.category20b().range();
  private _d3Color20C: string[] = d3.scale.category20c().range();

  public override seriesColour(properties: ISelectorProperties): string {
    if (!this.config || !this.config.colours) {
      this._colours = this._d3Color20A.concat(this._d3Color20B,
        this._d3Color20C);
    } else {
      this._colours = this.config.colours;
    }
    return this._colours[properties.index];
  }
}
