import {
  JsonObject,
  JsonProperty
} from "json2typescript";
import { TranslateService } from "@ngx-translate/core";

import * as GQL from "../../util/graphql-tags";
import { SnapshotSuperType } from './snapshot-super-type.model';

@JsonObject("SnapshotType")
export class SnapshotType {

  @JsonProperty(GQL.SUPER_TYPE, SnapshotSuperType, false)
  public Type: SnapshotSuperType = undefined;

  @JsonProperty(GQL.SCALAR_GUID, String, false)
  public guid: string = undefined;

  @JsonProperty(GQL.SCALAR_CODE, String, false)
  public code: string = undefined;

  @JsonProperty(GQL.SCALAR_NAME_REF, String, false)
  private _nameRef: string = undefined;

  @JsonProperty(GQL.SCALAR_NAME, String, false)
  private _name: string = undefined;

  public name(translateService: TranslateService) {
    if (undefined !== this._name) {
      return this._name;
    } else {
      return translateService.instant(this._nameRef);
    }
  }

}
