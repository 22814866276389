import { JsonObject, JsonProperty } from 'json2typescript';

import { Selectors } from './selectors';

@JsonObject("ContextualQuery")
export class ContextualQuery {

    public static EMPTY_QUERY: string = null;

    @JsonProperty("query", String, true)
    public query: string = ContextualQuery.EMPTY_QUERY;

    @JsonProperty("return-type", String, true)
    public returnType: string = undefined;

    @JsonProperty("priority", Number, true)
    public priority: number = null;

    @JsonProperty("path-to-data", String, true)
    public pathToData: string = undefined;

    @JsonProperty("serviced-path-to-data", String, true)
    public servicedPathToData: string = undefined;

    @JsonProperty("path-to-additional-data", String, true)
    public pathToAdditionalData: string = undefined;

    @JsonProperty("selectors", Selectors, true)
    public selectors: Selectors = null;
}
