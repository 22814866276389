import * as d3 from "d3";
import { BaseSelector } from "./base-selector";
export class IndexBasedSelector extends BaseSelector {
    constructor() {
        super(...arguments);
        this._d3Color20A = d3.scale.category20().range();
        this._d3Color20B = d3.scale.category20b().range();
        this._d3Color20C = d3.scale.category20c().range();
    }
    seriesColour(properties) {
        if (!this.config || !this.config.colours) {
            this._colours = this._d3Color20A.concat(this._d3Color20B, this._d3Color20C);
        }
        else {
            this._colours = this.config.colours;
        }
        return this._colours[properties.index];
    }
}
