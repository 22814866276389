import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from "json2typescript";
const GROUPS = "groups";
const ROLES = "roles";
const PERMISSIONS = "permissions";
let Authorization = class Authorization {
    constructor() {
        this.Groups = [];
        this.Roles = [];
        this.Permissions = [];
    }
};
__decorate([
    JsonProperty(GROUPS, [String], false),
    __metadata("design:type", Array)
], Authorization.prototype, "Groups", void 0);
__decorate([
    JsonProperty(ROLES, [String], false),
    __metadata("design:type", Array)
], Authorization.prototype, "Roles", void 0);
__decorate([
    JsonProperty(PERMISSIONS, [String], false),
    __metadata("design:type", Array)
], Authorization.prototype, "Permissions", void 0);
Authorization = __decorate([
    JsonObject("Authorization")
], Authorization);
export { Authorization };
