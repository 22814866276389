import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import Auth0Lock from "auth0-lock";
import { AppRoutes } from "../../approutes.enum";
import { UserReset, UserLoggedOut } from "../../context/user.actions";
import { CLASS, COLON, DOUBLE_SLASH, EMPTY, HEADER_BEARER, LS_PREFIX, SLASH, SPACE, STYLE } from "../../util/string-constants";
import { copyToClipboard, formattedTimeString } from "../../util/utils";
import { Logger } from "../logging/logger";
import { LoggingService } from "../logging/logging.service";
import { NotificationsService } from "../notifications/notifications.service";
import { getAuth, isAuthenticated, storeAuth } from "./auth-utils";
import { json2ts } from "../../util/json-2ts";
import { Authorization } from "../../model/user/authorization.model";
import * as i0 from "@angular/core";
import * as i1 from "../logging/logging.service";
import * as i2 from "@ngrx/store";
import * as i3 from "../notifications/notifications.service";
import * as i4 from "@ngx-translate/core";
const _DIDICI_NAMESPACE = "https://didici.io/";
export const AUTH_USER_NAME = _DIDICI_NAMESPACE + "name";
export const AUTH_PICTURE = _DIDICI_NAMESPACE + "picture";
export const AUTH_AUTHORIZATION = _DIDICI_NAMESPACE + "authorization";
const _LOG_NAMESPACE = "services.authentication";
const _TRANSLATE_UX_PLACEHOLDERS_PFX = "UX.PLACEHOLDERS.";
const _TRANSLATE_LOGOUT_OPTIONS_PFX = _TRANSLATE_UX_PLACEHOLDERS_PFX + "LOGOUT-OPTIONS.";
const _TRANSLATE_TITLE_KEY = _TRANSLATE_LOGOUT_OPTIONS_PFX + "TITLE";
const _TRANSLATE_CLEAR_CACHE_KEY = _TRANSLATE_LOGOUT_OPTIONS_PFX + "CLEAR-CACHE";
const _TRANSLATE_CLEAR_ALL_DATA_KEY = _TRANSLATE_LOGOUT_OPTIONS_PFX + "CLEAR-ALL-DATA";
const _AUTH0_LOCK_OVERLAY_CSS_SELECTOR = "auth0-lock-overlay";
const _AUTH0_LOCK_HEADER_BACKGROUND_CSS_SELECTOR = "auth0-lock-header-bg";
const _DDC_AUTH0_CSS_SELECTOR = "ddc-auth0-header";
const _BACKGROUND_IMAGE_STYLES = `
  height: 100%;
  min-height: 100%;
  background: url(/assets/img/join-the-dots.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;
export class AuthenticationService {
    constructor(loggingService, _store, _notificationsService, _translateService) {
        this._store = _store;
        this._notificationsService = _notificationsService;
        this._translateService = _translateService;
        this._logger = new Logger(_LOG_NAMESPACE, loggingService);
    }
    waitForAuthenticationResult(tokenExpired) {
        if (tokenExpired) {
            return new Promise((resolve, reject) => {
                this._lock.on("authenticated", (authResult) => {
                    this._logger.warn("Authentication succeeded.");
                    let node = document.getElementsByClassName(_AUTH0_LOCK_OVERLAY_CSS_SELECTOR)[0];
                    if (null == node) {
                        setTimeout(() => {
                            let node = document.getElementsByClassName(_AUTH0_LOCK_OVERLAY_CSS_SELECTOR)[0];
                            node.setAttribute(STYLE, EMPTY);
                        });
                    }
                    else {
                        node.setAttribute(STYLE, EMPTY);
                    }
                    this._lock.hide();
                    if (authResult && authResult["accessToken"]) {
                        storeAuth(authResult);
                        resolve({ isError: false, auth: authResult });
                    }
                });
                this._lock.on("authorization_error", (error) => {
                    resolve({ isError: true, error });
                });
                this._lock.on("show", () => {
                    // Collosal hack for now to do some basic styling on the login box.
                    // The selectors are Auth0 class names.
                    // The styles or classes it ADDS are defined in index.html
                    // Can't use ANY SCSS or rely on the login form templates because the
                    // Startup service is blocking waiting for us to login so none of that
                    // stuff is available yet!
                    let node = document.getElementsByClassName(_AUTH0_LOCK_OVERLAY_CSS_SELECTOR)[0];
                    if (null == node) {
                        setTimeout(() => {
                            let node = document.getElementsByClassName(_AUTH0_LOCK_OVERLAY_CSS_SELECTOR)[0];
                            node.setAttribute(STYLE, _BACKGROUND_IMAGE_STYLES);
                            node = document.getElementsByClassName(_AUTH0_LOCK_HEADER_BACKGROUND_CSS_SELECTOR)[0];
                            node.setAttribute(CLASS, node.getAttribute(CLASS) + SPACE + _DDC_AUTH0_CSS_SELECTOR);
                        });
                    }
                    else {
                        node.setAttribute(STYLE, _BACKGROUND_IMAGE_STYLES);
                        node = document.getElementsByClassName(_AUTH0_LOCK_HEADER_BACKGROUND_CSS_SELECTOR)[0];
                        node.setAttribute(CLASS, node.getAttribute(CLASS) + SPACE + _DDC_AUTH0_CSS_SELECTOR);
                    }
                });
            });
        }
        else {
            this._logger.debug("Returning current authentication credentials as they are still valid.", getAuth());
            return Promise.resolve({ isError: false, auth: getAuth() });
        }
    }
    authenticate() {
        if (this.isTokenExpired()) {
            // Can't use the Angular router here as it creates a circular dependency.
            if (location.pathname.search(SLASH + AppRoutes.Login) >= 0) {
                this._logger.warn("Current credentials are invalid.  Displaying login form.");
                this._lock.show();
            }
            else {
                this._logger.warn("Current credentials are invalid and we are not on the login page.  Redirecting...");
                location.pathname = SLASH + AppRoutes.Login;
            }
        }
        return this.waitForAuthenticationResult(this.isTokenExpired());
    }
    async logout(event = null, token = null) {
        if (null == event) {
            return this._performLogout();
        }
        else if (event instanceof MouseEvent) {
            return this._handleLogoutClicked(event, token);
        }
        else {
            return this._handleLogoutTapped(event, token);
        }
    }
    init(authConfig) {
        this._logger.info(formattedTimeString() + " Initializing Authentication Service...");
        this._authConfig = authConfig;
        this._setAuth0LockOptions();
        this._instantiateAuth0Lock();
    }
    isAuthenticated() {
        return isAuthenticated();
    }
    isTokenExpired() {
        return isAuthenticated().isTokenExpired;
    }
    getUserProfile(accessToken) {
        return new Promise((resolve, reject) => {
            this._lock.getUserInfo(accessToken, (error, profile) => {
                if (error) {
                    const errorMessage = `There was an error retrieving profile data.`;
                    this._logger.error(errorMessage, error);
                    resolve({
                        isError: true,
                        error: errorMessage + error.toString()
                    });
                }
                else {
                    this._logger.debug("Got user profile:", profile);
                    const authorization = profile[AUTH_AUTHORIZATION];
                    resolve({
                        isError: false,
                        userProfile: profile,
                        authorization: json2ts(authorization, Authorization)
                    });
                }
            });
        });
    }
    async _performLogout() {
        await this._store.dispatch(new UserLoggedOut());
        await this._store.dispatch(new UserReset());
        const logoutURL = window.location.protocol + DOUBLE_SLASH + window.location.hostname +
            ((window.location.port) ? COLON + window.location.port : EMPTY) + this._authConfig.logoutURL;
        this._lock.logout({ returnTo: logoutURL });
    }
    async _handleLogoutTapped(event, token) {
        // For some obscure reason the long press code sometimes emits a touch event for a normal press
        // and undefined for a long press.
        // Other times it just emits true or false for whether it was a long press or not...
        let isLongPress;
        if (event === undefined || event === true) {
            isLongPress = true;
        }
        else {
            isLongPress = false;
        }
        if (isLongPress) {
            this._showLogoutOptions();
        }
        else {
            this._performLogout();
        }
    }
    async _handleLogoutClicked(event, token) {
        if (this._copyAuthTokenToClipboardTriggered(event, token)) {
            console.log("Authentication token copied to clipboard.");
            return copyToClipboard(`${HEADER_BEARER} ${token}`);
        }
        else if (this._showLogoutOptionsTriggered(event)) {
            return this._showLogoutOptions();
        }
        else {
            return this._performLogout();
        }
    }
    _showLogoutOptions() {
        this._notificationsService.post({
            message: this._translateService.instant(_TRANSLATE_TITLE_KEY),
            showCloseButton: true,
            actions: {
                clearCache: {
                    label: this._translateService.instant(_TRANSLATE_CLEAR_CACHE_KEY),
                    action: async () => this._clearAuthenticationAndLogout()
                },
                clearAll: {
                    label: this._translateService.instant(_TRANSLATE_CLEAR_ALL_DATA_KEY),
                    action: async () => this._clearLocalStorageAndLogout()
                }
            }
        });
    }
    _copyAuthTokenToClipboardTriggered(event, token) {
        return (null != event.shiftKey && true === event.shiftKey)
            && (null != event.altKey && true === event.altKey)
            && (null != token);
    }
    _showLogoutOptionsTriggered(event) {
        return (null != event.shiftKey && true === event.shiftKey);
    }
    async _clearAuthenticationAndLogout() {
        await this._store.dispatch(new UserLoggedOut());
        // Can't import from context files, creates circular dependency loop.
        localStorage.removeItem(`${LS_PREFIX}user`);
        this._forceReload();
    }
    async _clearLocalStorageAndLogout() {
        await this._store.dispatch(new UserLoggedOut());
        localStorage.clear();
        this._forceReload();
    }
    _forceReload() {
        // Need to support this on the HTTP server, to redirect the POST to the login page.
        // const form: HTMLFormElement = document.createElement(FORM) as HTMLFormElement;
        // form.method = POST;
        // form.action = `${location.protocol}//${location.host}/${AppRoutes.Login}`;
        // document.body.appendChild(form);
        // form.submit();
        location.reload();
    }
    _instantiateAuth0Lock() {
        this._logger.info("Initializing Auth0 Lock " + formattedTimeString());
        this._lock = new Auth0Lock(this._authConfig.clientID, this._authConfig.domain, this._lockOptions);
        this._lock.on("signin submit", () => {
            console.log("Signing In");
        });
        this._lock.on('authorization_error', (err) => {
            console.error('Authorization Error:', err);
        });
        this._lock.on('authenticated', (authResult) => {
            console.log('Authenticated:', authResult);
        });
        this._lock.on('unrecoverable_error', (err) => {
            console.error('Unrecoverable Error:', err);
        });
    }
    _setAuth0LockOptions() {
        this._lockOptions = {
            autoclose: true,
            autofocus: true,
            debug: true,
            closable: false,
            languageDictionary: {
                title: ""
            },
            theme: {
                logo: "/assets/img/didici_logo.png",
                primaryColor: "#754C99"
            },
            auth: {
                sso: false,
                params: {
                    scope: this._authConfig.scope
                },
                // for now I add the redirectUrl here later we can send the whole callbackURL from the config
                redirectUrl: window.location.protocol + "//" + window.location.hostname +
                    ((window.location.port) ? ":" + window.location.port : "") + this._authConfig.callbackURL,
                responseType: "token"
            }
        };
    }
}
AuthenticationService.ɵfac = function AuthenticationService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || AuthenticationService)(i0.ɵɵinject(i1.LoggingService), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.NotificationsService), i0.ɵɵinject(i4.TranslateService)); };
AuthenticationService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuthenticationService, factory: AuthenticationService.ɵfac });
