import { __decorate, __metadata } from "tslib";
import { JsonProperty, JsonObject } from "json2typescript";
import * as GQL from "../../util/graphql-tags";
import { User } from "../user/user.model";
import { SnapshotType } from "./snapshot-type.model";
let Snapshot = class Snapshot {
    constructor() {
        this.guid = undefined;
        this.description = undefined;
        this.sequence = undefined;
        this.exists = false;
        this.snapshotGUID = undefined;
        this.Owner = undefined;
        // @JsonProperty(GQL.REFERENCE, Reference, true)
        // public Reference: Reference = undefined;
        this.Type = undefined;
        this.InputReference = undefined;
    }
};
__decorate([
    JsonProperty(GQL.SCALAR_GUID, String, false),
    __metadata("design:type", String)
], Snapshot.prototype, "guid", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_DESCRIPTION, String, false),
    __metadata("design:type", String)
], Snapshot.prototype, "description", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_SEQUENCE, Number, true),
    __metadata("design:type", Number)
], Snapshot.prototype, "sequence", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_EXISTS, Boolean, true),
    __metadata("design:type", Boolean)
], Snapshot.prototype, "exists", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_SNAPSHOT_GUID, String, true),
    __metadata("design:type", String)
], Snapshot.prototype, "snapshotGUID", void 0);
__decorate([
    JsonProperty(GQL.OWNER, User, true),
    __metadata("design:type", User)
], Snapshot.prototype, "Owner", void 0);
__decorate([
    JsonProperty(GQL.TYPE, SnapshotType, true),
    __metadata("design:type", SnapshotType)
], Snapshot.prototype, "Type", void 0);
Snapshot = __decorate([
    JsonObject("Snapshot")
], Snapshot);
export { Snapshot };
