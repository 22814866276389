var BusinessDateOffset_1;
import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from "json2typescript";
import * as GQL from "../../util/graphql-tags";
import { Order } from "../order.enum";
const _OFFSET_ELEMENTS = [
    GQL.SCALAR_YEARS,
    GQL.SCALAR_QUARTERS,
    GQL.SCALAR_MONTHS,
    GQL.SCALAR_WEEKS,
    GQL.SCALAR_DAYS,
    GQL.SCALAR_HOURS,
    GQL.SCALAR_MINUTES,
    GQL.SCALAR_SECONDS
];
let BusinessDateOffset = BusinessDateOffset_1 = class BusinessDateOffset {
    constructor() {
        this.years = undefined;
        this.quarters = undefined;
        this.months = undefined;
        this.weeks = undefined;
        this.days = undefined;
        this.hours = undefined;
    }
    static IsValidOffset(offset) {
        if (null == offset) {
            return false;
        }
        else if (Object.keys(offset).length === 0) {
            return false;
        }
        else {
            return true;
        }
    }
    /**
     * This function tells you if an offset is to the future.
     * It it is not (returns false) then it is in the past.
     */
    static isFuture(toCheck) {
        // Go from the least granular down as it's more likely we'll find a value quickly that way.
        for (const part of BusinessDateOffset_1.DATE_OFFSET_ELEMENTS_DESC) {
            if (toCheck[part] !== undefined) {
                if (toCheck[part] !== 0) {
                    return toCheck[part] > 0;
                }
            }
        }
        return false;
    }
    static compare(a, b, sortOrder = Order.Ascending) {
        if (null == b && null != a) {
            // Something comes before nothing (unless sorting backwards).
            return 1 * sortOrder;
        }
        else if (null != b && null == a) {
            // Something comes before nothing unless sorting backwards.
            return -1 * sortOrder;
        }
        else if (null == a && null == b) {
            return 0;
        }
        for (const element of _OFFSET_ELEMENTS) {
            const compare = BusinessDateOffset_1._compareOffsetElement(a[element], b[element], sortOrder);
            if (0 !== compare) {
                return compare;
            }
        }
        return 0;
    }
    static equals(a, b) {
        if (null == a || null == b) {
            return false;
        }
        else {
            return BusinessDateOffset_1.compare(a, b, Order.Ascending) === 0;
        }
    }
    /**
     * This will return 0 if either element is null or they are both equal and
     * either a negative or positive number in all other cases, based on the values
     * supplied and the sortOrder requested.
     * @param a First element to compare
     * @param b Second element to compare
     * @param sortOrder The order to sort them in (a-b or b-a)
     */
    static _compareOffsetElement(a, b, sortOrder) {
        let compare = 0;
        if (null != a && null != b) {
            compare = a - b;
        }
        return compare * sortOrder;
    }
};
// Date offset elements in order of granularity from most granular to least.
BusinessDateOffset.DATE_OFFSET_ELEMENTS_ASC = ["seconds", "minutes", "hours", "days", "weeks", "months", "quarters", "years"];
// Opposite order.
BusinessDateOffset.DATE_OFFSET_ELEMENTS_DESC = BusinessDateOffset_1.DATE_OFFSET_ELEMENTS_ASC.slice().reverse();
__decorate([
    JsonProperty(GQL.SCALAR_YEARS, Number, true),
    __metadata("design:type", Number)
], BusinessDateOffset.prototype, "years", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_QUARTERS, Number, true),
    __metadata("design:type", Number)
], BusinessDateOffset.prototype, "quarters", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_MONTHS, Number, true),
    __metadata("design:type", Number)
], BusinessDateOffset.prototype, "months", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_WEEKS, Number, true),
    __metadata("design:type", Number)
], BusinessDateOffset.prototype, "weeks", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_DAYS, Number, true),
    __metadata("design:type", Number)
], BusinessDateOffset.prototype, "days", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_HOURS, Number, true),
    __metadata("design:type", Number)
], BusinessDateOffset.prototype, "hours", void 0);
BusinessDateOffset = BusinessDateOffset_1 = __decorate([
    JsonObject("BusinessDateOffset")
], BusinessDateOffset);
export { BusinessDateOffset };
