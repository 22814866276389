import { JsonProperty, JsonObject } from "json2typescript";

import * as GQL from "../../util/graphql-tags";
import { User } from "../user/user.model";
import {
  SnapshotType
} from "./snapshot-type.model";
import { Reference, ReferenceInput } from "../reference.model";

@JsonObject("Snapshot")
export class Snapshot {

  @JsonProperty(GQL.SCALAR_GUID, String, false)
  public guid: string = undefined;

  @JsonProperty(GQL.SCALAR_DESCRIPTION, String, false)
  public description: string = undefined;

  @JsonProperty(GQL.SCALAR_SEQUENCE, Number, true)
  public sequence: number = undefined;

  @JsonProperty(GQL.SCALAR_EXISTS, Boolean, true)
  public exists: boolean = false;

  @JsonProperty(GQL.SCALAR_SNAPSHOT_GUID, String, true)
  public snapshotGUID: string = undefined;

  @JsonProperty(GQL.OWNER, User, true)
  public Owner: User = undefined;

  // @JsonProperty(GQL.REFERENCE, Reference, true)
  // public Reference: Reference = undefined;

  @JsonProperty(GQL.TYPE, SnapshotType, true)
  public Type: SnapshotType = undefined;

  public InputReference: ReferenceInput = undefined;

}
