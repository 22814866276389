import { TranslateService } from "@ngx-translate/core";
import * as d3 from "d3";
import { copyObjectWithoutNullsOrGraphQLMetaKeys } from "../../util/object-utils";
import { Logger } from "../logging/logger";
import { LoggingService } from "../logging/logging.service";
import { formattedTimeString } from "../../util/utils";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../logging/logging.service";
const LOG_NAMESPACE = "services.locale";
export class LocaleService {
    /*
    * Get Current Locale
    */
    get locale() {
        return this._locale;
    }
    constructor(_translate, loggingService) {
        this._translate = _translate;
        this._supportedLanguages = ['en', 'fr', 'ru'];
        this._defaultLanguageConfig = {
            user: "en",
            client: "en",
            didici: "en"
        };
        this._logger = new Logger(LOG_NAMESPACE, loggingService);
        const defaultLanguage = this._defaultLanguage();
        this._translate.setDefaultLang(defaultLanguage);
        const browserLang = _translate.getBrowserLang();
        // tslint:disable-next-line:max-line-length
        const language = browserLang && this._languageAvailable(browserLang) ? browserLang : defaultLanguage;
        this.switchLanguage(language);
    }
    init(localeConfig) {
        // If we leave the null entries that graphql will have populated for
        // locale configuration which is missing then you get some cryptic error
        // messages in the browser console.
        // Removing the nulls will make it a little clearer in the console log
        // what is missing...
        localeConfig = copyObjectWithoutNullsOrGraphQLMetaKeys(localeConfig);
        this._logger.info(formattedTimeString() + " Initializing Locale Service...");
        this._logger.debug("Local service loaded with config", { config: localeConfig });
        try {
            this._locale = d3.locale(localeConfig);
        }
        catch (e) {
            const userLocale = navigator.language ? navigator.language : navigator.userLanguage;
            // tslint:disable-next-line:max-line-length
            throw new Error("Error initializing D3 locale.  This is most likely caused bymissing or invalid locale configuration from back end for locale '" + userLocale + "'.  Make sure all elements of the locale are populated (look in en-GB locale for full specification.  Original error follows: \n" + e);
        }
        this._logger.info("Locale Service Initialized.");
    }
    switchLanguage(newLanguage) {
        this._logger.info("Switching Language to '" + newLanguage + "'");
        this._currentLanguage = newLanguage;
        this._translate.use(this._currentLanguage);
    }
    _defaultLanguage() {
        if (this._languageAvailable(this._defaultLanguageConfig["user"])) {
            return this._defaultLanguageConfig["user"];
        }
        else if (this._languageAvailable(this._defaultLanguageConfig["client"])) {
            return this._defaultLanguageConfig["client"];
        }
        else if (this._languageAvailable(this._defaultLanguageConfig["didici"])) {
            return this._defaultLanguageConfig["didici"];
        }
        else {
            // tslint:disable-next-line:max-line-length
            throw new Error("Default language config didn't contain any supported languages!  Supported langauges are: " + this._supportedLanguages.join(", ") + ".  Configured defaults were :" + JSON.stringify(this._defaultLanguageConfig) + ".  Please update the default language configuration to include a supported language!");
        }
    }
    _languageAvailable(language) {
        this._logger.info("Available language '" + language + "'");
        return this._supportedLanguages.indexOf(language) > -1;
    }
}
LocaleService.ɵfac = function LocaleService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || LocaleService)(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.LoggingService)); };
LocaleService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: LocaleService, factory: LocaleService.ɵfac });
