import { __decorate, __metadata } from "tslib";
import * as GQL from "../util/graphql-tags";
import { JsonObject, JsonProperty } from 'json2typescript';
let LocationType = class LocationType {
    constructor() {
        this.id = undefined;
        this.guid = undefined;
        this.nameRef = undefined;
    }
};
__decorate([
    JsonProperty(GQL.SCALAR_ID, String, false),
    __metadata("design:type", String)
], LocationType.prototype, "id", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_GUID, String, false),
    __metadata("design:type", String)
], LocationType.prototype, "guid", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_NAME_REF, String, true),
    __metadata("design:type", String)
], LocationType.prototype, "nameRef", void 0);
LocationType = __decorate([
    JsonObject("LocationType")
], LocationType);
export { LocationType };
