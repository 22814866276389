import { __decorate } from "tslib";
import { JsonConverter } from 'json2typescript';
import { MutationType } from './mutation-type.enum';
let MutationTypeArrayConverter = class MutationTypeArrayConverter {
    serialize(data) {
        if (!data) {
            return undefined;
        }
        return data.map((item) => item.toString());
    }
    deserialize(val) {
        if (!val) {
            return [];
        }
        const ret = val.map((item) => MutationType[item]);
        return ret.filter((item) => undefined !== item && null !== item);
    }
};
MutationTypeArrayConverter = __decorate([
    JsonConverter
], MutationTypeArrayConverter);
export { MutationTypeArrayConverter };
