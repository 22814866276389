import { Level } from './level.model';
import * as i0 from "@angular/core";
export class LoggerService {
    /**
     * Outputs an error message.
     */
    error(message, ...optionalParams) {
    }
    /**
     * Outputs a warning message.
     */
    warn(message, ...optionalParams) {
    }
    /**
     * Outputs an informational message.
     */
    info(message, ...optionalParams) {
    }
    /**
     * Outputs a debug message.
     */
    debug(message, ...optionalParams) {
    }
    /**
     * Outputs a message.
     */
    log(message, ...optionalParams) {
    }
    /**
     * Outputs a message.
     */
    logLevel(level, message, ...optionalParams) {
        switch (level) {
            case Level.ERROR:
                this.error(message, ...optionalParams);
                break;
            case Level.WARN:
                this.warn(message, ...optionalParams);
                break;
            case Level.INFO:
                this.info(message, ...optionalParams);
                break;
            case Level.DEBUG:
                this.debug(message, ...optionalParams);
                break;
            case Level.LOG:
                this.log(message, ...optionalParams);
                break;
        }
    }
}
LoggerService.ɵfac = function LoggerService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || LoggerService)(); };
LoggerService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: LoggerService, factory: LoggerService.ɵfac });
