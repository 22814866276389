import { Actions, createEffect, ofType } from "@ngrx/effects";
import { MutationType } from "../dashboard/components/config/mutation-type.enum";
import { DataService } from "../services/data/data.service";
import { Noop } from "./effects";
import { ADD_SNAPSHOT_USING_CONTEXT, MutationSuccessful } from "./mutation.actions";
import { Store } from "@ngrx/store";
import { switchMap, withLatestFrom } from "rxjs/operators";
import { ACTIVE_SNAPSHOT_CHANGED, ActiveSnapshotChanged, SNAPSHOT_TO_CONTEXT } from "./reference.actions";
import { TemporalService } from "../services/temporal/temporal.service";
import { ContextConfiguration } from "./config/context.config";
import { CurrentBusinessDateChangeComplete } from "./temporal.actions";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../services/temporal/temporal.service";
import * as i3 from "@ngrx/store";
import * as i4 from "../services/data/data.service";
const _LOG_ID = "snapshot.effects";
// tslint:disable-next-line:max-line-length
const _ADD_SNAPSHOT_MUTATION = `mutation ($ownerID: String!, $reference: ReferenceInput!, $snapshotGUID: String!) {
  AddSnapshot(Owner: {id: $ownerID}, Reference: $reference, snapshotGUID: $snapshotGUID) {
    guid
    exists
    description
    sequence
    snapshotGUID
    Owner {
      id
      guid
      displayName
    }
    Type {
      guid
      code
      name
      nameRef
      SuperType {
        guid
        code
        nameRef
      }
    }
    Reference {
      nameRef
      Entity {
        id
        guid
        name
      }
      Region {
        id
        guid
        name
      }
      Location {
        id
        guid
        name
      }
      Person {
        id
        guid
        displayName
      }
    }
  }
}
`;
export class SnapshotEffects {
    constructor(_action$, _temporalService, _store, _dataService) {
        this._action$ = _action$;
        this._temporalService = _temporalService;
        this._store = _store;
        this._dataService = _dataService;
        // @Effect()
        this.snapshotAdded$ = createEffect(() => this._action$
            .pipe(ofType(ADD_SNAPSHOT_USING_CONTEXT), switchMap(async (action) => {
            return this.saveSnapshot(action);
        })));
        // @Effect()
        this.snapshotUpdated$ = createEffect(() => this._action$
            .pipe(ofType(ACTIVE_SNAPSHOT_CHANGED), switchMap(async (obj) => {
            const { payload } = obj;
            if (null == payload || null == payload.Context || null == payload.Context.context) {
                return new Noop();
            }
            const context = JSON.parse(payload.Context.context);
            const rangeAndAggregation = null != context.temporal ? { ...context.temporal } : null;
            if (null == rangeAndAggregation) {
                return new Noop();
            }
            const temporalContext = await this._temporalService.HydrateInitialBaselineDatesAndRanges(rangeAndAggregation.range, rangeAndAggregation.aggregation, ContextConfiguration.LOADED.temporalAggregations);
            return new CurrentBusinessDateChangeComplete(temporalContext);
        })));
        // @Effect()
        this.SnapshotToContext$ = createEffect(() => this._action$
            .pipe(ofType(SNAPSHOT_TO_CONTEXT), withLatestFrom(this._store), switchMap(async ([, context]) => {
            return new ActiveSnapshotChanged(context.reference.ActiveSnapshot);
        })));
        this._store.select("user").subscribe((newUserContext) => {
            this._user = newUserContext.profile;
        });
    }
    async saveSnapshot(action) {
        const snapshotGUID = action.payload.snapshotGUID;
        const params = {
            ownerID: this._user.id,
            reference: this._dataService.ActiveReferenceInput,
            snapshotGUID
        };
        try {
            const result = await this._dataService.mutate(_LOG_ID, _ADD_SNAPSHOT_MUTATION, params);
            console.log("SNAPSHOT SAVED");
            return new MutationSuccessful([MutationType.Snapshot]);
        }
        catch (err) {
            console.log(`SNAPSHOT __NOT__ SAVED!:

      ${err}`);
            return new Noop();
        }
    }
}
SnapshotEffects.ɵfac = function SnapshotEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || SnapshotEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.TemporalService), i0.ɵɵinject(i3.Store), i0.ɵɵinject(i4.DataService)); };
SnapshotEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SnapshotEffects, factory: SnapshotEffects.ɵfac });
