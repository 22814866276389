import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Logger } from "../logging/logger";
import { LoggingService } from "../logging/logging.service";
import * as jp from "jsonpath";
import { FormatterService } from "../../services/formatter/formatter.service";
import { DataPreparation } from "./config/data-preparation";
import { Column } from "./config/column";
import { Sort } from "./config/sort";
import { Store } from "@ngrx/store";
import * as fromReference from "../../context/reference.reducers";
import { Subscription } from "rxjs";
import * as fromContext from "../../context/context";
import * as constants from "../../util/string-constants";
import { NullValues } from "../../dashboard/components/tables/config/null-values";

const LOG_NAMESPACE = "services.data-preparation";


@Injectable()
export class DataPreparationService {
  public replacements: any = {};
  private _referenceContext: fromReference.ReferenceContext;
  private _referenceSubscription$: Subscription;

  private dataPreparers = {
    dataPreparer1: (config: DataPreparation, data: any) => {
      // For multiple items (like locations) having their multiple rows.
      // Supports Total.
      const rowsMeta: any = [];
      const primaryItems: any[] = [];
      if (null != config.primaryItemsSet) {
        config.primaryItemsSet.forEach((primaryItemsObj: any) => {
          let items = jp.query(data, primaryItemsObj.jsonpath);
          items.forEach((item) => {
            item.ddcMeta = {};
            item.ddcMeta.fixedAdditionalValue = primaryItemsObj.fixedAdditionalValue;
            item.ddcMeta.styleSelectors = primaryItemsObj.styleSelectors;
            item.ddcMeta.nullValues = primaryItemsObj.nullValues;
          });
          items = this._sortRows(primaryItemsObj.sort, items);
          primaryItems.push(...items);
        });
      } else {
        config.primaryItemsJsonpaths.forEach((primaryItemsJsonpath: string) => {
          const items = jp.query(data, primaryItemsJsonpath);
          primaryItems.push(...items);
        });
      }
      const formattedData: any[][] = [];
      const unFormattedData: any[][] = [];
      let rowIdx = -1;
      primaryItems.forEach((primaryItem: any) => {
        if (true === this._checkNullValues(primaryItem.ddcMeta.nullValues, primaryItem)) {
          return;
        }
        config.rowsColumns.forEach((rowColumns: any) => {
          rowIdx++;
          const row: any[] = [];
          const rowStyleSelectorValues: any[] = [];
          const unFormattedRow: any[] = [];
          const columns: Column[] = rowColumns.columns || rowColumns;
          columns.forEach((rowColumn: any) => {
            let value: any;
            if (null != rowColumn.jsonpaths) {
              rowColumn.jsonpaths.find((jsonpath: string) => {
                value = jp.query(primaryItem, jsonpath)[0];
                return value;
              });
            }
            if (rowColumn.dynamicValues) {
              rowColumn.dynamicValues.find((dynamicValue: any) => {
                if (dynamicValue.jsonpath) {
                  value = jp.query(primaryItem, dynamicValue.jsonpath)[0];
                } else if (dynamicValue.value) {
                  value = this._translateService.instant(dynamicValue.value);
                }

                if (null != dynamicValue.replacements) {
                  Object.entries(dynamicValue.replacements).forEach(([str, path]) => {
                    if (typeof(path) === "string") {
                      const replacementValue = jp.query(primaryItem, path as string)[0];
                      value = value.replace(str, replacementValue);
                    } else {
                      let replacementValue = jp.query(primaryItem, (path as any).path)[0];
                      replacementValue = this._applyFormatters((path as any).formatters, replacementValue);
                      value = value.replace(str, replacementValue);
                    }
                  });
                }
                value = this._applyFormatters(dynamicValue.formatters, value, primaryItem);
                return value;
              })
            }
            else if (null == value && null != rowColumn.value) {
              value = this._translateService.instant(rowColumn.value);
              value = value.replace("${row-index}", rowIdx);
              if (null != rowColumn.valueFormatters) {
                value = this._applyFormatters(rowColumn.valueFormatters, value);
              }
            }
            if (null != rowColumn.unFormattedJsonpath) {
              const unFormattedValue = jp.query(primaryItem, rowColumn.unFormattedJsonpath)[0];
              unFormattedRow.push(unFormattedValue);
            } else {
              unFormattedRow.push(value);
            }
            if (null != rowColumn.format) {
              const formatter = this._formatterService.formatter(rowColumn.format);
              value = formatter.formatData(value);
            }
            if (null != rowColumn.formatters) {
              value = this._applyFormatters(rowColumn.formatters, value);
            }
            let styleSelectorValue;
            if (null != rowColumn["style-selector-json-path"]) {
              styleSelectorValue = jp.query(primaryItem, rowColumn["style-selector-json-path"])[0];
            } else {
              styleSelectorValue = value;
            }
            row.push(value);
            rowStyleSelectorValues.push(styleSelectorValue);
          });
          rowsMeta.push({...rowColumns.meta, columns, primaryItem, styleSelectorValues: rowStyleSelectorValues});
          formattedData.push(row);
          unFormattedData.push(unFormattedRow);
        });
      });
      return [rowsMeta, formattedData, unFormattedData];
    },
    evalPaths: (config: DataPreparation, data: any) => {

      config.evalPaths.forEach((evalPath: any) => {
        jp.apply(data, evalPath.jsonpath, (value) => {
          if (Array.isArray(value)) {
            value = JSON.stringify(value)
          }
          const toEval = evalPath.eval.replace(/\$\{value\}/g, value);
          value = window.eval(toEval);
          return value;
        });
      });
      return data;
    },
    nonDimensionalTimeseriesDataWithMeta: (config: DataPreparation, incomingData: any) => {

      let data: any[] = [];

      const xAxisData = jp.query(incomingData, config.xAxis["list-path"]).map((d) => {
        let xAxisDatum = jp.query(d, config.xAxis.path)[0];
        if (null != config.xAxis.formatter) {
          const formatter = this._formatterService.formatter(config.xAxis.formatter);
          xAxisDatum = formatter.formatData(xAxisDatum);
        }
        return xAxisDatum;
      });


      config.keyValues.forEach((keyValue: any, keyValueIndex) => {
        const values = jp.query(incomingData, keyValue.path);
        if (null != keyValue.eval) {
          values.forEach((value, valueIdx) => {
            values[valueIdx] = window.eval(keyValue.eval.replace("${value}", value));
          })
        }
        const datum = {
          key: this._translateService.instant(keyValue.name),
          yAxis: keyValue.axis || 1,
          type: keyValue.type || "bar",
          color: keyValue.color,
          values: values.map((value: any, valueIdx: number) => {
            return {
              valueIndex: keyValueIndex,
              x: true === config.xAxis["use-index"] ? valueIdx : xAxisData[valueIdx],
              y: value
            };
          })
        };
        data.push(datum);
      });
      return data;
    }
  }

  private _logger: Logger;

  constructor(
    private _translateService: TranslateService,
    private _formatterService: FormatterService,
    protected _store: Store<fromContext.Context>,
    private _loggingService: LoggingService
  ) {
    this._logger = new Logger(LOG_NAMESPACE, _loggingService);
    this.subscribeToReferenceContext();
  }

  public subscribeToReferenceContext() {
    if (null != this._referenceSubscription$) {
      return;
    }
    this._referenceSubscription$ = this._store.select(constants.CONTEXT_REFERENCE).subscribe((newReference: fromReference.ReferenceContext) => {
      this._referenceContext = newReference;
    });
  }

  public prepare(config: DataPreparation, data: any) {
    const dataPreparer = this.dataPreparers[config.name];
    if (null == dataPreparer) {
      this._logger.error(`dataPreparer with name '${config.name}' not found.`);
    }
    this._generateReplacementsMap(config.replacements, data);
    const preparerConfig: DataPreparation = JSON.parse(JSON.stringify(config));
    if (preparerConfig.primaryItemsSet) {
      preparerConfig.primaryItemsSet.forEach((primaryItemsObj, primaryItemsObjIdx) => {
        preparerConfig.primaryItemsSet[primaryItemsObjIdx].jsonpath = this._applyReplacements(primaryItemsObj.jsonpath);
      });
    }
    return dataPreparer(preparerConfig, data);
  }

  private _sortRows(sort: Sort, rows: any[]): any {
    if (null == sort) {
      return rows;
    }
    const sortedRows: any[] = [];

    if (null != sort.order) {

      sort.order.forEach((sortValue: string) => {
        const row = rows.find((_row: any, i: number): any => {
          const value = jp.query(_row, sort.key)[0];
          // tslint:disable-next-line:triple-equals
          if (value == sortValue) {
            rows.splice(i, 1);
            return true;
          }
        });
        if (null != row) {
          sortedRows.push(row);
        }
      });
      return sortedRows;
    }
  }

  private _checkNullValues(nullValues: NullValues, data: any): boolean {
    if (null != nullValues && true === nullValues.hide) {
      const values = [];
      for (const path of nullValues.valuePaths) {
        const dataPath = path;
        const value = jp.query(data, dataPath)[0];
        values.push(value);
      }
      if (nullValues.rule === "All") {
        if (true === values.every(x => x == null)) {
          return true;
        }
      } else {
        if (true === values.some(x => x == null)) {
          return true;
        }
      }
    }
    return false;
  }

  private _generateReplacementsMap(replacementsConfig: any, data: any) {
    this.replacements = {};

    if (null != replacementsConfig) {
      replacementsConfig.forEach((replacementObj: any) => {
        const datum = jp.query(data, replacementObj.path);
        if (datum.length > 0) {
          this.replacements = {...this.replacements, ...replacementObj.exists};
          return;
        }
        this.replacements = {...this.replacements, ...replacementObj.default};
      })
    }
  }

  private _applyReplacements(path: string) {
    Object.entries(this.replacements).forEach((replacement: any) => {
      path = path.replace(`\${${replacement[0]}}`, replacement[1]);
    });
    return path;
  }

  private _getReplacementValue(value: string, config: any, data: any = null): string {
    let sourceData = data;
    if (null == config.key) {
      return value;
    }
    if (config.context === constants.CONTEXT_REFERENCE) {
      sourceData = this._referenceContext;
    }
    return jp.query(sourceData, config.path)[0];
  }

  private _applyFormatters(formatters: any[], value: string, data: any = null) {
    if (null == formatters) {
      return value;
    }
    formatters.forEach((formatterConfig: any) => {
      const formatter = this._formatterService.formatter(formatterConfig.formatter);
      let replacementValue = this._getReplacementValue(value, formatterConfig, data);
      replacementValue = formatter.formatData(replacementValue);
      if (formatterConfig.key) {
        value = value.replace(formatterConfig.key, replacementValue);
      } else {
        value = replacementValue;
      }
    });
    return value;
  }

}
