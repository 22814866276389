
import {
  Series
} from "../../dashboard/components/config/series";
import {
  Alert
} from "../../model/alert/alert.model";
import {
  AlertsUXFacade
} from "../../model/alert/alerts-ux-facade";
import * as OU from "../../util/object-utils";
import {
  COLON_SPACE,
  EMPTY,
  ID,
  SEMI_COLON,
  SPACE
} from "../../util/string-constants";
import {
  IFormatter
} from "../formatter/i-formatter.interface";
import { TooltipBase } from "./base-tooltip";
import { BusinessDate } from '../../model/business-dates/business-date.model';

import * as GQL from "../../util/graphql-tags";

const _ALERTS_UX_FACADE: string = "alertsUXFacadeObject";
const _ALERTS_BY_BUSINESS_DATE_GUID = "alertsByBusinessDateGUID";

const _SERIES_DEFINITION: string = "seriesDefinition";
const _CSAT_SERIES_ID: string = "CSAT.Score.Overall";

const _INLINE_CONTAINER_STYLE: string = "line-height: 1.8;width: 24px;height: 24px;text-align: center;border:1px solid; padding: 0px; margin: 0px;";
const _INLINE_TEXT_STYLE: string = "font-size: 14px;color:#555555";

const _DATA: string = "data";
const _ADDITIONAL_DATA: string = "additionalData";

const _GREY_COLOR: string = "#555555";
const _WHITE_COLOR: string = "#ffffff";

const _DEFAULT_MAX_ALERTS_IN_TOOLTIP = 3;

export class SalesVSCsatTooltip extends TooltipBase {

  public override generateTooltipContent(data: any, params?: any): string {
    this._logService.debug("Generating SalesVSCsat Tooltip of data ", {tooltipData: data, parameter: params});
    const businessDate: BusinessDate = data['series'][0][GQL.SCALAR_DATA][GQL.BUSINESS_DATE];
    const formatterConfig = this._config["formatters"];
    const headerFormatter = this._formatterService.formatter(formatterConfig.header);
    const header = headerFormatter.formatData(businessDate);
    const salesFormatter = this._formatterService.formatter(formatterConfig.sales);
    const csatFormatter = this._formatterService.formatter(formatterConfig.csat);
    let additionalValueFormatter: IFormatter;
    if (formatterConfig.additionalValue) {
      additionalValueFormatter = this._formatterService.formatter(formatterConfig.additionalValue);
    }

    let popupHTML = '<table style="width: 95%; max-width: 300px; font-variant: small-caps;">';

    if (this._config["showHeader"]) {
      popupHTML += '<thead><tr> <td class="store-popup-title" colspan="3"> ' + header + '</td></tr></thead>';
    }

    const seriesDefinitions: Series[] = data.seriesDefinitions;
    data.series.forEach((ele: any) => {
      const val = ele['value'];
      const seriesDefinition: Series = seriesDefinitions.find((seriesDef: Series) => {
        return seriesDef.translatedNameWithAxisHint === ele.key;
      });

      popupHTML += '<tr class="store-popup-text">';
      let legendClass: string;
      const seriesColour =  ele['data']['type'] === "area" ? ele['color'] : undefined;
      const lineColour =  ele['data']['type'] === "area" ? undefined : ele['color'];

      if (this._config["showColorCode"]) {
        if (seriesDefinition && seriesDefinition.benchmark) {
          legendClass = "legend-color-guide-dotted-line";
        } else {
          legendClass = ele['data']['type'] === "area" ? "legend-color-guide-area" : "legend-color-guide-line";
        }
      }

      popupHTML += '<td align="right" class="legend-icon ' + legendClass + '"><div';
      if (seriesColour !== undefined) {
        popupHTML += ' style="background-color:' + seriesColour + '"';
      } else if (lineColour !== undefined) {
        popupHTML += ' style="color:' + lineColour + '"';
      }
      popupHTML += '></div></td>';

      if (this._config["showLabel"]) {
        popupHTML += '<td class="key" style="color:#555555;text-align: left;">' + ele['key'] + '</td>';
      }

      // For CSAT FORMATTER
      popupHTML +=  '<td class="value" style="color:#555555;text-align:right;">';
      if (val) {
        if (ele[_DATA][_SERIES_DEFINITION][ID] === _CSAT_SERIES_ID) {
          popupHTML += csatFormatter.formatData(val);
        } else {
          const additionalvalue = ele[_DATA][_ADDITIONAL_DATA] ?
          SPACE + additionalValueFormatter.formatData(ele[_DATA][_ADDITIONAL_DATA]) :
          EMPTY;
          popupHTML += salesFormatter.formatData(val) + additionalvalue;
        }
      }
      popupHTML += '</td></tr>';
    });

    popupHTML += '</tbody></table>';

    if (!this._config["showAlerts"]) {
      return popupHTML;
    }

    let alertsHTML = EMPTY;
    const alerts = data[_ALERTS_BY_BUSINESS_DATE_GUID] || {};
    const periodAlerts: Alert[] = alerts[businessDate.guid] || [];

    // See multi.component.ts _addAlertsToTooltipData method and setChartData() method where this function
    // is stored into the parent class protected variable, after which it will be called (if defined)
    // and adds in the alerts data and the alerts UX Facade to the tooltip data object
    // which is why you can retrieve it here!
    const alertsUxFacade: AlertsUXFacade = data[_ALERTS_UX_FACADE] as AlertsUXFacade;
    const alertsTitle = this._translateService.instant(this._config["translations"].alerts);

    const maxAlertsInTooltip = this._config["maxAlertsInTooltip"] || _DEFAULT_MAX_ALERTS_IN_TOOLTIP;
    const showMoreAlertsMessage = periodAlerts.length > maxAlertsInTooltip;
    const alertsToRender = Math.min(periodAlerts.length, maxAlertsInTooltip);

    // Check on the UX facade in case it wasn't properly added to the data.
    if (alertsUxFacade !== undefined && periodAlerts.length > 0) {
      // tslint:disable-next-line:max-line-length
      alertsHTML += `<table style="width: 270px; max-width: 270px;"><tbody><tr><td colspan="2" style="font-variant: small-caps;">${alertsTitle}</td></tr>`;
      for (let i = 0; i < alertsToRender; i++) {
        const alert: Alert = periodAlerts[i];
        const alertIconStyles = OU.object2String(alertsUxFacade.alertContainerStyles(alert), COLON_SPACE, SEMI_COLON);
        const alertTextStyles = OU.object2String(alertsUxFacade.alertTextStyles(alert), COLON_SPACE, SEMI_COLON);
        const icon = alertsUxFacade.alertIcon(alert);
        const text = alertsUxFacade.alertTitle(alert);
        const scope = alertsUxFacade.alertScope(alert);
        const iconColor = "color:" + (alertIconStyles.indexOf('background-color') === -1 ? _GREY_COLOR : _WHITE_COLOR) + ";";
        alertsHTML +=
        `<tr>
            <td align="right" class="legend-icon" style="padding-top: 3px;"><div style="${_INLINE_CONTAINER_STYLE} ${alertIconStyles}">
              <div><i style="${iconColor}" class="fa ${icon}"></i></div></div>
            </td>
            <td nowrap="wrap" width="233px" class="alert-text" style=" ${alertTextStyles} ; ${_INLINE_TEXT_STYLE}">
              <span>${scope}: ${text}</span>
            </td>
        </tr>`;
      }
      if (showMoreAlertsMessage) {
        const moreAlertsMessage = this._translateService.instant(
          this._config["translations"].moreAlerts,
          {
            alertCount: periodAlerts.length - maxAlertsInTooltip
          }
        );
        alertsHTML +=
        `<tr>
          <td></td>
          <td nowrap="wrap" width="233px" style="margin-left: 5px; font-size: 11px; font-style: italic;">
            ${moreAlertsMessage}
          </td>
        </tr>`;
      }
      alertsHTML += '</tbody></table>';
    }
    return popupHTML + alertsHTML;
  }

}
