import { JsonObject, JsonProperty, Any } from "json2typescript";

@JsonObject("ZendeskSupportWidgetConfiguration")
export class ZendeskSupportWidgetConfiguration {
  @JsonProperty("account-url", String, false)
  public accountURL: string = null;

  @JsonProperty("widget-config", Any, false)
  public widgetConfig: any = null;
}
