import {
  Any,
  JsonObject,
  JsonProperty
} from "json2typescript";

import * as GQL from "../../util/graphql-tags";
import { json2ts } from "../../util/json-2ts";
import {
  Features,
  FeaturesConverter
} from "./features-model";
import {
  Preferences,
  PreferencesConverter
} from "./preferences-model";
import {
  Settings,
  SettingsConverter
} from "./settings.model";

const _DEFAULT_CONTEXT = {
  reference: {},
  temporal: {},
  dimension: {}
};

@JsonObject("User")
export class User {

  public static ToValidContextObject(data: any): User {
    if (data instanceof User) {
      return data;
    } else {
      return json2ts(data, User);
    }
  }

  public static ToValidInputObject(data: User): any {
    if (null == data) {
      return null;
    }

    return {
      id: data.id
    };
  }

  @JsonProperty(GQL.SCALAR_GUID, String, false)
  public guid?: string = undefined;

  @JsonProperty(GQL.SCALAR_ID, String, false)
  public id?: string = undefined;

  @JsonProperty(GQL.SCALAR_DISPLAY_NAME, String, false)
  public displayName?: string = undefined;

  @JsonProperty(GQL.SCALAR_NICKNAME, String, true)
  public nickName?: string = undefined;

  @JsonProperty(GQL.SCALAR_AVATAR_URL, String, false)
  public avatarURL?: string = undefined;

  @JsonProperty(GQL.SCALAR_EMAIL, String, true)
  public email?: string = undefined;

  @JsonProperty(GQL.SCALAR_STATUS, String, true)
  public status?: string = undefined;

  @JsonProperty(GQL.SCALAR_ROLE, String, true)
  public role?: string = undefined;

  @JsonProperty(GQL.SETTINGS, SettingsConverter, true)
  public Settings?: Settings = new Settings();

  @JsonProperty(GQL.FEATURES, FeaturesConverter, true)
  public Features?: Features = new Features();

  @JsonProperty(GQL.PREFERENCES, PreferencesConverter, true)
  public Preferences?: Preferences = new Preferences();

  @JsonProperty(GQL.DEFAULT_CONTEXT, Any, true)
  public DefaultContext?: any = _DEFAULT_CONTEXT;

  public static clone(toClone: User): User {
    if (null == toClone) {
      return null;
    }
    const ret = new User();
    ret.guid = toClone.guid;
    ret.id = toClone.id;
    ret.displayName = toClone.displayName;
    ret.nickName = toClone.nickName;
    ret.avatarURL = toClone.avatarURL;
    ret.email = toClone.email;
    ret.role = toClone.role;
    ret.Settings = new Settings();
    Object.assign(ret.Settings, toClone.Settings);
    ret.Features = new Features();
    Object.assign(ret.Features, toClone.Features);
    ret.Preferences = new Preferences();
    Object.assign(ret.Preferences, toClone.Preferences);
    ret.DefaultContext = {};
    Object.assign(ret.DefaultContext, toClone.DefaultContext);
    return ret;
  }

}
