import {
  JsonConverter,
  JsonCustomConvert,
  JsonObject,
  JsonProperty
} from "json2typescript";
import {
  Any
} from "json2typescript";

import {
  fromReferenceSelectors,
  ReferenceSelector,
  toReferenceSelectors
} from "../../../context/reference-selector.enum";
import {
  SelectorConfig
} from "./selector-config";

@JsonConverter
class ReferenceSelectorConverter implements JsonCustomConvert<ReferenceSelector[]> {
  public serialize(selectors: ReferenceSelector[]) {
    return fromReferenceSelectors(selectors);
  }

  public deserialize(selectors: any[]): ReferenceSelector[] {
    return toReferenceSelectors(selectors);
  }
}

// tslint:disable-next-line:max-classes-per-file
@JsonObject("Selectors")
export class Selectors {

  public static DEFAULT: Selectors = new Selectors();

  @JsonProperty("reference", ReferenceSelectorConverter, true)
  public reference: ReferenceSelector[] = [];

  @JsonProperty("allow-empty-context", Boolean, true)
  public allowEmptyContext: boolean = false;

  @JsonProperty("temporal-mappings", Any, true)
  public temporalMappings: any = undefined;

  @JsonProperty("temporal", [SelectorConfig], true)
  private _temporal: SelectorConfig[] = null;

  @JsonProperty("reference-paths", [SelectorConfig], true)
  private _referencePaths: SelectorConfig[] = null;

  @JsonProperty("dimension", [SelectorConfig], true)
  private _dimension: SelectorConfig[] = null;

  @JsonProperty("ux", [SelectorConfig], true)
  private _ux: SelectorConfig[] = null;

  // The logic in the data registrations expects these arrays (temporal, dimension, ux) to ONLY be specified if they are populated.
  // So there was some unexpected behaviour with empty arrays.
  // Hence, rather than forever put (if xx && xx.length > 0) I've internalized it so that an empty array
  // is semantically equivalent to a null value.
  public get temporal(): SelectorConfig[] {
    return this._temporal && this._temporal.length > 0 ? this._temporal : null;
  }

  public set temporal(val: SelectorConfig[]) {
    this._temporal = val;
  }

  public get referencePaths(): SelectorConfig[] {
    return this._referencePaths && this._referencePaths.length > 0 ? this._referencePaths : null;
  }

  public set referencePaths(val: SelectorConfig[]) {
    this._referencePaths = val;
  }

  public get dimension(): SelectorConfig[] {
    return this._dimension && this._dimension.length > 0 ? this._dimension : null;
  }

  public set dimension(val: SelectorConfig[]) {
    this._dimension = val;
  }

  public get ux(): SelectorConfig[] {
    return this._ux && this._ux.length > 0 ? this._ux : null;
  }

  public set ux(val: SelectorConfig[]) {
    this._ux = val;
  }

}
