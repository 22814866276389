import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import { switchMap } from "rxjs/operators";

import {
  DataService
} from "../services/data/data.service";
import {
  Logger
} from "../services/logging/logger";
import {
  LoggingService
} from "../services/logging/logging.service";
import { Noop } from "./effects";
import * as fromUser from "./user.actions";
import { Timestamp } from "../model/timestamp.model";

const LOG_NAMESPACE = "effects.user";

const LOGOUT_MUTATION = "mutation logout($timestamp: DateTimeInput!) { RecordUserEvent(Event: Logout, Timestamp: $timestamp)  }"

@Injectable()
export class UserEffects {

  private _logger: Logger;


  // @Effect({dispatch: false})
  public logout$ = createEffect(() => this._action$
  .pipe(
    ofType(fromUser.USER_LOGGED_OUT),
    switchMap(async (action: fromUser.UserLoggedOut) => {
      const timestamp = Timestamp.ToValidInputObject(Timestamp.now());
      try {
        await this._dataService.mutate(
          LOG_NAMESPACE,
          LOGOUT_MUTATION,
          {timestamp}
        );
      } catch (e) {
        console.error(e)
        this._logger.error("Error recording user logout:", e);
        // Don't re-raise, user is logging out anyway.
      }
    })
  ), { dispatch: false });


  constructor(private _action$: Actions, loggingService: LoggingService, private _dataService: DataService) {
    this._logger = new Logger(LOG_NAMESPACE, loggingService);
  }

}
