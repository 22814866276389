
import * as GQL from "../util/graphql-tags";
import { JsonObject, JsonProperty } from 'json2typescript';
import { Geo } from './geo.model';

@JsonObject("Region")
export class Region {
  @JsonProperty(GQL.SCALAR_ID, String, false)
  public id: string = undefined;

  @JsonProperty(GQL.SCALAR_GUID, String, false)
  public guid: string = undefined;

  @JsonProperty(GQL.SCALAR_NAME, String, false)
  public name: string = undefined;

  @JsonProperty(GQL.GEO, Geo, true)
  public Geo: Geo = undefined;
}
