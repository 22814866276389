// import { appConfig } from './app/app.config';
console.log("MAIN.TS LOADING");

/**
 * Angular bootstrapping
 */
import { platformBrowser } from "@angular/platform-browser";
import { decorateModuleRef } from "./app/environment";
/**
 * App Module
 * our top level module that holds all of our components.
 */
import { AppModule } from "./app/app.module";

platformBrowser()
  .bootstrapModule(AppModule)
  .then(decorateModuleRef)
  .catch((err) => console.error(err));
