import * as OU from "../../util/object-utils";
import { SPACE } from "../../util/string-constants";
import { ISelectorProperties } from "../brand/selector.properties.interface";
import { IFormatter } from "../formatter/i-formatter.interface";
import { TooltipBase } from "./base-tooltip";
import * as jp from "jsonpath";

const LABELS = "group-labels";
const COLON = ":";
const SEMI_COLON = ";";
const SEMI_COLON_SPACE = SEMI_COLON + SPACE;
const BRAND_SELECTOR = "brand-selector";
const TOOLTIP_HIGHLIGHTED_VALUE_KEY = "tooltip-highlighted-value-key";
const TOOLTIP_VALUE_KEY = "tooltip-value-key";
const TOOLTIP_BRAND_SELECTOR = "tooltip-brand-selector";
const TOOLTIP_POPUP_TITLE_KEY = "tooltip-popup-title-key";
const TOOLTIP_POPUP_SUBTITLE_KEY = "tooltip-popup-subtitle-key";
const SHOW_ZERO_VALUES = "show-zero-values";
const TOOLTIP_POPUP_COLUMN_HEADING_KEY = "tooltip-popup-column-heading-key";
const TOOLTIP_POPUP_COLUMN_TITLE_KEY = "tooltip-popup-column-title-key";
const TOOLTIP_POPUP_ROW_TITLE_KEY = "tooltip-popup-row-title-key";
const TOOLTIP_POPUP_TOTAL_TITLE_KEY = "tooltip-popup-total-title-key";

export class MultiDimensionHorizontalBarTooltip extends TooltipBase {
  /*
  * Have to modify nvd3 code to tell us if the series isArea, on hover, so that
  * we can change the shape of bullet based on line type.
  */

  public override generateTooltipContent(data: any, params?: any): string {
    this._logService.debug("Generating Dimension Horizontal Bar ToolTip of data ", {tooltipData: data, parameter: params});

    const valuePaths = params["value-paths"];
    const brandSelectorConfig = this._config[BRAND_SELECTOR];
    const tooltipStyleBrandSelector = brandSelectorConfig[TOOLTIP_BRAND_SELECTOR];
    const titleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_TITLE_KEY]);
    const subtitleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_SUBTITLE_KEY]);

    const columnHeaderStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_COLUMN_HEADING_KEY]);
    const columnTitleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_COLUMN_TITLE_KEY]);
    const rowTitleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_ROW_TITLE_KEY]);

    const valueStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_VALUE_KEY]);
    const highlightedValueStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_HIGHLIGHTED_VALUE_KEY]);

    const totalTitleValueStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_TOTAL_TITLE_KEY]);
    const seriesData = data.series[0];
    const showZeroValues = this._config[SHOW_ZERO_VALUES] === undefined ? true : this._config[SHOW_ZERO_VALUES];
    let translatedName: string;
    const seriesKeyName = (true === params.showSeriesName) ? data.data.name :
      (data.data.leftLabel || data.data.label || data.data.breakoutID || data.data.key);
    const keyName = data.data.leftLabel || data.data.label || data.data.breakoutID || data.data.key || data.data.name;
    const metaDataArray = data.data.meta;
    let metaData: any;
    if (params.showOtherValue) {
      metaData = metaDataArray.map((s: any) => Object.assign({}, s));
      const all = metaData.shift();
      metaData.push(all);
    } else {
      metaData = metaDataArray;
    }
    let dataFormatter: IFormatter;
    let percentWithBracketsFormatter: IFormatter;
    let percentDefaultFormatter: IFormatter;
    let salesLabel = "";
    let sharePctLabel = "";
    let variancePctLabel = "";
    let VSLabel = "";

    // Data from response.
    const dataKey = jp.query(data, params.dataKeyPath || "$")[0];
    let tooltipData: any;
    if (params.dataPath) {
      tooltipData = jp.query(data.responseData, params.dataPath.replace(/__key/g, dataKey))[0];
    }

    const hideSubtitle = null != params["hideSubtitle"] ? params["hideSubtitle"] : false;
    const hideTotalLabel = null != params["hideTotalLabel"] ? params["hideTotalLabel"] : false;

    const formatters = {};
    if (params) {
      if (params.formatter) {
        dataFormatter = this._formatterService.formatter(params.formatter);
      }
      if (params.percentFormatter) {
        percentWithBracketsFormatter = this._formatterService.formatter(params.percentFormatter);
      }
      if (params.percentDefault) {
        percentDefaultFormatter = this._formatterService.formatter(params.percentDefault);
      }
      if (params.titleTranslationKey) {
        translatedName = this._translateService.instant(params.titleTranslationKey);
      } else {
        translatedName = this._translateService.instant(this._config[LABELS][data.index]);
      }
      if (params.salesLabel) {
        salesLabel = this._translateService.instant(params.salesLabel);
      }
      if (params.shareLabel) {
        sharePctLabel = this._translateService.instant(params.shareLabel);
      }
      if (params.variancePctLabel) {
        variancePctLabel = this._translateService.instant(params.variancePctLabel);
      }
      if (params.vsLabel) {
        VSLabel = this._translateService.instant(params.vsLabel);
      }

      if (params.formatters) {
        params.formatters.forEach((formatterName: string) => {
          const formatter = this._formatterService.formatter(formatterName);
          if (null == formatters[formatterName]) {
            formatters[formatterName] = formatter;
          }
        });
      }

    }
    let popupHTML = "";
    popupHTML += `<table style="width: 95%; max-width: 420px;">
      <thead><tr style="${titleStyle}"><td colspan="6">${translatedName} Breakdown</strong></td></tr>`;
    if ((params.forceSubtitle === true) || hideSubtitle === false && metaDataArray.length > 1 && (data.data.other === undefined || data.data.other)) {
      popupHTML += `<tr style="${subtitleStyle}"><td colspan="6"> ` + keyName + `</strong></td></tr>`;
    }
    popupHTML += "</thead><tbody>";
    let titleLabels: any[] = [];
    let shortLabels: any[] = [];
    if (params.showOnlySelectedData === true) {
      const value = metaData[0].values[data.index];
      titleLabels = [value.label];
      shortLabels = [value.shortLabel];
    } else if (null != valuePaths) {
      valuePaths.forEach((valuePath: any) => {
        const value = jp.query(metaData[0], valuePath)[0];
        titleLabels.push(value.label);
        shortLabels.push(value.shortLabel);
      });
    } else {
      titleLabels = metaData[0].values.map((labelValue: any) => labelValue.label);
      shortLabels = metaData[0].values.map((labelValue: any) => labelValue.shortLabel);
    }
    const additionalMeta = metaData[0].additionalMeta.map((labelValue: any) => labelValue.label);
    const currentItemShortLabel = data.data.shortLabel;
    if (metaDataArray.length > 1 || params.forceDimensionsKeys === true) {
      popupHTML += '<tr class="store-popup-text">';
      popupHTML += `<td colspan="2" style="${rowTitleStyle}"></td>`;
      for (const title of titleLabels) {
        popupHTML += `<td colspan="${ true === params.hideShare ? 1 : 2 }" style="${columnTitleStyle}">` + title + "</td>";
      }
      if (additionalMeta.length > 0 || params.addVariancePct) {
        popupHTML += `<td style="${columnHeaderStyle}">
          ${params["use-additional-meta-labels"] === true ? additionalMeta[0] : "vs " + shortLabels.slice(-1)[0]}
        </td>`;
      }
      popupHTML += `</tr>`;

      popupHTML += '<tr class="store-popup-text">';
      popupHTML += `<td colspan="2" style="${rowTitleStyle}"></td>`;
      metaData[0].values.forEach((_: any, valueIdx: number) => {
        if (null != params.showDataIndexes && !params.showDataIndexes.includes(valueIdx)) {
          return;
        }
        if (params.showOnlySelectedData === true && data.index !== valueIdx) {
          return;
        }
        popupHTML += `<td style="${columnHeaderStyle}">${salesLabel}</td>`;
        popupHTML += `<td style="${columnHeaderStyle}">${sharePctLabel}</td>`;
      });
      if (params.addVariancePct) {
        popupHTML += `<td style="${columnHeaderStyle}">${variancePctLabel}</td>`;
      }
      if (true !== params.hideShare) {
        for (const adm of additionalMeta) {
          popupHTML += `<td style="${columnHeaderStyle}">${adm}</td>`;
        }
      }
      popupHTML += "</tr>";

      const totalAdm: number[] = [];
      const currentItemKey = data.data.key;
      metaData.forEach((meta: any, index: number) => {
        const metaValues = meta.values.map((x: any) => Object.assign({}, x));
        let dimensionName = meta.name;
        let hoveredStyle = "";
        const onlyValues = metaValues.map((labelValue: any) => labelValue.value);
        const totalValue = onlyValues.reduce((a: any, b: any) => a + b, 0);
        if (!showZeroValues && (totalValue === 0 || totalValue === null)) {
          return;
        }
        if (params.addVariancePct) {
          const variance = this._getCalculatedVariance(onlyValues[0], onlyValues[1], variancePctLabel);
          metaValues.push(variance);
        }
        let dimensionColor = meta.color;
        if (index === metaData.length - 1 && params.showOtherValue  && (data.data.other === undefined || data.data.other)) {
          dimensionName = "Other " + dimensionName;
          dimensionColor = "#bdbdbd";
        }

        if (null != params.dimensionNameTranslationSuffix) {
          dimensionName = dimensionName[params.dimensionNameTranslationSuffix];
        }

        if (meta.key === seriesData.key || (seriesData.key === "All" && meta.name === data.data.name)) {
          hoveredStyle = "border-bottom-style: solid; border-top-style: solid; border-width:1px; border-color:" + meta.color + ";";
        }
        popupHTML += '<tr class="store-popup-text" style="' + hoveredStyle + '"><td align="right" class="legend-icon legend-color-guide-area">';
        popupHTML += '<div style="background-color: ' + dimensionColor;
        popupHTML += '; margin-top:2px"></div></td><td nowrap="wrap" width="80px" style="color:#555555; text-align: left;" class="key">';
        popupHTML += dimensionName + "</td>";
        metaValues.forEach((md: any, i: number) => {
          if (null != params.showDataIndexes && !params.showDataIndexes.includes(i)) {
            return;
          }
          if (params.showOnlySelectedData === true && data.index !== i) {
            return;
          }
          const isValueToHighlight =
            (currentItemKey === meta.key || (seriesData.key === "All" && meta.name === data.data.name)) && (currentItemShortLabel === md.shortLabel);
          const valueStyling =
            isValueToHighlight
              ? highlightedValueStyle
              : valueStyle;
          if (md.formatPercent === undefined || !md.formatPercent) {
            let formattedPercentage;
            if (null == data.data.totalValues[i]) {
              formattedPercentage = "NA";
            } else {
              const percentageShare = (md.value / data.data.totalValues[i]) * 100;
              formattedPercentage = percentWithBracketsFormatter.formatData(percentageShare);
            }

            let formatter;
            if (params.formatters) {
              formatter = formatters[params.formatters[i]];
            } else if (dataFormatter) {
              formatter = dataFormatter;
            }

            const dimensionValue = null != formatter ? formatter.formatData(md.value) : md.value;
            popupHTML += `<td style="${valueStyling}">${dimensionValue}</td>`;
            if (true !== params.hideShare) {
              popupHTML += `<td style="${valueStyling}">${formattedPercentage}</td>`;
            }
          } else {
            const dimensionValue = percentDefaultFormatter ? percentDefaultFormatter.formatData(md.value) : md.value;
            popupHTML += `<td style="${valueStyling}">${dimensionValue}</td>`;
          }
        });
        // if (!data.data.pivot) {
        meta.additionalMeta.forEach((aditionalMetaData: any, admIndex: number) => {
          totalAdm[admIndex] = null != totalAdm[admIndex] ?
            totalAdm[admIndex] + aditionalMetaData.value : aditionalMetaData.value;
          const formattedAddtionalValue = percentDefaultFormatter.formatData(aditionalMetaData.value);

          popupHTML += `<td style="${valueStyle}">${formattedAddtionalValue}</td>`;
        });
        popupHTML += "</tr>";
        // }
      });
      if (true !== params.hideTotal) {
        popupHTML += `<tr>
          <td colspan="2" style="${totalTitleValueStyle}">${translatedName} ${ hideTotalLabel === false ? "Total" : "" }
          </td>`;
        shortLabels.forEach((_: any, i: number) => {
          if (null != params.showDataIndexes && !params.showDataIndexes.includes(i)) {
            return;
          }
          if (params.showOnlySelectedData === true) {
            i = data.index;
          }
          const dimensionTotalValue = dataFormatter ? dataFormatter.formatData(data.data.totalValues[i]) : data.data.totalValues[i];
          popupHTML += `<td style="${valueStyle}">${dimensionTotalValue}</td>`;
          if (true !== params.hideShare) {
            popupHTML += `<td style="${valueStyle}">${percentDefaultFormatter ? percentDefaultFormatter.formatData(100) : 100}</td>`;
          }
        });
        if (null != params.showAdditionalMetaTotal) {
          totalAdm.forEach((value: number, index: number) => {
            if (index in params.showAdditionalMetaTotal) {
              let val = value;
              let formatter = dataFormatter;
              if (typeof params.showAdditionalMetaTotal[index] === "object") {
                const {path, formatter: _f} = params.showAdditionalMetaTotal[index];
                formatter = this._formatterService.formatter(_f);
                val = jp.query(tooltipData, path)[0];
              }
              popupHTML += `<td style="${valueStyle}">${formatter ? formatter.formatData(val) : val}</td>`;
            }
          });
        }
        popupHTML += "</tr>";
      }
    } else {
        popupHTML += '<tr class="store-popup-text">';
        popupHTML += `<td colspan="2" style="${rowTitleStyle}"></td>`;
        for (const title of titleLabels) {
          popupHTML += `<td style="${columnTitleStyle}">` + title + "</td>";
        }
        if (params.addVariancePct) {
          popupHTML += `<td style="${columnHeaderStyle}">${VSLabel} ${shortLabels.slice(-1)[0]}</td>`;
        }
        if (null == valuePaths) {
          for (const adm of additionalMeta) {
            popupHTML += `<td style="${columnHeaderStyle}">${adm}</td>`;
          }
        }
        popupHTML += `</tr>`;
        // const dimensionValue = dataFormatter ? dataFormatter.formatData(data.data.totalValue) : data.data.totalValue;
        popupHTML += '<tr><td align="right" class="legend-icon legend-color-guide-area"><div style="background-color: ';
        popupHTML += (data.color || data.data.selectedColor) + '; margin-top:2px">';
        popupHTML += `</div></td><td>${seriesKeyName}</td>`;
        if (metaData[0]) {
          let metaValues;
          if (null != valuePaths) {
            metaValues = valuePaths.map((valuePath: any) => {
              return jp.query(metaData[0], valuePath)[0];
            });
          } else {
            metaValues = metaData[0].values.map((x: any) => Object.assign({}, x));
          }
          if (params.addVariancePct) {
            const mv = this._getCalculatedVariance(metaValues[0].value, metaValues[1].value, variancePctLabel);
            metaValues.push(mv);
          }
          metaValues.forEach((md: any, i: number) => {
            if (null != params.showDataIndexes && !params.showDataIndexes.includes(i)) {
              return;
            }
            if (params.showOnlySelectedData === true && data.index !== i) {
              return;
            }
            if (null != params.percentValueIndexes) {
              md.formatPercent = params.percentValueIndexes.indexOf(i) !== -1 ? true : false;
            }
            const isValueToHighlight =  (currentItemShortLabel === md.shortLabel);
            const valueStyling =
              isValueToHighlight
                ? highlightedValueStyle
                : valueStyle;
            if (!md.formatPercent) {
              let formatter;
              if (params.formatters) {
                formatter = formatters[params.formatters[i]];
              } else if (dataFormatter) {
                formatter = dataFormatter;
              }

              const dimensionValue = formatter
                ? this._isNumeric(md.value)
                  ? formatter.formatData(md.value)
                  : "NA"
                : md.value;
              popupHTML += `<td style="${valueStyling}">${dimensionValue}</td>`;
            } else {
              const dimensionValue = percentDefaultFormatter ?
                                      this._isNumeric(md.value) ?
                                        percentDefaultFormatter.formatData(md.value) :
                                          "NA" :
                                      md.value;
              popupHTML += `<td style="${valueStyling}">${dimensionValue}</td>`;
            }
          });
          if (null == valuePaths) {
            metaData[0].additionalMeta.forEach((aditionalMetaData: any, admIndex: number) => {
              const formattedAddtionalValue = percentDefaultFormatter.formatData(aditionalMetaData.value);
              popupHTML += `<td style="${valueStyle}">${formattedAddtionalValue}</td>`;
            });
          }
        } else {
          const dimensionValue = dataFormatter ? dataFormatter.formatData(data.data.totalValue) : data.data.totalValue;
          popupHTML += `<td style="${valueStyle}">${dimensionValue}</td>`;
        }
        popupHTML += `</tr>`;
    }
    popupHTML += "</tbody></table>";
    return popupHTML;
  }

  private _getStyleFromBrandSelector(brandSelector: string, key: string): string {
    const bStyle = this._brandService.styleSelector(brandSelector);
    const props: ISelectorProperties = {id: key};
    if (bStyle.styles(props)) {
      return `${OU.object2String(bStyle.styles(props), COLON, SEMI_COLON_SPACE)};`;
    }
    return "";
  }

}
