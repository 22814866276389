import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from "json2typescript";
let SeriesValues = class SeriesValues {
    constructor() {
        this.seriesID = undefined;
        this.path = undefined;
        this.type = undefined;
        this.subType = null;
        this.cumulative = false;
        this.seriesNamePath = undefined;
        this.seriesKey = undefined;
        this.includesPriorPeriods = undefined;
        this.displayPriorPeriods = undefined;
        this.axis = undefined;
    }
};
__decorate([
    JsonProperty("series-id", String, false),
    __metadata("design:type", String)
], SeriesValues.prototype, "seriesID", void 0);
__decorate([
    JsonProperty("path", String, false),
    __metadata("design:type", String)
], SeriesValues.prototype, "path", void 0);
__decorate([
    JsonProperty("type", String, true),
    __metadata("design:type", String)
], SeriesValues.prototype, "type", void 0);
__decorate([
    JsonProperty("sub-type", String, true),
    __metadata("design:type", String)
], SeriesValues.prototype, "subType", void 0);
__decorate([
    JsonProperty("cumulative", Boolean, true),
    __metadata("design:type", Boolean)
], SeriesValues.prototype, "cumulative", void 0);
__decorate([
    JsonProperty("series-name-path", String, true),
    __metadata("design:type", String)
], SeriesValues.prototype, "seriesNamePath", void 0);
__decorate([
    JsonProperty("series-key", Number, true),
    __metadata("design:type", Number)
], SeriesValues.prototype, "seriesKey", void 0);
__decorate([
    JsonProperty("includes-prior-periods", Boolean, true),
    __metadata("design:type", Boolean)
], SeriesValues.prototype, "includesPriorPeriods", void 0);
__decorate([
    JsonProperty("display-prior-periods", Boolean, true),
    __metadata("design:type", Boolean)
], SeriesValues.prototype, "displayPriorPeriods", void 0);
__decorate([
    JsonProperty("axis", Number, true),
    __metadata("design:type", Number)
], SeriesValues.prototype, "axis", void 0);
SeriesValues = __decorate([
    JsonObject("SeriesValues")
], SeriesValues);
export { SeriesValues };
