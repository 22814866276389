import { Logger } from "../logging/logger";
import { BaseSelector } from "./base-selector";
import { ISelectorProperties } from "./selector.properties.interface";
import { ISelector } from "./selector.interface";
import { BrandService } from "./brand.service";

const NO_MATCH_SELECTOR = "no-match-selector";

enum ColourProperties {
  Default = "default",
  Benchmark = "benchmark",
  PriorPeriod = "prior",
  Selected = "selected"
}

export class SeriesBasedSelector extends BaseSelector {

  private _noMatchSelector: ISelector;
  private _series: {[series: string]: object};

  public constructor(_code: string, _config: any, _brandService: BrandService, _logger: Logger) {
    super(_code, _config, _brandService, _logger);
    if (!this.config || !this.config[NO_MATCH_SELECTOR]) {
      throw new TypeError("no-match-selector property missing from brand service selector with code '" + _code + "'!");
    }

    const noMatchSelector = this.config[NO_MATCH_SELECTOR];

    try {
      this._noMatchSelector = this._brandService.seriesColourSelector(noMatchSelector);
    } catch (e) {
      // tslint:disable-next-line:max-line-length
      throw new Error("Configured no-match-selector '" + noMatchSelector + "' for brand service selector with code '" + _code + "' could not be loaded.  Underlying error follows:\n" + e);
    }
    this._series = this.config.series;
    Object.keys(this._series).forEach((colorObjKey: any) => {
      const colorObj = this._series[colorObjKey];
      this._series[colorObjKey] = typeof colorObj === "string" ? { [ColourProperties.Default]: colorObj } : colorObj;
    });

  }

  public override seriesColour(properties: ISelectorProperties): string {
    // If there isn't an ID then we can't do our thing so we return the default
    // colour.
    let ret: string;
    if (!properties || !properties.series) {
      ret = this._noMatchSelector.seriesColour(properties);
    } else {
      const seriesKey: string = properties.series;
      const colourObjKey =
        true === properties.benchmark ? ColourProperties.Benchmark :
        true === properties.selected ? ColourProperties.Selected :
        true === properties.priorPeriod ? ColourProperties.PriorPeriod :
        ColourProperties.Default;
      const colourObj: any = this._series[seriesKey];
      if (null == colourObj) {
        this._logger.debug(`No series colour object found for series '${seriesKey}'.  Falling back to no-match-selector for series colour.`);
        ret = this._noMatchSelector.seriesColour(properties);
      } else {
        ret = colourObj[colourObjKey] || colourObj[ColourProperties.Default] || this._noMatchSelector.seriesColour(properties);
      }
    }
    return ret;
  }
}
