var Timestamp_1;
import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from "json2typescript";
import * as GQL from "../util/graphql-tags";
import { json2ts } from "../util/json-2ts";
let Timestamp = Timestamp_1 = class Timestamp {
    static toUTCDate(date) {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        // This WORKS for when the date picker control returns a date with some random
        // hour.  It will take the date in the actually picked control, and it will
        // convert it to a date which represents MIDNIGHT on that date, UTC, which the browser
        // will then convert to a locale date object (so 1am on that date in BST for instance.)
        // When this is passed to other timestamp methods it will be correctly treated as the right
        // UTC date to pass in to back end queries because they use toJSON() to create the asString
        // property which turns the date into a UTC string (and hence you wind up at the right date
        // irrespective of the browser time zone.)
        // Note you MUST NOT use the getUTCXXX methods here.  If you do that the browser will use
        // whatever random hour the control assigned to the date and in some cases that will cause
        // the date to get changed to something OTHER than the user selected.
        // So we use the actual date (year, month and day) the user selected, we convert it to
        // midnight on that date IN UTC, and then we leave the browser to do it's thing since we
        // know we have set a date which is actually CORRECT now.
        // PS Javascript dates are AWFUL.
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
    }
    static now() {
        return Timestamp_1.fromDateObject(new Date(Date.now()));
    }
    now() {
        return Timestamp_1.fromDateObject(this.asDate);
    }
    static fromAsStringProperty(asString) {
        return Timestamp_1.fromDateObject(new Date(asString));
    }
    static fromDateObject(date) {
        if (!date) {
            return undefined;
        }
        return json2ts({
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
            hour: date.getHours(),
            minute: date.getMinutes(),
            second: date.getSeconds(),
            asString: date.toJSON()
        }, Timestamp_1);
    }
    static ToValidInputObject(toStrip) {
        if (null == toStrip) {
            return null;
        }
        const ret = JSON.parse(JSON.stringify(toStrip));
        if (null != ret) {
            ret[GQL.SCALAR_YEAR] = toStrip.year;
            ret[GQL.SCALAR_MONTH] = toStrip.month;
            ret[GQL.SCALAR_DAY] = toStrip.day;
            ret[GQL.SCALAR_HOUR] = toStrip.hour;
            ret[GQL.SCALAR_MINUTE] = toStrip.minute;
            ret[GQL.SCALAR_SECOND] = toStrip.second;
            ret[GQL.SCALAR_AS_STRING] = toStrip.asString;
            delete ret._year;
            delete ret._month;
            delete ret._day;
            delete ret._hour;
            delete ret._minute;
            delete ret._second;
        }
        return ret;
    }
    static compare(a, b, timestampSortOrder) {
        if (a !== null && b === null) {
            return timestampSortOrder;
        }
        else if (a === null && b !== null) {
            return -1 * timestampSortOrder;
        }
        else if (a === null && b === null) {
            return 0;
        }
        const ret = new Date(a.asString).getTime() - new Date(b.asString).getTime();
        return ret * timestampSortOrder;
    }
    get year() {
        return null != this._year ? this._year : this.asDate.getUTCFullYear();
    }
    get month() {
        return null != this._month ? this._month : this.asDate.getUTCMonth();
    }
    get day() {
        return null != this._day ? this._day : this.asDate.getUTCDay();
    }
    get hour() {
        return null != this._hour ? this._hour : this.asDate.getUTCHours();
    }
    get minute() {
        return null != this._minute ? this._minute : this.asDate.getUTCMinutes();
    }
    get second() {
        return null != this._second ? this._second : this.asDate.getUTCSeconds();
    }
    get asDate() {
        return new Date(this.asString);
    }
    constructor(dateObj = null) {
        this.asString = undefined;
        this._year = undefined;
        this._month = undefined;
        this._day = undefined;
        this._hour = undefined;
        this._minute = undefined;
        this._second = undefined;
        if (null != dateObj) {
            this.asString = dateObj.toUTCString();
            return;
        }
        this.asString = new Date(Date.now()).toUTCString();
    }
    static clone(toClone) {
        if (null == toClone) {
            return null;
        }
        return Timestamp_1.fromAsStringProperty(toClone.asString);
    }
};
__decorate([
    JsonProperty(GQL.SCALAR_AS_STRING, String, false),
    __metadata("design:type", String)
], Timestamp.prototype, "asString", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_YEAR, Number, true),
    __metadata("design:type", Number)
], Timestamp.prototype, "_year", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_MONTH, Number, true),
    __metadata("design:type", Number)
], Timestamp.prototype, "_month", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_DAY, Number, true),
    __metadata("design:type", Number)
], Timestamp.prototype, "_day", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_HOUR, Number, true),
    __metadata("design:type", Number)
], Timestamp.prototype, "_hour", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_MINUTE, Number, true),
    __metadata("design:type", Number)
], Timestamp.prototype, "_minute", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_SECOND, Number, true),
    __metadata("design:type", Number)
], Timestamp.prototype, "_second", void 0);
Timestamp = Timestamp_1 = __decorate([
    JsonObject("Timestamp"),
    __metadata("design:paramtypes", [Date])
], Timestamp);
export { Timestamp };
