import * as i0 from "@angular/core";
export class NotificationsService {
    constructor() {
        this._messenger = null;
    }
    init(_config) {
        this._config = JSON.parse(_config);
        this._initMessenger();
    }
    post(notif) {
        if (this._initMessenger()) {
            return this._messenger().post(notif);
        }
        else {
            console.error("Messenger not able to initialize at this time.  Supplied notification details were:", notif);
        }
    }
    _initMessenger() {
        if (null != this._messenger) {
            return true;
        }
        this._messenger = window.Messenger || null;
        if (null == this._messenger) {
            console.error("Messenger Not Initialized");
            return false;
        }
        this._messenger.options = this._config.options || {};
        return true;
    }
}
NotificationsService.ɵfac = function NotificationsService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || NotificationsService)(); };
NotificationsService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: NotificationsService, factory: NotificationsService.ɵfac });
