import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap } from "rxjs/operators";
import { AUTHENTICATION_COMPLETED } from "./user.actions";
import { SupportService } from "../services/support/support.service";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../services/support/support.service";
export class ZendeskEffects {
    constructor(_actions$, _supportService) {
        this._actions$ = _actions$;
        this._supportService = _supportService;
        // @Effect({ dispatch: false })
        this.navigate$ = createEffect(() => this._actions$
            .pipe(ofType(AUTHENTICATION_COMPLETED), switchMap(async (action) => {
            this._supportService.onAuthenticationComplete(action.payload);
        })), { dispatch: false });
    }
}
ZendeskEffects.ɵfac = function ZendeskEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || ZendeskEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.SupportService)); };
ZendeskEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ZendeskEffects, factory: ZendeskEffects.ɵfac });
