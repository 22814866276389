import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap } from "rxjs/operators";
import { DataService } from "../services/data/data.service";
import { Noop } from "./effects";
import { MutationSuccessful, UPDATE_TAG_VALUES } from "./mutation.actions";
import { MutationType } from "../dashboard/components/config/mutation-type.enum";
import { Timestamp } from "../model/timestamp.model";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../services/data/data.service";
const _LOG_ID = "comment.effects";
// tslint:disable-next-line:max-line-length
const _UPDATE_TAG_VALUES_GQL = `mutation updateTagValues($modifiedByID: String!, $timestamp:DateTimeInput!, $tagValuesData: String!) {
  UpdateTagValues(ModifiedBy: {id: $modifiedByID}, Timestamp: $timestamp, tagValuesData: $tagValuesData)
}
`;
export class TagEffects {
    constructor(_action$, _dataService) {
        this._action$ = _action$;
        this._dataService = _dataService;
        // @Effect()
        this.tagValuesUpdated$ = createEffect(() => this._action$
            .pipe(ofType(UPDATE_TAG_VALUES), switchMap(async (action) => {
            return this.updateTagValues(action);
        })));
    }
    async updateTagValues(action) {
        const tagUpdates = action.payload;
        // console.log("TAG UPDATES", tagUpdates);
        const tagValuesData = {
            context: {
                reference: tagUpdates.reference,
                temporal: tagUpdates.temporal
            },
            tag: {
                id: tagUpdates.tag.id,
                "value-id": tagUpdates.tagValue.id
            }
        };
        if (null != tagUpdates.dimensionPayload) {
            tagValuesData.context.dimension = tagUpdates.dimensionPayload;
        }
        // console.log("tagValuesData", tagValuesData);
        const params = {
            modifiedByID: tagUpdates.ModifiedBy.id,
            timestamp: Timestamp.ToValidInputObject(tagUpdates.Timestamp),
            tagValuesData: JSON.stringify(tagValuesData)
        };
        try {
            await this._dataService.mutate(_LOG_ID, _UPDATE_TAG_VALUES_GQL, params);
            console.log("TAGS UPDATED");
            return new MutationSuccessful([MutationType.Tag]);
        }
        catch (err) {
            console.log(err);
            console.log(`TAGS __NOT__ UPDATED!: ${err}`);
            return new Noop();
        }
    }
}
TagEffects.ɵfac = function TagEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TagEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.DataService)); };
TagEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TagEffects, factory: TagEffects.ɵfac });
