import { ErrorHandler } from "@angular/core";
import { LoggingService } from "./logging.service";
import * as i0 from "@angular/core";
import * as i1 from "./logging.service";
export class GlobalErrorHandler extends ErrorHandler {
    constructor(_loggingService) {
        super();
        this._loggingService = _loggingService;
    }
    handleError(error) {
        this._loggingService.error(error);
    }
}
GlobalErrorHandler.ɵfac = function GlobalErrorHandler_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || GlobalErrorHandler)(i0.ɵɵinject(i1.LoggingService)); };
GlobalErrorHandler.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: GlobalErrorHandler, factory: GlobalErrorHandler.ɵfac });
