import { __decorate, __metadata } from "tslib";
import * as GQL from "../util/graphql-tags";
import { JsonObject, JsonProperty } from 'json2typescript';
import { Geo } from './geo.model';
let Region = class Region {
    constructor() {
        this.id = undefined;
        this.guid = undefined;
        this.name = undefined;
        this.Geo = undefined;
    }
};
__decorate([
    JsonProperty(GQL.SCALAR_ID, String, false),
    __metadata("design:type", String)
], Region.prototype, "id", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_GUID, String, false),
    __metadata("design:type", String)
], Region.prototype, "guid", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_NAME, String, false),
    __metadata("design:type", String)
], Region.prototype, "name", void 0);
__decorate([
    JsonProperty(GQL.GEO, Geo, true),
    __metadata("design:type", Geo)
], Region.prototype, "Geo", void 0);
Region = __decorate([
    JsonObject("Region")
], Region);
export { Region };
