import { __decorate } from "tslib";
import { JsonConverter } from "json2typescript";
export var NullValueRule;
(function (NullValueRule) {
    NullValueRule["Any"] = "Any";
    NullValueRule["All"] = "All";
})(NullValueRule || (NullValueRule = {}));
let NullValueRuleConverter = class NullValueRuleConverter {
    serialize(data) {
        return data ? data.toString() : "";
    }
    deserialize(val) {
        return NullValueRule[val];
    }
};
NullValueRuleConverter = __decorate([
    JsonConverter
], NullValueRuleConverter);
export { NullValueRuleConverter };
