import * as OU from "../../util/object-utils";
import {
  ISelectorProperties
} from "../brand/selector.properties.interface";
import { TooltipBase } from "./base-tooltip";
import * as jp from 'jsonpath';

const LABELS = "group-labels";
const COLON_SPACE = ": ";
const SEMI_COLON = ";";
const TOOLTIP_POPUP_SUBTITLE_KEY = "tooltip-popup-subtitle-key";
const TOOLTIP_HIGHLIGHTED_VALUE_KEY = "tooltip-highlighted-value-key";
const BRAND_SELECTOR = "brand-selector";
const TOOLTIP_VALUE_KEY = "tooltip-value-key";
const TOOLTIP_POPUP_COLUMN_HEADING_KEY = "tooltip-popup-column-heading-key";
const TOOLTIP_BRAND_SELECTOR = "tooltip-brand-selector";
const TOOLTIP_POPUP_TITLE_KEY = "tooltip-popup-title-key";

export class WeatherHorizontalBarChartTooltip extends TooltipBase {

  public override generateTooltipContent(data: any, params?: any): string {
    this._logService.debug("Generating Weather Horizontal Bar ToolTip of data: ", {tooltipData: data, parameter: params});
    const meta = data.data.meta;
    const brandSelectorConfig = this._config[BRAND_SELECTOR];
    const tooltipStyleBrandSelector = brandSelectorConfig[TOOLTIP_BRAND_SELECTOR];
    const titleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_TITLE_KEY]);
    const subtitleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_SUBTITLE_KEY]);
    const valueStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_VALUE_KEY]);
    const highlightedValueStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_HIGHLIGHTED_VALUE_KEY]);
    const seriesData = data.series[0];
    const columnHeaderLabels = jp.query(data, '$..meta[0].values[*].label');

    let translatedName;
    if (params.titleTranslationKey) {
      translatedName = this._translateService.instant(params.titleTranslationKey);
    } else {
      translatedName = this._translateService.instant(this._config[LABELS][data.index]);
    }

    const rowSelectedStyle = 'border-bottom-style: solid; border-top-style: solid; border-width: 1px;';
    const columnHeaderStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_COLUMN_HEADING_KEY]);
    const currentItemKey = data.data.key;
    const currentItemShortLabel = data.data.shortLabel;
    const tableData = meta.map((row: any) => {
      const isRowSelected: boolean = currentItemKey === row.key || (seriesData.key === "All" && row.name === data.data.name);
      const rowStyling: string = isRowSelected ? `${rowSelectedStyle} border-color: ${row.color};` : '';
      let rowElement: string = `
      <tr style="${rowStyling}">
        <td class="legend-color-guide">
          <div style="border: none; background-color: ${row.color}"></div>
        </td>
        <td class="key">${row.name}</td>`;
      row.values.forEach((column: any) => {
        const isColumnSelected = isRowSelected && currentItemShortLabel === column.shortLabel;
        const valueStyling = isColumnSelected ? highlightedValueStyle : valueStyle;
        rowElement += `<td class="value" style="${valueStyling}">${column.value}</td>`;
      });
      rowElement += `</tr>`;
      return rowElement;
    });

    // sorting by 'order-by-series-key'
    const sortedTableData = new Array<any>(meta.length);
    params['order-by-series-key'].forEach((orderIndex: any, i: number) => {
      sortedTableData[orderIndex] = tableData[i];
    });

    const seriesKeyName = data.data.leftLabel || data.data.name || data.data.key;
    const popupHTML = `
    <table style="width: 95%; max-width: 420px; font-variant: small-caps;">
      <thead>
        <tr style="${titleStyle}"><td colspan="6">${translatedName}</strong></td></tr>
        <tr style="${subtitleStyle}"><td colspan="6">${seriesKeyName}</strong></td></tr>
      </thead>
      <tbody>
        <tr class="store-popup-text">
          <tr>
            <td></td>
            <td></td>
            ${columnHeaderLabels.map(label => `<td style="${columnHeaderStyle}">${label}</td>`).join('')}
          </tr>
          ${sortedTableData.join('')}
        </tr>
      </tbody>
    </table>`;
    return popupHTML;
  }

  private _getStyleFromBrandSelector(brandSelector: string, key: string): string {
    const bStyle = this._brandService.styleSelector(brandSelector);
    const props: ISelectorProperties = {id: key};
    return OU.object2String(bStyle.styles(props), COLON_SPACE, SEMI_COLON);
  }

}
