import { __decorate, __metadata } from "tslib";
import * as GQL from "../util/graphql-tags";
import { JsonObject, JsonProperty } from 'json2typescript';
import { Region } from './region.model';
import { Geo } from './geo.model';
import { LocationType } from './location-type.model';
let Location = class Location {
    constructor() {
        this.id = undefined;
        this.guid = undefined;
        this.name = undefined;
        this.Geo = undefined;
        this.Region = undefined;
        this.Type = undefined;
    }
};
__decorate([
    JsonProperty(GQL.SCALAR_ID, String, false),
    __metadata("design:type", String)
], Location.prototype, "id", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_GUID, String, false),
    __metadata("design:type", String)
], Location.prototype, "guid", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_NAME, String, false),
    __metadata("design:type", String)
], Location.prototype, "name", void 0);
__decorate([
    JsonProperty(GQL.GEO, Geo, true),
    __metadata("design:type", Geo)
], Location.prototype, "Geo", void 0);
__decorate([
    JsonProperty(GQL.REGION, Region, true),
    __metadata("design:type", Region)
], Location.prototype, "Region", void 0);
__decorate([
    JsonProperty(GQL.TYPE, LocationType, true),
    __metadata("design:type", LocationType)
], Location.prototype, "Type", void 0);
Location = __decorate([
    JsonObject("Location")
], Location);
export { Location };
