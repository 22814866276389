import gql from "graphql-tag";
import { Timestamp } from "../../model/timestamp.model";
import { formattedTimeString } from "../../util/utils";
import { DataService } from "../data/data.service";
import { Logger } from "../logging/logger";
import { LoggingService } from "../logging/logging.service";
import * as i0 from "@angular/core";
import * as i1 from "../data/data.service";
import * as i2 from "../logging/logging.service";
const GQL_NODE_NAME_SPEC = "Specifications";
const GQL_NODE_NAME_CONFIG = "Configuration";
const GQL_NODE_NAME_DASH = "Dashboard";
const LIST_QUERY = `
query dashboardSpecs {
  Configuration {
    Dashboard {
      Specifications {
        id
        guid
        name
        icon
        img
        imgStyle
        enabled
      }
    }
  }
}`;
const USER_NAVIGATION = "UserNavigation";
const LOG_DASHBOARD_NAVIGATION_QUERY = `mutation navigate_dashboard($timestamp:DateTimeInput!, $eventParams: String!) {
  RecordUserEvent(
    Event: Navigate
    Timestamp: $timestamp
    EventParams: $eventParams
  )
}`;
const SPEC_QUERY = `
query dashboardSpec($ids: [String!]) {
  Configuration {
    Dashboard {
      Specifications(ids: $ids) {
        id
        guid
        enabled
        title
        description
        OnStateChange {
          Activate {
            Actions
            ActivateEvent {
              Event {
                id
                guid
                year
                quarter
                month
                week
                day
              }
              aggregation
              dateType
            }
            clearEvents
          }
          Deactivate {
            Actions
            ActivateEvent {
              Event {
                id
                guid
                year
                quarter
                month
                week
                day
              }
              aggregation
              dateType
            }
            clearEvents
          }
        }
        ContainerSpecifications {
          ContextSensitive
          Groups {
            groupID
            parent
            styles
            classes
          }
          Containers {
            id
            guid
            groupID
            includeInHeader
            contextSensitive
            Title {
                name
                Values{
                  key
                  context
                  formatter
                }
              }
            Components {
              id
              guid
              type
              target
              config
            }
            Classes {
              container
              Widget {
                section
                title
                body
                header
                parent
              }
              Components {
                componentID
                values
              }
            }
            Styles {
              container
              Widget {
                section
                title
                body
                header
                parent
                downloadCsv
                close
              }
              Components {
                componentID
                values
              }
            }
            Controls {
              show
              close
              expand
              fullscreen
              reload
              settings
              downloadCsv
              info
            }
          }
        }
      }
    }
  }
}`;
const LOG_NAMESPACE = "services.dashboard";
export class DashboardService {
    constructor(_dataService, loggingService) {
        this._dataService = _dataService;
        this._logger = new Logger(LOG_NAMESPACE, loggingService);
        this._logger.info("Instantiated Dashboard Service " + formattedTimeString());
    }
    dashboardSpecifications() {
        const queryOptions = {
            query: gql `${LIST_QUERY}`
        };
        return this._dataService.runQuery("DashBoard", queryOptions).then((response) => {
            this._logger.debug("Got the response", { data: response });
            return response.data[GQL_NODE_NAME_CONFIG][GQL_NODE_NAME_DASH][GQL_NODE_NAME_SPEC]
                .filter((item) => item.enabled === true);
        });
    }
    async dashboardSpecification(dashboardID) {
        const queryOptions = {
            query: gql `${SPEC_QUERY}`,
            variables: {
                ids: [dashboardID]
            }
        };
        const response = await this._dataService.runQuery(dashboardID, queryOptions);
        this._logger.debug("Got the response for dashboard id '" + dashboardID + "'", { data: response });
        const ret = response.data[GQL_NODE_NAME_CONFIG][GQL_NODE_NAME_DASH][GQL_NODE_NAME_SPEC][0];
        return ret;
    }
    async logDashboardNavigation(prevDashboardId, currentDashboardId) {
        const eventParams = `{"from":"${prevDashboardId}","to":"${currentDashboardId}"}`;
        const timestamp = Timestamp.ToValidInputObject(Timestamp.now());
        await this._dataService.mutate(USER_NAVIGATION, LOG_DASHBOARD_NAVIGATION_QUERY, { timestamp, eventParams });
    }
}
DashboardService.ɵfac = function DashboardService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || DashboardService)(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.LoggingService)); };
DashboardService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: DashboardService, factory: DashboardService.ɵfac });
