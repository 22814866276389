import * as ReferenceActions from './reference.actions';
import * as OU from '../util/object-utils';
import { Snapshot } from '../model/snapshots/snapshot.model';

export enum ReferenceContextPropertyName {
  Location = "locationID",
  LocationType = "locationTypeID",
  Region = "regionID",
  Entity = "entityID"
}

export enum ReferenceContextObjectName {
  Location = "Location",
  LocationType = "LocationType",
  Region = "Region",
  Entity = "Entity",
  Partner = "Partner",
  Person = "Person"
}

export class ReferenceContext {

  public static ToValidInputObject(
    toStrip: ReferenceContext,
    removeFromToIfLastNext: boolean = true
  ): ReferenceContext {
    if (null == toStrip) {
      return null;
    }
    return toStrip;
  }

  public static ToValidContextObject(
    toStrip: ReferenceContext
  ): ReferenceContext {

    if (null == toStrip) {
      return null;
    }

    return toStrip;
  }

  public static isValid(context: ReferenceContext): boolean {
    return true;
  }

  locationID: string[];
  entityID: string[];
  regionID: string[];
  locationTypeID: string[];
  ActiveSnapshot: Snapshot;
  partnerID: string[];
  personID: string[];
  departmentID: string[];
}

export const initialState: ReferenceContext = {
  locationTypeID: [],
  entityID: [],
  regionID: [],
  locationID: [],
  ActiveSnapshot: null,
  partnerID: [],
  personID: [],
  departmentID: []
};

export function referenceReducer(state = initialState, action: ReferenceActions.ReferenceActions): ReferenceContext {
  switch (action.type) {

    case ReferenceActions.LOCATION_TYPES_CHANGED:
      return {
        ...state,
        locationTypeID: action.payload
      };

    case ReferenceActions.ENTITIES_CHANGED:
      return {
        ...state,
        entityID: action.payload
      };

    case ReferenceActions.REGIONS_CHANGED:
      return {
        ...state,
        regionID: action.payload,
      };

    case ReferenceActions.LOCATION_IDS_CHANGED:
      return {
        ...state,
        locationID: action.payload,
        ActiveSnapshot: null
      };
    case ReferenceActions.UPDATE_REFERENCE:
      const {toggle, referenceType} = action.payload;
      if (null != referenceType) {
        const currentReference = JSON.parse(JSON.stringify(state[referenceType]));
        const currentIndex = currentReference.indexOf(action.payload[referenceType][0]);
        if (toggle && currentIndex > -1) {
          currentReference.splice(currentIndex, 1);
          return {
            ...state,
            [referenceType]: currentReference
          };
        }
      }

      return {
        ...state,
        ...action.payload
      };
    case ReferenceActions.ACTIVE_SNAPSHOT_CHANGED:
      return {
        ...state,
        ActiveSnapshot: action.payload
      };
    case ReferenceActions.PEOPLE_CHANGED:
      return {
        ...state,
        personID: action.payload
      };
    case ReferenceActions.DEPARTMENT_CHANGED:
      return {
        ...state,
        departmentID: action.payload
      };
    case ReferenceActions.PARTNER_CHANGED:
      return {
        ...state,
        partnerID: action.payload
      };
    case ReferenceActions.REFERENCE_CONTEXT_OVERWRITE:
      return OU.copyObjectWithoutNullsOrGraphQLMetaKeys(action.context) as ReferenceContext;

    default:
      return state;
  }
}
