var BusinessDate_1;
import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from "json2typescript";
import * as GQL from "../../util/graphql-tags";
import { json2ts } from "../../util/json-2ts";
import { Order } from "../order.enum";
import { CalendarDateRange } from "./calendar-date-range.model";
import { TemporalAggregation, TemporalAggregationGranularity } from "./temporal-aggregation.enum";
import { TemporalAggregationConverter } from "../../context/config/temporal-aggregation-converters";
import { Event } from "../events/event.model";
let BusinessDate = BusinessDate_1 = class BusinessDate {
    constructor() {
        this.type = TemporalAggregation.DEFAULT;
        this.guid = undefined;
        this.year = undefined;
        this.month = undefined;
        this.quarter = undefined;
        this.week = undefined;
        this.day = undefined;
        this.hour = undefined;
        this.minute = undefined;
        this.second = undefined;
        this.CalendarDateRange = undefined;
        this.Event = undefined;
    }
    static IsValid(date) {
        if (null == date) {
            return false;
        }
        return null != date.year && !(null == date.quarter &&
            null == date.month &&
            null == date.week &&
            null == date.day &&
            null == date.hour &&
            null == date.minute &&
            null == date.second);
    }
    static ToValidInputObject(toStrip) {
        if (null == toStrip) {
            return null;
        }
        const ret = JSON.parse(JSON.stringify(toStrip));
        if (null != ret) {
            delete ret[GQL.META_TYPE_NAME];
            delete ret[GQL.SCALAR_TYPE];
            delete ret[GQL.SCALAR_GUID];
            delete ret[GQL.CALENDAR_DATE_RANGE];
            delete ret[GQL.SCALAR_AS_STRING];
            delete ret[GQL.BUSINESS_DATE_RANGE];
            if (null != ret.Event) {
                ret[GQL.EVENT] = Event.ToValidInputObject(ret.Event);
            }
        }
        return ret;
    }
    static ToValidContextObjects(toClean) {
        if (null == toClean) {
            return null;
        }
        return Object.keys(toClean).reduce((dict, curr) => {
            dict[curr] = BusinessDate_1.ToValidContextObject(toClean[curr]);
            return dict;
        }, {});
    }
    static ToValidContextObject(toClean) {
        if (null == toClean) {
            return null;
        }
        const ret = BusinessDate_1.clone(toClean);
        delete ret[GQL.BUSINESS_DATE_RANGE];
        if (GQL.EVENT in ret) {
            ret[GQL.EVENT] = Event.ToValidContextObject(ret[GQL.EVENT]);
        }
        return ret;
    }
    static dateElementChanged(newDate, oldDate) {
        let ret;
        if (undefined === oldDate) {
            return ret;
        }
        for (const toCheck of BusinessDate_1._DATE_ELEMENTS_ASC) {
            if (newDate[toCheck] && oldDate[toCheck] && newDate[toCheck] > oldDate[toCheck]) {
                ret = toCheck;
                break;
            }
        }
        return ret;
    }
    /**
     * A function which can be used to sort an array of business dates as it
     * meets the requires of Array.sort.compareFn.
     *
     * Note that this is only suitable for comparing business dates with the same
     * level of granularity.  At the moment it will NOT  determine
     * That 2016W15D3 is "greater than" 2016W15.  It will ignore elements of the
     * business date that don't exist in _both_ dates.
     *
     * Or it can easily be used to just do a one off compare of two dates.
     *
     * Will return -1 if date1 < date2 (date1 is an earlier date.)
     *
     * Will return 1 if date1 > date2 (date1 is a later date.)
     *
     * Will return 0 if date1 = date2 (date1 and date2 are the same date, to whatever
     * level of precision they contain.)
     * @param a The first of the two dates to compare.
     * @param b The second of the two dates to compare.
     */
    static compare(a, b, sortDirection = Order.Ascending) {
        if (a != null && b == null) {
            return sortDirection;
        }
        else if (a == null && b != null) {
            return -1 * sortDirection;
        }
        else if (a == null && b == null) {
            return 0;
        }
        if (null != a.guid && null != b.guid && a.guid === b.guid) {
            return 0;
        }
        let ret = 0;
        for (const part of BusinessDate_1._DATE_ELEMENTS_DESC) {
            // Always compare using an ascending sort as the end of the for loop
            // will apply the sort order logic.  If you apply it twice, guess what,
            // you didn't apply it at all!  We're both reversing the polarity...
            // We're CONFUSING the polarity! :)
            ret = BusinessDate_1._compareBusinessDateElement(a, b, part, Order.Ascending);
            if (ret !== 0) {
                // We could return the actual difference but that would be a little misleading
                // since we don't say which element caused us to make the decision
                // (year, week, month etc.)
                // So better to just return -1 or 1 depending on the sign of the result.
                ret = ret < 0 ? -1 : 1;
                // If this is sufficient to distinguish the dates, then break
                // and return the value.  Otherwise we will need to look at the next,
                // more granular date component.
                break;
            }
        }
        // Sort according to desired direction.
        return ret * sortDirection;
    }
    static equalAtLevel(a, b, compareAtLevel) {
        if (null == a || null == b) {
            return false;
        }
        const compareGranularity = TemporalAggregationGranularity[compareAtLevel];
        for (const part of BusinessDate_1._DATE_ELEMENTS_DESC) {
            const partGranularity = TemporalAggregationGranularity[part];
            if (partGranularity > compareGranularity) {
                return true;
            }
            const compare = BusinessDate_1._strictCompareBusinessDateElement(a, b, part, Order.Ascending);
            if (compare !== 0) {
                return false;
            }
        }
    }
    static equals(a, b) {
        if (null == a || null == b) {
            return false;
        }
        if (null != a.guid && null != b.guid) {
            // If they both have guids then either they're equal or they're not!
            // But if either one has no GUID then we fall back on a straight compare
            // of the date elements themselves.
            return a.guid === b.guid;
        }
        return BusinessDate_1.compare(a, b, Order.Ascending) === 0;
    }
    static clone(toClone) {
        if (null == toClone) {
            return null;
        }
        return json2ts(JSON.parse(JSON.stringify(toClone)), BusinessDate_1);
    }
    static _strictCompareBusinessDateElement(a, b, propertyName, sortDirection) {
        if (null == a || null == b) {
            return null;
        }
        else if (null != a[propertyName] && null != b[propertyName]) {
            return BusinessDate_1._compareBusinessDateElement(a, b, propertyName, sortDirection);
        }
        else {
            return null;
        }
    }
    static _compareBusinessDateElement(a, b, propertyName, sortDirection) {
        let compare = 0;
        if (null != a[propertyName] && null != b[propertyName]) {
            compare = a[propertyName] - b[propertyName];
        }
        return compare * sortDirection;
    }
};
// Date elements in order of granularity from most granular to least.
BusinessDate._DATE_ELEMENTS_ASC = [
    GQL.SCALAR_SECOND,
    GQL.SCALAR_MINUTE,
    GQL.SCALAR_HOUR,
    GQL.SCALAR_DAY,
    GQL.SCALAR_WEEK,
    GQL.SCALAR_MONTH,
    GQL.SCALAR_QUARTER,
    GQL.SCALAR_YEAR
];
// Opposite order.
BusinessDate._DATE_ELEMENTS_DESC = BusinessDate_1._DATE_ELEMENTS_ASC.slice().reverse();
__decorate([
    JsonProperty(GQL.SCALAR_TYPE, TemporalAggregationConverter, true),
    __metadata("design:type", String)
], BusinessDate.prototype, "type", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_GUID, String, true),
    __metadata("design:type", String)
], BusinessDate.prototype, "guid", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_YEAR, Number, true),
    __metadata("design:type", Number)
], BusinessDate.prototype, "year", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_MONTH, Number, true),
    __metadata("design:type", Number)
], BusinessDate.prototype, "month", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_QUARTER, Number, true),
    __metadata("design:type", Number)
], BusinessDate.prototype, "quarter", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_WEEK, Number, true),
    __metadata("design:type", Number)
], BusinessDate.prototype, "week", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_DAY, Number, true),
    __metadata("design:type", Number)
], BusinessDate.prototype, "day", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_HOUR, Number, true),
    __metadata("design:type", Number)
], BusinessDate.prototype, "hour", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_MINUTE, Number, true),
    __metadata("design:type", Number)
], BusinessDate.prototype, "minute", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_SECOND, Number, true),
    __metadata("design:type", Number)
], BusinessDate.prototype, "second", void 0);
__decorate([
    JsonProperty(GQL.CALENDAR_DATE_RANGE, CalendarDateRange, true),
    __metadata("design:type", CalendarDateRange)
], BusinessDate.prototype, "CalendarDateRange", void 0);
__decorate([
    JsonProperty(GQL.EVENT, Event, true),
    __metadata("design:type", Event)
], BusinessDate.prototype, "Event", void 0);
BusinessDate = BusinessDate_1 = __decorate([
    JsonObject("BusinessDate")
], BusinessDate);
export { BusinessDate };
