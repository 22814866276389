var Person_1;
import { __decorate, __metadata } from "tslib";
import * as GQL from "../util/graphql-tags";
import { JsonObject, JsonProperty } from "json2typescript";
import { avatarURL } from "./model.utils";
let Person = Person_1 = class Person {
    constructor() {
        this.RelatedPeople = [];
        this.DirectMinus1 = [];
        this.id = null;
        this.displayName = null;
        this.__additionalData = null;
        this.__parsedAdditionalData = null;
        this.__avatarURL = null;
        this.exists = null;
    }
    get parsedAdditionalData() {
        if (null == this.__parsedAdditionalData) {
            if (null == this.__additionalData) {
                // console.log("IS NULL", this.id);
                this.__parsedAdditionalData = {};
            }
            else {
                // console.log("PARSING", this.id)
                this.__parsedAdditionalData = JSON.parse(this.__additionalData);
            }
        }
        // console.log("RETURNING", this.id);
        return this.__parsedAdditionalData;
    }
    set parsedAdditionalData(data) {
        this.__parsedAdditionalData = JSON.parse(JSON.stringify(data));
    }
    get avatarURL() {
        if (null == this.__avatarURL) {
            this.__avatarURL = avatarURL(this.displayName, this.displayName);
        }
        return this.__avatarURL;
    }
    static clone(toClone) {
        if (null == toClone) {
            return null;
        }
        const ret = new Person_1();
        ret.id = toClone.id;
        ret.displayName = toClone.displayName;
        ret.__avatarURL = toClone.__avatarURL;
        ret.__additionalData = toClone.__additionalData;
        return ret;
    }
    static ToValidInputObject(data) {
        if (null == data) {
            return null;
        }
        return {
            id: data.id,
            name: data.displayName
        };
    }
};
__decorate([
    JsonProperty("RelatedPeople", [Person], true),
    __metadata("design:type", Array)
], Person.prototype, "RelatedPeople", void 0);
__decorate([
    JsonProperty("DirectMinus1", [Person], true),
    __metadata("design:type", Array)
], Person.prototype, "DirectMinus1", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_ID, String, true),
    __metadata("design:type", String)
], Person.prototype, "id", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_DISPLAY_NAME, String, false),
    __metadata("design:type", String)
], Person.prototype, "displayName", void 0);
__decorate([
    JsonProperty("additionalData", String, true),
    __metadata("design:type", String)
], Person.prototype, "__additionalData", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_AVATAR_URL, String, true),
    __metadata("design:type", String)
], Person.prototype, "__avatarURL", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_EXISTS, Boolean, true),
    __metadata("design:type", Boolean)
], Person.prototype, "exists", void 0);
Person = Person_1 = __decorate([
    JsonObject("Person")
], Person);
export { Person };
