var ContextConfiguration_1;
import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import { TemporalAggregation } from "../../model/business-dates/temporal-aggregation.enum";
import * as Config from "../../util/config.constants";
import { json2ts } from "../../util/json-2ts";
import { TemporalAggregationArrayConverter } from "./temporal-aggregation-converters";
const _DEFAULT_TEMPORAL_AGGREGATIONS = [
    TemporalAggregation.Day,
    TemporalAggregation.Week
];
let ContextConfiguration = ContextConfiguration_1 = class ContextConfiguration {
    constructor() {
        this.temporalAggregations = _DEFAULT_TEMPORAL_AGGREGATIONS;
        this.dimensionalDependencies = {};
    }
    static init(config) {
        ContextConfiguration_1.LOADED = json2ts(JSON.parse(config), ContextConfiguration_1);
    }
};
ContextConfiguration.DEFAULT = {
    temporalAggregations: _DEFAULT_TEMPORAL_AGGREGATIONS,
    dimensionalDependencies: {}
};
ContextConfiguration.LOADED = ContextConfiguration_1.DEFAULT;
__decorate([
    JsonProperty(Config.TEMPORAL_AGGREGATIONS, TemporalAggregationArrayConverter, true),
    __metadata("design:type", Array)
], ContextConfiguration.prototype, "temporalAggregations", void 0);
__decorate([
    JsonProperty("dimensional-dependencies", Any, true),
    __metadata("design:type", Object)
], ContextConfiguration.prototype, "dimensionalDependencies", void 0);
ContextConfiguration = ContextConfiguration_1 = __decorate([
    JsonObject("ContextConfiguration")
], ContextConfiguration);
export { ContextConfiguration };
