import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from "json2typescript";
import { NullValueRule, NullValueRuleConverter } from "./null-values-rule.enum";
let NullValues = class NullValues {
    constructor() {
        this.hide = false;
        this.valuePaths = [];
        this.rule = NullValueRule.Any;
    }
};
__decorate([
    JsonProperty("hide", Boolean, true),
    __metadata("design:type", Boolean)
], NullValues.prototype, "hide", void 0);
__decorate([
    JsonProperty("value-paths", [String], true),
    __metadata("design:type", Array)
], NullValues.prototype, "valuePaths", void 0);
__decorate([
    JsonProperty("rule", NullValueRuleConverter, true),
    __metadata("design:type", String)
], NullValues.prototype, "rule", void 0);
NullValues = __decorate([
    JsonObject("NullValues")
], NullValues);
export { NullValues };
