import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from "json2typescript";
import * as GQL from "../../util/graphql-tags";
import { SnapshotSuperType } from './snapshot-super-type.model';
let SnapshotType = class SnapshotType {
    constructor() {
        this.Type = undefined;
        this.guid = undefined;
        this.code = undefined;
        this._nameRef = undefined;
        this._name = undefined;
    }
    name(translateService) {
        if (undefined !== this._name) {
            return this._name;
        }
        else {
            return translateService.instant(this._nameRef);
        }
    }
};
__decorate([
    JsonProperty(GQL.SUPER_TYPE, SnapshotSuperType, false),
    __metadata("design:type", SnapshotSuperType)
], SnapshotType.prototype, "Type", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_GUID, String, false),
    __metadata("design:type", String)
], SnapshotType.prototype, "guid", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_CODE, String, false),
    __metadata("design:type", String)
], SnapshotType.prototype, "code", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_NAME_REF, String, false),
    __metadata("design:type", String)
], SnapshotType.prototype, "_nameRef", void 0);
__decorate([
    JsonProperty(GQL.SCALAR_NAME, String, false),
    __metadata("design:type", String)
], SnapshotType.prototype, "_name", void 0);
SnapshotType = __decorate([
    JsonObject("SnapshotType")
], SnapshotType);
export { SnapshotType };
