import * as OU from "../../util/object-utils";
import {
  ISelectorProperties
} from "../brand/selector.properties.interface";
import { TooltipBase } from "./base-tooltip";

const LABELS = "group-labels";
const _TITLE_TRANSLATION_KEY = "title-translation-key";
const COLON_SPACE = ": ";
const SEMI_COLON = ";";
const BRAND_SELECTOR = "brand-selector";
const TOOLTIP_BRAND_SELECTOR = "tooltip-brand-selector";
const TOOLTIP_POPUP_TITLE_KEY = "tooltip-popup-title-key";
const TOOLTIP_POPUP_TABLE_KEY = "tooltip-popup-table-key";

export class HorizontalBarChartTooltip extends TooltipBase {
  /*
  * Have to modify nvd3 code to tell us if the series isArea, on hover, so that
  * we can change the shape of bullet based on line type.
  */

  public override generateTooltipContent(data: any, params?: any): string {
    this._logService.debug("Generating Horizontal Bar ToolTip of data: ", {tooltipData: data, parameter: params});
    const brandSelectorConfig = this._config[BRAND_SELECTOR] || null;
    const tooltipStyleBrandSelector = null != brandSelectorConfig && brandSelectorConfig[TOOLTIP_BRAND_SELECTOR];
    const seriesData = data.series[0];
    let val =  seriesData.value;
    let translatedName;
    if (params && params.formatter) {
      const dataFormatter = this._formatterService.formatter(params.formatter);
      val = dataFormatter.formatData(val);
    }
    if (params && params.titleTranslationKey) {
      translatedName = this._translateService.instant(params.titleTranslationKey);
    } else if (this._config[_TITLE_TRANSLATION_KEY] != null) {
      translatedName = this._translateService.instant(this._config[_TITLE_TRANSLATION_KEY]);
    } else {
      translatedName = this._translateService.instant(this._config[LABELS][data.index]);
    }

    let popUpTableStyle;
    let textStyle;
    if (null != brandSelectorConfig) {
      popUpTableStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_TITLE_KEY]);
      textStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_TABLE_KEY]);
    }
    let percentValue = null != data.percent && params.showTooltipPercent === true
                          ? Math.round(data.percent * 100) : "";

    const tHead = params.showTitle ? `
                  <thead>
                    <tr style="${null != textStyle && textStyle}">
                      <td colspan="3"> ${translatedName}</td>
                    </tr>
                  </thead>
                  ` : "";

    if (null != params.formatters && params.formatters.length !== 0) {
      const dataFormatter = this._formatterService.formatter(params.formatters[0]);
      val = dataFormatter.formatData(val);

      const secondaryValueFormatter = this._formatterService.formatter(params.formatters[1]);
      percentValue = secondaryValueFormatter.formatData(percentValue);
    } else {
      percentValue = `${percentValue}%`;
    }

    const displayValues = params.switchValues === true ?
                          `${percentValue} ${this._getSecondaryValueHtml(val)}` :
                          `${val} ${this._getSecondaryValueHtml(percentValue)}`;

    let popupHTML = "";
    popupHTML += `<table style="${null != popUpTableStyle && popUpTableStyle}">`;
    popupHTML += tHead;
    popupHTML += '<tbody><tr class="store-popup-text"><td class="legend-color-guide">';
    popupHTML += '<div style="border: none; background-color: ' + seriesData.color + '"></div></td><td class="key">' + seriesData.key;
    popupHTML += `</td><td class="value">${displayValues}</td></tr></tbody></table>`;
    return popupHTML;
  }

  private _getStyleFromBrandSelector(brandSelector: string, key: string): string {
    const bStyle = this._brandService.styleSelector(brandSelector);
    const props: ISelectorProperties = {id: key};
    return OU.object2String(bStyle.styles(props), COLON_SPACE, SEMI_COLON);
  }

  private _getSecondaryValueHtml(value: string | number): string {
    return `<span style="font-weight: normal;">(${value})<span>`
  }

}
