import { __decorate, __metadata } from "tslib";
import { Any, JsonObject, JsonProperty } from "json2typescript";
let ThresholdConfig = class ThresholdConfig {
    constructor() {
        this.colour = undefined;
        this.styles = undefined;
        this.rowStyles = undefined;
        this.selectedRowStyles = undefined;
        this.selectorID = undefined;
        this.selector = undefined;
        this.value = undefined;
        this.threshold = undefined;
        this.thresholdExpression = undefined;
    }
};
__decorate([
    JsonProperty("colour", String, true),
    __metadata("design:type", String)
], ThresholdConfig.prototype, "colour", void 0);
__decorate([
    JsonProperty("styles", Any, true),
    __metadata("design:type", Object)
], ThresholdConfig.prototype, "styles", void 0);
__decorate([
    JsonProperty("row-styles", Any, true),
    __metadata("design:type", Object)
], ThresholdConfig.prototype, "rowStyles", void 0);
__decorate([
    JsonProperty("selected-row-styles", Any, true),
    __metadata("design:type", Object)
], ThresholdConfig.prototype, "selectedRowStyles", void 0);
__decorate([
    JsonProperty("selectorID", Any, true),
    __metadata("design:type", Object)
], ThresholdConfig.prototype, "selectorID", void 0);
__decorate([
    JsonProperty("selector", Any, true),
    __metadata("design:type", Object)
], ThresholdConfig.prototype, "selector", void 0);
__decorate([
    JsonProperty("value", String, true),
    __metadata("design:type", String)
], ThresholdConfig.prototype, "value", void 0);
__decorate([
    JsonProperty("threshold", Number, true),
    __metadata("design:type", Number)
], ThresholdConfig.prototype, "threshold", void 0);
__decorate([
    JsonProperty("thresholdExpression", String, true),
    __metadata("design:type", String)
], ThresholdConfig.prototype, "thresholdExpression", void 0);
ThresholdConfig = __decorate([
    JsonObject("ThresholdConfig")
], ThresholdConfig);
export { ThresholdConfig };
