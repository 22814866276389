import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import {
  DataService
} from "../services/data/data.service";
import { Noop } from "./effects";
import {
  MUTATION_SUCCESSFUL,
  MutationSuccessful
} from "./mutation.actions";
import { resetApolloClientStore } from '../services/data/apollo-client';
import { switchMap } from "rxjs/operators";

const _LOG_ID = "mutation.effects";

@Injectable()
export class MutationEffects {

  public mutationSuccessful$ = createEffect(() => this._action$
  .pipe(
    ofType(MUTATION_SUCCESSFUL),
    switchMap((action: MutationSuccessful) => {
      return this._refreshAfterMutation(action);
    })
  ));

  constructor(private _action$: Actions, private _dataService: DataService) {}

  private _refreshAfterMutation(action: MutationSuccessful): Promise<Noop> {
    this._clearClientCache();
    this._dataService.refreshAfterMutation(...action.payload);
    return Promise.resolve(new Noop());
  }

  private async _clearClientCache() {
    try {
      const resetOK = await resetApolloClientStore();
      if (resetOK) {
        console.log("APOLLO CLIENT STORE SUCCESSFULLY RESET");
      } else {
        console.log("APOLLO CLIENT STORE RESET DID NOT SUCCEED!");
      }
    } catch (err) {
      console.log(`APOLLO CLIENT STORE RESET DID NOT SUCCEED!:

      ${err}`);
      return new Noop();
    }
  }

}
