import {
  TranslateService
} from "@ngx-translate/core";

import { Logger } from "../logging/logger";
import { BaseFormatter } from "./base-formatter";
import {
  FormatterService
} from "./formatter.service";

export class FunctionBasedFormatter extends BaseFormatter {

  constructor(locale: d3.Locale, config: any, translateService: TranslateService, formatterService: FormatterService, logger: Logger) {

    super(locale, config, translateService, formatterService, logger);
    if (null == this.config) {
      // tslint:disable-next-line:max-line-length
      throw new Error("No configuration supplied for function based formatter!  Either configuration was missing or one of formatString / function option was not set!");
    }
  }

  public override formatData(datum: any): string {
    const body = this.config.function;
    const wrap = (s: any) => "{ return " + body + " };";
    const func = new Function(wrap(body));
    const value = func.call(null).call(null, datum);
    return value;
  }
}
