/* tslint:disable max-classes-per-file */

import { TemporalEffects } from "./temporal.effects";
import { DemoEffects } from "./demo.effects";
import { Action } from "@ngrx/store";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { RouterEffects } from "./router.effects";
import { CommentEffects } from "./comment.effects";
import { MutationEffects } from "./mutation.effects";
import { SnapshotEffects } from "./snapshot.effects";
import { switchMap } from "rxjs/operators";
import { NotificationEffects } from "./notification.effects";
import { ZendeskEffects } from "./zendesk-effects";
import { UXEffects } from "./ux.effects";
import { TagEffects } from "./tag.effects";
import { UserEffects } from "./user.effects";
import { TaskEffects } from "./task.effects";
import { PersonEffects } from "./person.effects";

export const NOOP = "NOOP";

export class Noop implements Action {
  public readonly type = NOOP;
}

@Injectable()
export class NoopEffects {

  // @Effect({dispatch: false})
  public noopEffect$ = createEffect(() => this._action$.pipe(
    ofType(NOOP),
    switchMap(() => of(null))
  ), { dispatch: false });

  constructor(private _action$: Actions) {}
}

export const Effects: any = [
  TemporalEffects,
  DemoEffects,
  NoopEffects,
  RouterEffects,
  CommentEffects,
  TaskEffects,
  SnapshotEffects,
  MutationEffects,
  NotificationEffects,
  ZendeskEffects,
  UXEffects,
  TagEffects,
  UserEffects,
  PersonEffects
];

/* tslint:enable max-classes-per-file */
