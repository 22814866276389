import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from 'json2typescript';
let Geo = class Geo {
    constructor() {
        this.lat = undefined;
        this.lng = undefined;
        this.zoom = undefined;
    }
};
__decorate([
    JsonProperty("lat", Number, false),
    __metadata("design:type", Number)
], Geo.prototype, "lat", void 0);
__decorate([
    JsonProperty("lng", Number, false),
    __metadata("design:type", Number)
], Geo.prototype, "lng", void 0);
__decorate([
    JsonProperty("zoom", Number, true),
    __metadata("design:type", Number)
], Geo.prototype, "zoom", void 0);
Geo = __decorate([
    JsonObject("Geo")
], Geo);
export { Geo };
