import { __decorate, __metadata } from "tslib";
import { Any, JsonObject, JsonProperty } from "json2typescript";
import { DataRegistrationConfig } from "../../../config/data-registration-config";
import { Series } from "../../../config/series";
import { NullValues } from "../../../tables/config/null-values";
let CSVExporterConfig = class CSVExporterConfig {
    constructor() {
        this.id = undefined;
        this.fileName = undefined;
        this.brandSelector = undefined;
        this.labelValue = undefined;
        this.toggleAlertSidebar = false;
        this.checkData = null;
        this.dataValues = false;
        this.icon = undefined;
        this.series = undefined;
        this.seriesGroups = null;
        this.headers = null;
        this.replacements = null;
        this.rowsExpressions = null;
        this.rows = [];
        this.columns = [];
        this.nullValues = undefined;
        this.loopQuery = undefined;
        this.errorNotification = undefined;
        this.dataRegistrationConfig = null;
    }
};
__decorate([
    JsonProperty("id", String, true),
    __metadata("design:type", String)
], CSVExporterConfig.prototype, "id", void 0);
__decorate([
    JsonProperty("file-name", String, true),
    __metadata("design:type", String)
], CSVExporterConfig.prototype, "fileName", void 0);
__decorate([
    JsonProperty("brand-selector", String, true),
    __metadata("design:type", String)
], CSVExporterConfig.prototype, "brandSelector", void 0);
__decorate([
    JsonProperty("label-value", String, true),
    __metadata("design:type", String)
], CSVExporterConfig.prototype, "labelValue", void 0);
__decorate([
    JsonProperty("toggle-alert-sidebar", Boolean, true),
    __metadata("design:type", Boolean)
], CSVExporterConfig.prototype, "toggleAlertSidebar", void 0);
__decorate([
    JsonProperty("check-data", Any, true),
    __metadata("design:type", Object)
], CSVExporterConfig.prototype, "checkData", void 0);
__decorate([
    JsonProperty("data-values", Any, true),
    __metadata("design:type", Object)
], CSVExporterConfig.prototype, "dataValues", void 0);
__decorate([
    JsonProperty("icon", String, true),
    __metadata("design:type", String)
], CSVExporterConfig.prototype, "icon", void 0);
__decorate([
    JsonProperty("series", [Series], true),
    __metadata("design:type", Array)
], CSVExporterConfig.prototype, "series", void 0);
__decorate([
    JsonProperty("series-groups", Any, true),
    __metadata("design:type", Object)
], CSVExporterConfig.prototype, "seriesGroups", void 0);
__decorate([
    JsonProperty("headers", [Any], true),
    __metadata("design:type", Array)
], CSVExporterConfig.prototype, "headers", void 0);
__decorate([
    JsonProperty("replacements", [Any], true),
    __metadata("design:type", Array)
], CSVExporterConfig.prototype, "replacements", void 0);
__decorate([
    JsonProperty("rows-expressions", Any, true),
    __metadata("design:type", Object)
], CSVExporterConfig.prototype, "rowsExpressions", void 0);
__decorate([
    JsonProperty("rows", Any, true),
    __metadata("design:type", Object)
], CSVExporterConfig.prototype, "rows", void 0);
__decorate([
    JsonProperty("columns", Any, true),
    __metadata("design:type", Object)
], CSVExporterConfig.prototype, "columns", void 0);
__decorate([
    JsonProperty("null-values", NullValues, true),
    __metadata("design:type", NullValues)
], CSVExporterConfig.prototype, "nullValues", void 0);
__decorate([
    JsonProperty("loop-query", Any, true),
    __metadata("design:type", Object)
], CSVExporterConfig.prototype, "loopQuery", void 0);
__decorate([
    JsonProperty("error-notification", Any, true),
    __metadata("design:type", Object)
], CSVExporterConfig.prototype, "errorNotification", void 0);
__decorate([
    JsonProperty("data-registration", DataRegistrationConfig, true),
    __metadata("design:type", DataRegistrationConfig)
], CSVExporterConfig.prototype, "dataRegistrationConfig", void 0);
CSVExporterConfig = __decorate([
    JsonObject("CSVExporterConfig")
], CSVExporterConfig);
export { CSVExporterConfig };
