import {
  JsonObject,
  JsonProperty
} from "json2typescript";

import * as GQL from "../../util/graphql-tags";
import { Order } from "../order.enum";

const _OFFSET_ELEMENTS = [
  GQL.SCALAR_YEARS,
  GQL.SCALAR_QUARTERS,
  GQL.SCALAR_MONTHS,
  GQL.SCALAR_WEEKS,
  GQL.SCALAR_DAYS,
  GQL.SCALAR_HOURS,
  GQL.SCALAR_MINUTES,
  GQL.SCALAR_SECONDS
];

@JsonObject("BusinessDateOffset")
export class BusinessDateOffset {

  // Date offset elements in order of granularity from most granular to least.
  public static readonly DATE_OFFSET_ELEMENTS_ASC: string[] = ["seconds", "minutes", "hours", "days", "weeks", "months", "quarters", "years"];

  // Opposite order.
  public static readonly DATE_OFFSET_ELEMENTS_DESC: string[] = BusinessDateOffset.DATE_OFFSET_ELEMENTS_ASC.slice().reverse();

  public static IsValidOffset(offset: BusinessDateOffset): boolean {

    if (null == offset) {
      return false;
    } else if (Object.keys(offset).length === 0) {
      return false;
    } else {
      return true;
    }

  }

  /**
   * This function tells you if an offset is to the future.
   * It it is not (returns false) then it is in the past.
   */
  public static isFuture(toCheck: BusinessDateOffset): boolean {
    // Go from the least granular down as it's more likely we'll find a value quickly that way.
    for (const part of BusinessDateOffset.DATE_OFFSET_ELEMENTS_DESC) {
      if (toCheck[part] !== undefined) {
        if (toCheck[part] !== 0) {
          return toCheck[part] > 0;
        }
      }
    }
    return false;
  }

  public static compare(a: BusinessDateOffset, b: BusinessDateOffset, sortOrder: Order = Order.Ascending): number {
    if (null == b && null != a) {
      // Something comes before nothing (unless sorting backwards).
      return 1 * sortOrder;
    } else if (null != b && null == a) {
      // Something comes before nothing unless sorting backwards.
      return -1 * sortOrder;
    } else if ( null == a && null == b) {
      return 0;
    }

    for (const element of _OFFSET_ELEMENTS) {
      const compare = BusinessDateOffset._compareOffsetElement(a[element], b[element], sortOrder);
      if (0 !== compare) {
        return compare;
      }
    }
    return 0;

  }

  public static equals(a: BusinessDateOffset, b: BusinessDateOffset): boolean {
    if (null == a || null == b) {
      return false;
    } else {
      return BusinessDateOffset.compare(a, b, Order.Ascending) === 0;
    }
  }

  /**
   * This will return 0 if either element is null or they are both equal and
   * either a negative or positive number in all other cases, based on the values
   * supplied and the sortOrder requested.
   * @param a First element to compare
   * @param b Second element to compare
   * @param sortOrder The order to sort them in (a-b or b-a)
   */
  private static _compareOffsetElement(a: number, b: number, sortOrder: Order): number {
    let compare: number = 0;
    if (null != a && null != b) {
      compare = a - b;
    }

    return compare * sortOrder;
  }

  @JsonProperty(GQL.SCALAR_YEARS, Number, true)
  public years?: number = undefined;

  @JsonProperty(GQL.SCALAR_QUARTERS, Number, true)
  public quarters?: number = undefined;

  @JsonProperty(GQL.SCALAR_MONTHS, Number, true)
  public months?: number = undefined;

  @JsonProperty(GQL.SCALAR_WEEKS, Number, true)
  public weeks?: number = undefined;

  @JsonProperty(GQL.SCALAR_DAYS, Number, true)
  public days?: number = undefined;

  @JsonProperty(GQL.SCALAR_HOURS, Number, true)
  public hours?: number = undefined;

}
