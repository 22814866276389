import { BaseSelector } from "./base-selector";
import {
  ISelectorProperties
} from "./selector.properties.interface";

export class ValueBasedStyleSelector extends BaseSelector {

  public override styles(properties: ISelectorProperties): {[type: string]: any} {
    if (!properties || !properties.value) {
      // Can't do anything so just return an empty array of styles.
      return [];
    }
    const lookupKey = properties.value;
    const tStyle = this.config[lookupKey];
    if (null != tStyle) {
      return tStyle[properties.type];
    }

    return this.config.default[properties.type];
  }
}
