import { JsonObject, JsonProperty, Any } from "json2typescript";

@JsonObject("SelectorConfig")
export class SelectorConfig {
  @JsonProperty("token", String, false)
  public token: string = undefined;

  @JsonProperty("check-path", String, true)
  public checkPath: string = undefined;

  @JsonProperty("path", String, false)
  public path: string = undefined;

  @JsonProperty("sub-path", String, true)
  public subPath: string = "";

  @JsonProperty("default", Any, true)
  public default: any = null;

  @JsonProperty("value", Any, true)
  public value: any = null;

  @JsonProperty("function", String, true)
  public function: string = null;

  @JsonProperty("include-prior-periods", Boolean, true)
  public includePriorPeriods: boolean = false;

  @JsonProperty("period-type", String, true)
  public periodType: string = "YearAgoWeek";

  @JsonProperty("path-index", Number, true)
  public pathIndex: number = 0;

  @JsonProperty("mappings", Any, true)
  public mappings: any = null;
}
