import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import { switchMap, tap } from "rxjs/operators";

import { AppRoutes } from "../approutes.enum";
import {
  LayoutRoutes
} from "../layout/layoutroutes.enum";
import {
  EMPTY,
  URL_SEP
} from "../util/string-constants";
import { Noop } from "./effects";
import * as RouterActions from "./router.actions";
import {
  ActiveRouteChanged
} from "./user.actions";


@Injectable()
export class RouterEffects {


  // @Effect({ dispatch: true })
  public navigate$ = createEffect(() => this._actions$
  .pipe(
    ofType(RouterActions.GO),
    switchMap(async (action: RouterActions.Go) => {
      const path = action.payload.path;
      const queryParams = action.payload.query;
      const options = action.payload.options;
      const success = await this._router.navigate(path, { queryParams, ...options });
      if (success) {
        return new ActiveRouteChanged(this._activeRouteFullPath(path));
      } else {
        return new Noop();
      }
    })
  ), { dispatch: true });

  // @Effect({dispatch: true})
  public changeDashboard$ = createEffect(() => this._actions$
  .pipe(
    ofType(RouterActions.CHANGE_DASHBOARD),
    switchMap(async (action: RouterActions.ChangeDashboard) => {
      const routerAction = new RouterActions.Go(
        {
          path: [AppRoutes.Application, LayoutRoutes.Dashboard, action.payload.dashboard],
          options: { skipLocationChange: false }
        }
      );
      return routerAction;
    })
  ), { dispatch: true });

  // @Effect({ dispatch: false })
  public navigateBack$ = createEffect(() => this._actions$
  .pipe(
    ofType(RouterActions.BACK),
    tap(() => this._location.back())
  ), { dispatch: false });

  // @Effect({ dispatch: false })
  public navigateForward$ = createEffect(() => this._actions$
  .pipe(
    ofType(RouterActions.FORWARD),
    tap(() => this._location.forward())
  ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _router: Router,
    private _location: Location
  ) {}

  // Use the below constructor when you want to demonstrate and log that you get initialised and every action to receive.
  // constructor(private _actions$: Actions, private _router: Router, private _location: Location) {
  //   console.log('RouterEffects initialized:', this._actions$);
  //   this._actions$.subscribe((action) => {
  //     console.log('Actions stream received:', action); // Logs every action received by the stream
  //   });
  // }

  private _activeRouteFullPath(path: any[]): string {
    return path.reduce((assembledPath: string, currPathElement: string) => {
      let sep: string = URL_SEP;
      if (currPathElement.startsWith(URL_SEP)) {
        sep = EMPTY;
      }
      return assembledPath + sep + currPathElement;
    }, EMPTY);
  }
}
