var Selectors_1;
import { __decorate, __metadata } from "tslib";
import { JsonConverter, JsonObject, JsonProperty } from "json2typescript";
import { Any } from "json2typescript";
import { fromReferenceSelectors, toReferenceSelectors } from "../../../context/reference-selector.enum";
import { SelectorConfig } from "./selector-config";
let ReferenceSelectorConverter = class ReferenceSelectorConverter {
    serialize(selectors) {
        return fromReferenceSelectors(selectors);
    }
    deserialize(selectors) {
        return toReferenceSelectors(selectors);
    }
};
ReferenceSelectorConverter = __decorate([
    JsonConverter
], ReferenceSelectorConverter);
// tslint:disable-next-line:max-classes-per-file
let Selectors = Selectors_1 = class Selectors {
    constructor() {
        this.reference = [];
        this.allowEmptyContext = false;
        this.temporalMappings = undefined;
        this._temporal = null;
        this._referencePaths = null;
        this._dimension = null;
        this._ux = null;
    }
    // The logic in the data registrations expects these arrays (temporal, dimension, ux) to ONLY be specified if they are populated.
    // So there was some unexpected behaviour with empty arrays.
    // Hence, rather than forever put (if xx && xx.length > 0) I've internalized it so that an empty array
    // is semantically equivalent to a null value.
    get temporal() {
        return this._temporal && this._temporal.length > 0 ? this._temporal : null;
    }
    set temporal(val) {
        this._temporal = val;
    }
    get referencePaths() {
        return this._referencePaths && this._referencePaths.length > 0 ? this._referencePaths : null;
    }
    set referencePaths(val) {
        this._referencePaths = val;
    }
    get dimension() {
        return this._dimension && this._dimension.length > 0 ? this._dimension : null;
    }
    set dimension(val) {
        this._dimension = val;
    }
    get ux() {
        return this._ux && this._ux.length > 0 ? this._ux : null;
    }
    set ux(val) {
        this._ux = val;
    }
};
Selectors.DEFAULT = new Selectors_1();
__decorate([
    JsonProperty("reference", ReferenceSelectorConverter, true),
    __metadata("design:type", Array)
], Selectors.prototype, "reference", void 0);
__decorate([
    JsonProperty("allow-empty-context", Boolean, true),
    __metadata("design:type", Boolean)
], Selectors.prototype, "allowEmptyContext", void 0);
__decorate([
    JsonProperty("temporal-mappings", Any, true),
    __metadata("design:type", Object)
], Selectors.prototype, "temporalMappings", void 0);
__decorate([
    JsonProperty("temporal", [SelectorConfig], true),
    __metadata("design:type", Array)
], Selectors.prototype, "_temporal", void 0);
__decorate([
    JsonProperty("reference-paths", [SelectorConfig], true),
    __metadata("design:type", Array)
], Selectors.prototype, "_referencePaths", void 0);
__decorate([
    JsonProperty("dimension", [SelectorConfig], true),
    __metadata("design:type", Array)
], Selectors.prototype, "_dimension", void 0);
__decorate([
    JsonProperty("ux", [SelectorConfig], true),
    __metadata("design:type", Array)
], Selectors.prototype, "_ux", void 0);
Selectors = Selectors_1 = __decorate([
    JsonObject("Selectors")
], Selectors);
export { Selectors };
