import { Level } from "../../../packages/ngx-toolkit/logger/level.model";
import { ConsoleLoggerService } from "../../../packages/ngx-toolkit/logger/console-logger.service";
import * as i0 from "@angular/core";
const _DEFAULT_LOG_LEVEL = Level.WARN;
export class LoggingService extends ConsoleLoggerService {
    constructor() {
        super(_DEFAULT_LOG_LEVEL);
        this._logLevel = _DEFAULT_LOG_LEVEL;
    }
    info(message, ...optionalParams) {
        if (this._shouldLog(Level.INFO)) {
            super.info(message, ...optionalParams);
        }
    }
    debug(message, ...optionalParams) {
        if (this._shouldLog(Level.DEBUG)) {
            super.debug(message, ...optionalParams);
        }
    }
    warn(message, ...optionalParams) {
        if (this._shouldLog(Level.WARN)) {
            super.warn(message, ...optionalParams);
        }
    }
    error(message, ...optionalParams) {
        if (this._shouldLog(Level.ERROR)) {
            super.error(message, ...optionalParams);
        }
    }
    log(message, ...optionalParams) {
        if (this._shouldLog(Level.LOG)) {
            super.log(message, ...optionalParams);
        }
    }
    _shouldLog(level) {
        return level <= this._logLevel;
    }
}
LoggingService.ɵfac = function LoggingService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || LoggingService)(); };
LoggingService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: LoggingService, factory: LoggingService.ɵfac });
