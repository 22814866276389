import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap } from "rxjs/operators";
import { DataService } from "../services/data/data.service";
import { Logger } from "../services/logging/logger";
import { LoggingService } from "../services/logging/logging.service";
import * as fromUser from "./user.actions";
import { Timestamp } from "../model/timestamp.model";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../services/logging/logging.service";
import * as i3 from "../services/data/data.service";
const LOG_NAMESPACE = "effects.user";
const LOGOUT_MUTATION = "mutation logout($timestamp: DateTimeInput!) { RecordUserEvent(Event: Logout, Timestamp: $timestamp)  }";
export class UserEffects {
    constructor(_action$, loggingService, _dataService) {
        this._action$ = _action$;
        this._dataService = _dataService;
        // @Effect({dispatch: false})
        this.logout$ = createEffect(() => this._action$
            .pipe(ofType(fromUser.USER_LOGGED_OUT), switchMap(async (action) => {
            const timestamp = Timestamp.ToValidInputObject(Timestamp.now());
            try {
                await this._dataService.mutate(LOG_NAMESPACE, LOGOUT_MUTATION, { timestamp });
            }
            catch (e) {
                console.error(e);
                this._logger.error("Error recording user logout:", e);
                // Don't re-raise, user is logging out anyway.
            }
        })), { dispatch: false });
        this._logger = new Logger(LOG_NAMESPACE, loggingService);
    }
}
UserEffects.ɵfac = function UserEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || UserEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.LoggingService), i0.ɵɵinject(i3.DataService)); };
UserEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UserEffects, factory: UserEffects.ɵfac });
