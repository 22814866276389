import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap, tap } from "rxjs/operators";
import { AppRoutes } from "../approutes.enum";
import { LayoutRoutes } from "../layout/layoutroutes.enum";
import { EMPTY, URL_SEP } from "../util/string-constants";
import { Noop } from "./effects";
import * as RouterActions from "./router.actions";
import { ActiveRouteChanged } from "./user.actions";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
export class RouterEffects {
    constructor(_actions$, _router, _location) {
        this._actions$ = _actions$;
        this._router = _router;
        this._location = _location;
        // @Effect({ dispatch: true })
        this.navigate$ = createEffect(() => this._actions$
            .pipe(ofType(RouterActions.GO), switchMap(async (action) => {
            const path = action.payload.path;
            const queryParams = action.payload.query;
            const options = action.payload.options;
            const success = await this._router.navigate(path, { queryParams, ...options });
            if (success) {
                return new ActiveRouteChanged(this._activeRouteFullPath(path));
            }
            else {
                return new Noop();
            }
        })), { dispatch: true });
        // @Effect({dispatch: true})
        this.changeDashboard$ = createEffect(() => this._actions$
            .pipe(ofType(RouterActions.CHANGE_DASHBOARD), switchMap(async (action) => {
            const routerAction = new RouterActions.Go({
                path: [AppRoutes.Application, LayoutRoutes.Dashboard, action.payload.dashboard],
                options: { skipLocationChange: false }
            });
            return routerAction;
        })), { dispatch: true });
        // @Effect({ dispatch: false })
        this.navigateBack$ = createEffect(() => this._actions$
            .pipe(ofType(RouterActions.BACK), tap(() => this._location.back())), { dispatch: false });
        // @Effect({ dispatch: false })
        this.navigateForward$ = createEffect(() => this._actions$
            .pipe(ofType(RouterActions.FORWARD), tap(() => this._location.forward())), { dispatch: false });
    }
    // Use the below constructor when you want to demonstrate and log that you get initialised and every action to receive.
    // constructor(private _actions$: Actions, private _router: Router, private _location: Location) {
    //   console.log('RouterEffects initialized:', this._actions$);
    //   this._actions$.subscribe((action) => {
    //     console.log('Actions stream received:', action); // Logs every action received by the stream
    //   });
    // }
    _activeRouteFullPath(path) {
        return path.reduce((assembledPath, currPathElement) => {
            let sep = URL_SEP;
            if (currPathElement.startsWith(URL_SEP)) {
                sep = EMPTY;
            }
            return assembledPath + sep + currPathElement;
        }, EMPTY);
    }
}
RouterEffects.ɵfac = function RouterEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || RouterEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location)); };
RouterEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: RouterEffects, factory: RouterEffects.ɵfac });
