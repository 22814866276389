import {
  JsonObject,
  JsonProperty
} from "json2typescript";
import { TranslateService } from "@ngx-translate/core";

import * as GQL from "../../util/graphql-tags";

@JsonObject("SnapshotSuperType")
export class SnapshotSuperType {

  @JsonProperty(GQL.SCALAR_GUID, String, false)
  public guid: string = undefined;

  @JsonProperty(GQL.SCALAR_CODE, String, false)
  public code: string = undefined;

  @JsonProperty(GQL.SCALAR_NAME_REF, String, false)
  private _nameRef: string = undefined;

  public name(translateService: TranslateService, nameType: string) {
    return translateService.instant(this._nameRef + nameType);
  }

}
