import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty, Any } from "json2typescript";
import * as constants from "../../../util/string-constants";
import { SeriesValues } from "./series-values";
let Series = class Series {
    constructor() {
        this.id = undefined;
        this.axis = undefined;
        this.referenceThresholdSeriesIndex = undefined;
        this.displayAxisIfNoData = true;
        this.values = undefined;
        this.color = undefined;
        this.type = undefined;
        this.benchmark = false;
        // This should be CSS class that will be applied on Series
        this.cssClass = undefined;
        this.seriesKey = undefined;
        this.tooltipCSSClass = undefined;
        // This should be set to NAME, TINYNAME or LONGNAME
        this.name = "NAME";
        this.group = undefined;
        this.parentSeriesID = undefined;
        this.priorPeriod = false;
        // If we don't want to take the default name that is given for series,
        // We can override it by this config key, by providing the full path of the alternative name
        this.seriesNamePath = undefined;
        // Adding _seriesID to uniquly identify series
        // Id will remain same for Sales and Sales Bmk
        this.seriesID = undefined;
        this.Aggregation = undefined;
        this.Coalesce = undefined;
    }
    static SeriesDefinitions(seriesConfig, ignoreCoalesce = false) {
        const seriesQueryDefs = [];
        const seriesDef = {};
        if (seriesConfig.series !== undefined) {
            seriesConfig.series.forEach((seriesEle) => {
                // tslint:disable-next-line:max-line-length
                const bmk = seriesEle[constants.BENCHMARK] != null ? seriesEle[constants.BENCHMARK] : false;
                const seriesAgg = seriesEle[constants.AGGREGATION];
                const customSerAggID = seriesAgg != null && seriesAgg[constants.CUSTOM_ID] != null ? `,customID: ${seriesAgg[constants.CUSTOM_ID]}` : '';
                const aggregation = seriesAgg != null ? `, Aggregation: {Type: ${seriesAgg['Type']}${customSerAggID}}` : '';
                let coalesce = '';
                if (seriesEle[constants.COALESCE] != null && !ignoreCoalesce) {
                    const coalesceSeriesConfig = {
                        series: seriesEle[constants.COALESCE]
                    };
                    const coalescedSeriesElements = this.SeriesDefinitions(coalesceSeriesConfig, true);
                    coalesce = `, Coalesce:[${coalescedSeriesElements[0].series}]`;
                }
                let seriesDefinition = `{id: "${seriesEle[constants.ID]}", benchmark: ${bmk}${aggregation}${coalesce}}`;
                if (seriesConfig.seriesGroups != null) {
                    const group = seriesEle[constants.GROUP];
                    seriesDef[group] = seriesDef[group] ? seriesDef[group] + constants.COMMA_SPACE + seriesDefinition : seriesDefinition;
                }
                else {
                    // tslint:disable-next-line:max-line-length
                    seriesDef[constants.DEFAULT_SERIES_GROUP] = seriesDef[constants.DEFAULT_SERIES_GROUP] ? seriesDef[constants.DEFAULT_SERIES_GROUP] + constants.COMMA_SPACE + seriesDefinition : seriesDefinition;
                }
            });
            if (seriesConfig.seriesGroups) {
                for (const key of Object.keys(seriesConfig.seriesGroups)) {
                    seriesQueryDefs.push({
                        regex: new RegExp(seriesConfig.seriesGroups[key][constants.SERIES_DEFINITION_REGEX], constants.REGEX_GLOBAL_MATCH),
                        series: seriesDef[key]
                    });
                }
            }
            else {
                seriesQueryDefs.push({
                    regex: new RegExp(constants.SERIES_DEFINITION_PLACEHOLDER, constants.REGEX_GLOBAL_MATCH),
                    series: seriesDef[constants.DEFAULT_SERIES_GROUP]
                });
            }
        }
        return seriesQueryDefs;
    }
    get referenceSeriesID() {
        if (undefined === this.seriesID) {
            return this.id;
        }
        return this.seriesID;
    }
    /*
    * Since series id is optional, we can use this getter
    * function to get the sid if given else its always the
    * combination of series id and its benchmark type.
    */
    get IDWithBenchmarkSuffix() {
        if (this.seriesID !== undefined) {
            return this.seriesID;
        }
        else {
            return this.id + (this.benchmark ? "-bmk" : "");
        }
    }
};
__decorate([
    JsonProperty("id", String, false),
    __metadata("design:type", String)
], Series.prototype, "id", void 0);
__decorate([
    JsonProperty("axis", Number, true),
    __metadata("design:type", Number)
], Series.prototype, "axis", void 0);
__decorate([
    JsonProperty("reference-threshold-series-index", Number, true),
    __metadata("design:type", Number)
], Series.prototype, "referenceThresholdSeriesIndex", void 0);
__decorate([
    JsonProperty("display-axis-if-no-data", Boolean, true),
    __metadata("design:type", Boolean)
], Series.prototype, "displayAxisIfNoData", void 0);
__decorate([
    JsonProperty("values", [SeriesValues], true),
    __metadata("design:type", Array)
], Series.prototype, "values", void 0);
__decorate([
    JsonProperty("color", String, true),
    __metadata("design:type", String)
], Series.prototype, "color", void 0);
__decorate([
    JsonProperty("type", String, true),
    __metadata("design:type", String)
], Series.prototype, "type", void 0);
__decorate([
    JsonProperty("benchmark", Boolean, true),
    __metadata("design:type", Boolean)
], Series.prototype, "benchmark", void 0);
__decorate([
    JsonProperty("cssClass", String, true),
    __metadata("design:type", String)
], Series.prototype, "cssClass", void 0);
__decorate([
    JsonProperty("series-key", Number, true),
    __metadata("design:type", Number)
], Series.prototype, "seriesKey", void 0);
__decorate([
    JsonProperty("tooltip-css-class", String, true),
    __metadata("design:type", String)
], Series.prototype, "tooltipCSSClass", void 0);
__decorate([
    JsonProperty("name", String, false),
    __metadata("design:type", String)
], Series.prototype, "name", void 0);
__decorate([
    JsonProperty("group", String, true),
    __metadata("design:type", String)
], Series.prototype, "group", void 0);
__decorate([
    JsonProperty("parent-series-id", String, true),
    __metadata("design:type", String)
], Series.prototype, "parentSeriesID", void 0);
__decorate([
    JsonProperty("prior-period", Boolean, true),
    __metadata("design:type", Boolean)
], Series.prototype, "priorPeriod", void 0);
__decorate([
    JsonProperty("series-name-path", String, true),
    __metadata("design:type", String)
], Series.prototype, "seriesNamePath", void 0);
__decorate([
    JsonProperty("series-id", String, true),
    __metadata("design:type", String)
], Series.prototype, "seriesID", void 0);
__decorate([
    JsonProperty("Aggregation", Any, true),
    __metadata("design:type", Object)
], Series.prototype, "Aggregation", void 0);
__decorate([
    JsonProperty("Coalesce", Any, true),
    __metadata("design:type", Object)
], Series.prototype, "Coalesce", void 0);
Series = __decorate([
    JsonObject("Series")
], Series);
export { Series };
