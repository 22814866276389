import { JsonObject, JsonProperty } from "json2typescript";
import { NullValueRule, NullValueRuleConverter } from "./null-values-rule.enum";

@JsonObject("NullValues")
export class NullValues {

  @JsonProperty("hide", Boolean, true)
  public hide: boolean = false;

  @JsonProperty("value-paths", [String], true)
  public valuePaths: string[] = [];

  @JsonProperty("rule", NullValueRuleConverter, true)
  public rule: NullValueRule = NullValueRule.Any;

}
