import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty, Any } from "json2typescript";
let ZendeskSupportWidgetConfiguration = class ZendeskSupportWidgetConfiguration {
    constructor() {
        this.accountURL = null;
        this.widgetConfig = null;
    }
};
__decorate([
    JsonProperty("account-url", String, false),
    __metadata("design:type", String)
], ZendeskSupportWidgetConfiguration.prototype, "accountURL", void 0);
__decorate([
    JsonProperty("widget-config", Any, false),
    __metadata("design:type", Object)
], ZendeskSupportWidgetConfiguration.prototype, "widgetConfig", void 0);
ZendeskSupportWidgetConfiguration = __decorate([
    JsonObject("ZendeskSupportWidgetConfiguration")
], ZendeskSupportWidgetConfiguration);
export { ZendeskSupportWidgetConfiguration };
