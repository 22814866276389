import * as OU from '../../util/object-utils';
import { SPACE } from '../../util/string-constants';
import { ISelectorProperties } from '../brand/selector.properties.interface';
import { IFormatter } from '../formatter/i-formatter.interface';
import { TooltipBase } from './base-tooltip';
import * as jp from 'jsonpath';
import { BrandService } from "../brand/brand.service";

const LABELS = "group-labels";
const COLON = ":";
const SEMI_COLON = ";";
const SEMI_COLON_SPACE = SEMI_COLON + SPACE;
const BRAND_SELECTOR = "brand-selector";
const TOOLTIP_HIGHLIGHTED_VALUE_KEY = "tooltip-highlighted-value-key";
const TOOLTIP_VALUE_KEY = "tooltip-value-key";
const TOOLTIP_BRAND_SELECTOR = "tooltip-brand-selector";
const TOOLTIP_POPUP_TITLE_KEY = "tooltip-popup-title-key";
const TOOLTIP_POPUP_SUBTITLE_KEY = "tooltip-popup-subtitle-key";
const SHOW_ZERO_VALUES = "show-zero-values";
const TOOLTIP_POPUP_COLUMN_HEADING_KEY = "tooltip-popup-column-heading-key";
const TOOLTIP_POPUP_COLUMN_TITLE_KEY = "tooltip-popup-column-title-key";
const TOOLTIP_POPUP_ROW_TITLE_KEY = "tooltip-popup-row-title-key";
const TOOLTIP_POPUP_TOTAL_TITLE_KEY = "tooltip-popup-total-title-key";

export class MultiDimensionHorizontalBarVerticalLayoutTooltip extends TooltipBase {

  public override generateTooltipContent(data: any, params?: any): string {
    this._logService.debug("Generating Dimension Horizontal Bar ToolTip of data ", {tooltipData: data, parameter: params});

    const valuePaths = params["value-paths"];
    const brandSelectorConfig = this._config[BRAND_SELECTOR];
    const tooltipStyleBrandSelector = brandSelectorConfig[TOOLTIP_BRAND_SELECTOR];
    const titleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_TITLE_KEY]);
    const subtitleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_SUBTITLE_KEY]);

    const columnHeaderStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_COLUMN_HEADING_KEY]);
    const columnTitleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_COLUMN_TITLE_KEY]);
    const rowTitleStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_ROW_TITLE_KEY]);

    const valueStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_VALUE_KEY]);
    const highlightedValueStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_HIGHLIGHTED_VALUE_KEY]);

    const totalTitleValueStyle = this._getStyleFromBrandSelector(tooltipStyleBrandSelector, brandSelectorConfig[TOOLTIP_POPUP_TOTAL_TITLE_KEY]);
    const seriesData = data.series[0];
    const showZeroValues = this._config[SHOW_ZERO_VALUES] === undefined ? true : this._config[SHOW_ZERO_VALUES];
    let translatedName: string;
    const seriesKeyName = data.data.leftLabel || data.data.name || data.data.key;
    const metaDataArray = data.data.meta;
    let metaData: any;
    if (params.showOtherValue) {
      metaData = metaDataArray.map((s: any) => Object.assign({}, s));
      const all = metaData.shift();
      metaData.push(all);
    } else {
      metaData = metaDataArray;
    }
    let dataFormatter: IFormatter;
    let percentWithBracketsFormatter: IFormatter;
    let percentDefaultFormatter: IFormatter;
    let variancePctLabel = "";
    if (params) {
      if (params.formatter) {
        dataFormatter = this._formatterService.formatter(params.formatter);
      }
      if (params.percentFormatter) {
        percentWithBracketsFormatter = this._formatterService.formatter(params.percentFormatter);
      }
      if (params.percentDefault) {
        percentDefaultFormatter = this._formatterService.formatter(params.percentDefault);
      }
      if (params.titleTranslationKey) {
        translatedName = this._translateService.instant(params.titleTranslationKey);
      } else {
        translatedName = this._translateService.instant(this._config[LABELS][data.index]);
      }
      if (params.variancePctLabel) {
        variancePctLabel = this._translateService.instant(params.variancePctLabel);
      }

    }
    let popupHTML = `
      <table style="width: 95%; max-width: 420px; font-variant: small-caps;">
        <thead>
          <tr style="${titleStyle}"><td colspan="6">${translatedName} Breakdown</td></tr>
          <tr style="${subtitleStyle}">
            <td colspan="6" col class="legend-icon legend-color-guide-area" style="padding-left: 2em;">
              <div style="display:inline-block; background-color:${data.data.selectedColor}; margin-top:2px"></div>
              ${seriesKeyName}
            </td>
          </tr>
        </thead><tbody>`;
    const additionalMeta = metaData[0].additionalMeta.map((labelValue: any) => labelValue.label);
    const currentItemShortLabel = data.data.shortLabel;
    popupHTML += '<tr class="store-popup-text">';
    const pathToValuesLabel = this._translateService.instant(params.pathToValuesLabel);
    popupHTML += `<td colspan="2" style="${columnHeaderStyle}">${pathToValuesLabel}</td>`;
    if (null == valuePaths) {
      for (const adm of additionalMeta) {
        popupHTML += `<td style="${columnHeaderStyle}">${adm}</td>`;
      }
    }
    popupHTML += '</tr>';
    // Get rows and loop through them.
    const metaValues = metaData[0].values.map((x: any) => Object.assign({}, x));
    metaValues.forEach((metaValue: any, metaValueIdx: number) => {

      // Get current value's style.
      const isValueToHighlight = (currentItemShortLabel === metaValue.shortLabel);
      const valueStyling = isValueToHighlight ? highlightedValueStyle : valueStyle;

      // Get formatted value.
      let value;
      if (!metaValue.formatPercent) {
        value = dataFormatter ?
          this._isNumeric(metaValue.value) ?
            dataFormatter.formatData(metaValue.value) : "NA" :
          metaValue.value;
      } else {
        value = percentDefaultFormatter ?
          this._isNumeric(metaValue.value) ?
            percentDefaultFormatter.formatData(metaValue.value) : "NA" :
          metaValue.value;
      }

      // TDs for additionalMetaData.
      const additionalMetaElements = metaData[0].additionalMeta.map((additionalMetaDatum: any, amdIdx: number) => {
        if (params.additionalMetaMappings[metaValueIdx].indexOf(amdIdx) === -1) {
          return '';
        }
        const formatter = this._formatterService.formatter(params.additionalMetaFormatters[amdIdx]);
        const additionalMetaValue = formatter.formatData(additionalMetaDatum.value);
        return `<td style="${ valueStyling }">${additionalMetaValue}</td>`;
      });
      if (params.addVariancePct) {
        const calculatedVariance = this._getCalculatedVariance(metaValues[0].value, metaValues[1].value, variancePctLabel);
        metaValue.calculatedVariancePct = percentDefaultFormatter.formatData(calculatedVariance['value']);
      }
      popupHTML += `
        <tr>
          <td style="${columnTitleStyle}">${metaValue.label || metaValue.shortLabel}</td>
          <td style="${valueStyling}">${value}</td>
          ${ params.addVariancePct ? `<td style="${ valueStyling }">${ metaValue.calculatedVariancePct}</td>` : ''}
          ${additionalMetaElements.join('')}
        </tr>`;
    });
    popupHTML += `</tr></tbody></table>`;
    return popupHTML;
  }

  private _getStyleFromBrandSelector(brandSelector: string, key: string): string {
    const bStyle = this._brandService.styleSelector(brandSelector);
    const props: ISelectorProperties = {id: key};
    if (bStyle.styles(props)) {
      return `${OU.object2String(bStyle.styles(props), COLON, SEMI_COLON_SPACE)};`;
    }
    return "";
  }

}
