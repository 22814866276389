import { TooltipBase } from "./base-tooltip";
import { ITooltip } from "./i-tooltip.interface";
import { ISelector } from "../brand/selector.interface";

import { FormatterService } from "../formatter/formatter.service";
import { TranslateService } from "@ngx-translate/core";

import { BrandService } from "../brand/brand.service";
import * as OU from '../../util/object-utils';

import {
  ISelectorProperties
} from "../brand/selector.properties.interface";

const SPACE = " ";
const UNDERSCORE = "_";
const DASH = "-";
const PLUS = "+";
const COLON = ":";
const SEMI_COLON = ";";
const EMPTY_STRING = "";
const CLASS_RECT_NVBAR = "rect.nv-bar";
const STYLE_TOOLTIP_KEY = "text-key";
const STYLE_TOOLTIP_HUGE_KEY = "text-huge-key";
const STYLE_TOOLTIP_BIG_KEY = "text-big-key";
const STYLE_TOOLTIP_MED_KEY = "text-med-key";
const STYLE_TOOLTIP_SMALL_KEY = "text-small-key";
const STYLE_TEXT_ALIGN_CENTER = "text-align-center";
const UPPER = "upper";
const LOWER = "lower";
const STYLES = "styles";
const COLON_SPACE = ": ";
const BETWEEN = "between";
const INCLUDING = "including";
const TOOLTIP_LABELS = "tooltip-labels";
const TITLE = "title";
const SINGULAR = "singular";
const PLURAL = "plural";
const MIDDLE = "middle";
const END = "end";
const DESCRIPTION = "description";
const IS_SELECTED_ITEM_IN_BUCKET = "isSelectedItemInBucket";
const SELECTED_ITEM_NAME = "selectedItemName";
const VALUE_BRAND_SELECTOR = "value-brand-selector";
const TEXT_BRAND_SELECTOR = "text-brand-selector";
const BRAND_SELECTOR = "brand-selector";

export class HistogramTooltip extends TooltipBase {

  public override generateTooltipContent(data: any, params?: any): string {
    this._logService.debug("Generating Histogram ToolTip of data: ", {tooltipData: data, parameter: params});
    const brandSelectorConfig = this._config[BRAND_SELECTOR];
    const valueBrandSelector = brandSelectorConfig[VALUE_BRAND_SELECTOR];
    const textBrandSelector = brandSelectorConfig[TEXT_BRAND_SELECTOR];

    const index = data.index;
    const seriesData = data.series[0];
    const lBound = seriesData.data[LOWER];
    const uBound = seriesData.data[UPPER];
    const isSelected = seriesData.data[IS_SELECTED_ITEM_IN_BUCKET] !== undefined ? seriesData.data[IS_SELECTED_ITEM_IN_BUCKET] : false;
    const selectedName = isSelected ? (seriesData.data[SELECTED_ITEM_NAME] !== undefined ? seriesData.data[SELECTED_ITEM_NAME] : "") : "";
    const seriesValue = seriesData.value;

    const title = this._getTranslatedVal(this._config[TOOLTIP_LABELS][TITLE]);
    const description = this._getTranslatedVal(this._config[TOOLTIP_LABELS][DESCRIPTION]) + (seriesValue === 1 ? "" : "s");
    const messageSingular = this._getTranslatedVal(this._config[TOOLTIP_LABELS][SINGULAR]);
    const messagePlural = this._getTranslatedVal(this._config[TOOLTIP_LABELS][PLURAL]);
    const messageMiddle = this._getTranslatedVal(this._config[TOOLTIP_LABELS][MIDDLE]);
    const messageEnd = this._getTranslatedVal(this._config[TOOLTIP_LABELS][END]);
    const messageBetween = this._getTranslatedVal(this._config[TOOLTIP_LABELS][BETWEEN]);
    const messageInclude = this._getTranslatedVal(this._config[TOOLTIP_LABELS][INCLUDING]);

    let variesMessage = (seriesData.value === 1 ? messageSingular : messagePlural);
    variesMessage += SPACE + messageMiddle + SPACE;

    const boundsMatch = uBound.toFixed(1) === lBound.toFixed(1);

    if (boundsMatch) {
      variesMessage += messageEnd + SPACE;
    } else {
      variesMessage += messageBetween + SPACE;
    }

    const popUpTableStyle = this._getStyleFromBrandSelector(textBrandSelector, brandSelectorConfig[STYLE_TOOLTIP_KEY]);
    const TooltipBigStyle = this._getStyleFromBrandSelector(textBrandSelector, brandSelectorConfig[STYLE_TOOLTIP_BIG_KEY]);
    const TooltipHugeStyle = this._getStyleFromBrandSelector(textBrandSelector, brandSelectorConfig[STYLE_TOOLTIP_HUGE_KEY]);
    const TooltipMedStyle = this._getStyleFromBrandSelector(textBrandSelector, brandSelectorConfig[STYLE_TOOLTIP_MED_KEY]);
    const TooltipSmallStyle = this._getStyleFromBrandSelector(textBrandSelector, brandSelectorConfig[STYLE_TOOLTIP_SMALL_KEY]);

    let tooltipHTML = EMPTY_STRING;
    tooltipHTML += `<table style="${popUpTableStyle}">`;
    tooltipHTML += `<tbody><thead><tr><td style="${TooltipBigStyle}" colspan="2">` ;
    tooltipHTML +=  title + `</td></tr></thead>`;
    tooltipHTML += `<tbody><tr>`;
    tooltipHTML += `<td align="center"><div style="${TooltipHugeStyle}"` + SPACE + STYLE_TEXT_ALIGN_CENTER + `">` + seriesValue + `</div>`;
    tooltipHTML += `<div style="${TooltipSmallStyle}"` + SPACE + STYLE_TEXT_ALIGN_CENTER + `">` + description + `</div></td>`;
    tooltipHTML += `<td valign="top">`;
    if (isSelected && selectedName !== "") {
        tooltipHTML += `<div style="${TooltipMedStyle}">` ;
        tooltipHTML += (seriesValue !== 1 ? messageInclude + SPACE : EMPTY_STRING) + selectedName + '</div>';
    }
    tooltipHTML += `<span style="${TooltipSmallStyle}">` + variesMessage + `</span><br/><span style="${TooltipBigStyle}">` +
     '<span style="color: ' + this._getColorCodeByValue(lBound, valueBrandSelector) + ';">' + this._getFormattedValue(lBound) + '</span>';
    if (!boundsMatch) {
        tooltipHTML += `  <span style="${TooltipSmallStyle}">and</span>`;
        tooltipHTML += `  <span style="${TooltipBigStyle}">`;
        tooltipHTML +=  `<span style="color: ` + this._getColorCodeByValue(uBound, valueBrandSelector) + `;">` +
         this._getFormattedValue(uBound) + `</span></td>`;
    }
    tooltipHTML += `</tr>`;
    tooltipHTML += `</tbody></table>`;
    return tooltipHTML;
  }

  private _getTranslatedVal(configProp: string): string {
    return this._translateService.instant(configProp);
  }

  private _getFormattedValue(val: number) {
    const formatterConfig = this._config["formatters"];
    const valFormatter = this._formatterService.formatter(formatterConfig.value);
    return valFormatter.formatData(val);
  }

  private _getStyleFromBrandSelector(brandSelector: string, key: string): string {
    const bStyle = this._brandService.styleSelector(brandSelector);
    const props: ISelectorProperties = {id: key};
    return OU.object2String(bStyle.styles(props), COLON_SPACE, SEMI_COLON);
  }

  private _getColorCodeByValue(val: number, brandSelector: string): string {
    const colourSelector = this._brandService.seriesColourSelector(brandSelector);
    const colorSelectionProperties: ISelectorProperties = {
      value: val
    };
    return colourSelector.seriesColour(colorSelectionProperties);
  }
}
