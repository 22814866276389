import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import gql from "graphql-tag";
import * as jp from "jsonpath";
import * as GQL from "../util/graphql-tags";
import { DataService } from "../services/data/data.service";
import { DemoResetDemo, SetDemoState } from "./demo.actions";
import { DimensionAggregationsDemoReset } from "./dimension.actions";
import { SetReferenceContext } from "./reference.actions";
import { TemporalDemoReset } from "./temporal.actions";
import { AUTHENTICATION_COMPLETED } from "./user.actions";
import { UXReset } from "./ux.actions";
import { MutationSuccessful } from './mutation.actions';
import { switchMap } from "rxjs/operators";
import { MutationType } from "../dashboard/components/config/mutation-type.enum";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../services/data/data.service";
import * as i3 from "@ngrx/store";
const _STRIP_DEFAULT_CONTEXT_TAGS = ["guid"];
const _JSON_PATH_DEFAULT_CONTEXT = "$..DefaultContext";
const _JSON_PATH_DEMO_ENABLED = "$..enabled";
const _RESET_DEMO = `
mutation resetDemo {
  Configuration: ResetDemo {
    enabled
  }
}`;
const _DEMO_SETTINGS_QUERY = `
query getDemoConfig {
  Configuration {
    Demo {
      enabled
    }
  }
}`;
export const DEMO_RESET = "DEMO_RESET";
export class DemoEffects {
    constructor(_action$, _dataService, _store) {
        this._action$ = _action$;
        this._dataService = _dataService;
        this._store = _store;
        this.loginSuccessed$ = createEffect(() => this._action$
            .pipe(ofType(AUTHENTICATION_COMPLETED), switchMap(async (action) => {
            const enabled = await this._demoEnabled();
            return new SetDemoState(enabled);
        })));
        this.demoEffect$ = createEffect(() => this._action$
            .pipe(ofType(DEMO_RESET), switchMap(async () => {
            const response = await this._resetDemo();
            if (response.reference) {
                this._store.dispatch(new SetReferenceContext(response.reference));
            }
            if (response.temporal) {
                this._store.dispatch(new TemporalDemoReset(response.temporal));
            }
            if (response.ux) {
                this._store.dispatch(new UXReset(response.ux));
            }
            if (response.dimension) {
                this._store.dispatch(new DimensionAggregationsDemoReset(response.dimension));
            }
            if (response.demo) {
                this._store.dispatch(new DemoResetDemo(response.demo));
            }
            return new MutationSuccessful([MutationType.DemoReset]);
        })));
    }
    async _demoEnabled() {
        const data = await this._demoConfiguration();
        return jp.query(data, _JSON_PATH_DEMO_ENABLED)[0];
    }
    async _resetDemo() {
        const resp = await this._dataService.mutate("ResetDemo", _RESET_DEMO);
        return this._processDemoConfiguration(resp.data);
    }
    async _demoConfiguration() {
        if (undefined !== this._demoGQLData) {
            return this._demoGQLData;
        }
        const queryOptions = {
            query: gql `${_DEMO_SETTINGS_QUERY}`
        };
        const resp = await this._dataService.runQuery("DemoConfiguration", queryOptions);
        return this._processDemoConfiguration(resp.data);
    }
    _processDemoConfiguration(data) {
        const ret = {};
        ret[GQL.SCALAR_ENABLED] = jp.query(data, _JSON_PATH_DEMO_ENABLED)[0];
        this._demoGQLData = ret;
        return ret;
    }
}
DemoEffects.ɵfac = function DemoEffects_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || DemoEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.DataService), i0.ɵɵinject(i3.Store)); };
DemoEffects.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: DemoEffects, factory: DemoEffects.ɵfac });
