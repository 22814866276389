import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from "json2typescript";
import * as GQL from "../../util/graphql-tags";
import { BusinessDate } from '../business-dates/business-date.model';
let Period = class Period {
    constructor() {
        this.type = undefined;
        this.BusinessDate = undefined;
    }
};
Period.NO_PERIOD = {
    type: "NoPeriod",
    BusinessDate: null
};
__decorate([
    JsonProperty(GQL.SCALAR_TYPE, String, false),
    __metadata("design:type", String)
], Period.prototype, "type", void 0);
__decorate([
    JsonProperty(GQL.BUSINESS_DATE, Object, false),
    __metadata("design:type", BusinessDate)
], Period.prototype, "BusinessDate", void 0);
Period = __decorate([
    JsonObject("Period")
], Period);
export { Period };
