import * as fromDemo from "./demo.reducers";
import * as fromDimension from "./dimension.reducers";
import * as fromReference from "./reference.reducers";
import * as fromTemporal from "./temporal.reducers";
import * as fromUser from "./user.reducers";
import * as fromUX from "./ux.reducers";
export const reducers = {
    reference: fromReference.referenceReducer,
    temporal: fromTemporal.temporalReducer,
    user: fromUser.userReducer,
    demo: fromDemo.demoReducer,
    ux: fromUX.uxReducer,
    dimension: fromDimension.dimensionReducer
};
// tslint:disable:max-classes-per-file
export class ReferenceContextKeys {
}
ReferenceContextKeys.LOCATION_ID = "locationID";
ReferenceContextKeys.LOCATION_TYPE_ID = "locationTypeID";
ReferenceContextKeys.ENTITY_ID = "entityID";
ReferenceContextKeys.REGION_ID = "regionID";
ReferenceContextKeys.ACTIVE_SNAPSHOT = "ActiveSnapshot";
export class TemporalContextKeys {
}
TemporalContextKeys.CONTEXT_KEY = "temporal";
TemporalContextKeys.FROM = "from";
TemporalContextKeys.TO = "to";
TemporalContextKeys.LAST = "last";
TemporalContextKeys.NEXT = "next";
TemporalContextKeys.L4L = "L4L";
TemporalContextKeys.DATE_TYPE = "dateType";
export class AggregationContextKeys {
}
AggregationContextKeys.CONTEXT_KEY = "aggregation";
AggregationContextKeys.TEMPORAL = "temporal";
export class UserContextKeys {
}
UserContextKeys.CONTEXT_KEY = "user";
UserContextKeys.PROFILE = "profile";
UserContextKeys.AUTH = "auth";
UserContextKeys.MESSAGE = "message";
export class DemoContextKeys {
}
DemoContextKeys.CONTEXT_KEY = "demo";
DemoContextKeys.ALERT_DISMISSED = "dismissedAlerts";
DemoContextKeys.ADD_PRIORITY_ALERT = "priorityAlert";
export class UXContextKeys {
}
UXContextKeys.CONTEXT_KEY = "ux";
UXContextKeys.RAISE_EVENT = "raiseEvent";
UXContextKeys.CLEAR_EVENT = "clearEvent";
export class DimensionContextKeys {
}
DimensionContextKeys.CONTEXT_KEY = "dimension";
DimensionContextKeys.PRODUCT = "product";
// tslint:enable:max-classes-per-file
