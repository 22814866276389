import { Action } from "@ngrx/store";

import { ReferenceContext } from "./reference.reducers";
import { Snapshot } from "../model/snapshots/snapshot.model";
import { Person } from "../model/person.model";

/* tslint:disable max-classes-per-file */
export const LOCATION_TYPES_CHANGED = "LOCATION_TYPES_CHANGED";
export const ENTITIES_CHANGED = "ENTITIES_CHANGED";
export const REGIONS_CHANGED = "REGIONS_CHANGED";
export const LOCATION_IDS_CHANGED = "LOCATION_IDS_CHANGED";
export const REFERENCE_CONTEXT_OVERWRITE = "REFERENCE_CONTEXT_OVERWRITE";
export const UPDATE_REFERENCE = "UPDATE_REFERENCE";
export const ACTIVE_SNAPSHOT_CHANGED = "ACTIVE_SNAPSHOT_CHANGED";
export const SNAPSHOT_TO_CONTEXT = "SNAPSHOT_TO_CONTEXT";
export const PEOPLE_CHANGED = "PEOPLE_CHANGED";
export const DEPARTMENT_CHANGED = "DEPARTMENT_CHANGED";
export const PARTNER_CHANGED = "PARTNER_CHANGED";
export const PERSON_ADDITIONAL_DATA_CHANGED = "PERSON_ADDITIONAL_DATA_CHANGED";
export const PERSON_RELATIONSHIP_ADDED = "PERSON_RELATIONSHIP_ADDED";
export const PERSON_RELATIONSHIP_DELETED = "PERSON_RELATIONSHIP_DELETED";


export class PersonAdditionalDataChanged implements Action {
  public readonly type = PERSON_ADDITIONAL_DATA_CHANGED;
  constructor(public person: Person) {};
}

export class PersonRelationshipAdded implements Action {
  public readonly type = PERSON_RELATIONSHIP_ADDED;
  constructor(public person: Person, public relatedPerson: Person, public relationshipType: string) {};
}

export class PersonRelationshipDeleted implements Action {
  public readonly type = PERSON_RELATIONSHIP_DELETED;
  constructor(public person: Person, public relatedPerson: Person, public relationshipType: string) {};
}

export class  LocationTypesChanged implements Action {
    public readonly type = LOCATION_TYPES_CHANGED;
    constructor(public payload: string[]) {} // for now I take it as object later we can change this to model or any
}

export class EntitiesChanged implements Action {
  public readonly type = ENTITIES_CHANGED;
  constructor(public payload: string[]) {} // for now I take it as object later we can change this to model or any
}

export class LocationsChanged implements Action {
  public readonly type = LOCATION_IDS_CHANGED;
  constructor(public payload: string[]) {} // for now I take it as object later we can change this to model or any
}

export class RegionsChanged implements Action {
    public readonly type = REGIONS_CHANGED;
    constructor(public payload: string[]) {} // for now I take it as object later we can change this to model or any
}

export class SetReferenceContext implements Action {
  public readonly type = REFERENCE_CONTEXT_OVERWRITE;
  constructor(public context: ReferenceContext) {}
}

export class UpdateReference implements Action {
  public readonly type = UPDATE_REFERENCE;
  constructor(public payload: any) {}
}

export class ActiveSnapshotChanged implements Action {
  public readonly type = ACTIVE_SNAPSHOT_CHANGED;
  constructor(public payload: Snapshot) {}
}
export class SnapshotToContext implements Action {
  public readonly type = SNAPSHOT_TO_CONTEXT;
  constructor() {}
}
export class PeopleChanged implements Action {
  public readonly type = PEOPLE_CHANGED;
  constructor(public payload: string[]) {}
}
export class DepartmentChanged implements Action {
  public readonly type = DEPARTMENT_CHANGED;
  constructor(public payload: string[]) {}
}
export class PartnerChanged implements Action {
  public readonly type = PARTNER_CHANGED;
  constructor(public payload: string[]) {}
}
export type ReferenceActions = EntitiesChanged | LocationTypesChanged | LocationsChanged | RegionsChanged | SetReferenceContext | UpdateReference |
ActiveSnapshotChanged | PeopleChanged | PartnerChanged | DepartmentChanged | PersonAdditionalDataChanged | PersonRelationshipAdded | PersonRelationshipDeleted | SnapshotToContext;
/* tslint:enable max-classes-per-file */
