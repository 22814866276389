import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import {
  MutationType
} from "../dashboard/components/config/mutation-type.enum";
import {
  DataService
} from "../services/data/data.service";
import { Noop } from "./effects";
import {
  ADD_SNAPSHOT_USING_CONTEXT,
  AddSnapshotUsingContext,
  MutationSuccessful
} from "./mutation.actions";
import { User } from "../model/user/user.model";
import * as fromContext from "../context/context";
import * as fromUser from "../context/user.reducers";
import { Store } from "@ngrx/store";
import { switchMap, withLatestFrom } from "rxjs/operators";
import { ACTIVE_SNAPSHOT_CHANGED, ActiveSnapshotChanged, SNAPSHOT_TO_CONTEXT } from "./reference.actions";
import { TemporalService } from "../services/temporal/temporal.service";
import { ContextConfiguration } from "./config/context.config";
import { CurrentBusinessDateChangeComplete } from "./temporal.actions";

const _LOG_ID = "snapshot.effects";

// tslint:disable-next-line:max-line-length
const _ADD_SNAPSHOT_MUTATION = `mutation ($ownerID: String!, $reference: ReferenceInput!, $snapshotGUID: String!) {
  AddSnapshot(Owner: {id: $ownerID}, Reference: $reference, snapshotGUID: $snapshotGUID) {
    guid
    exists
    description
    sequence
    snapshotGUID
    Owner {
      id
      guid
      displayName
    }
    Type {
      guid
      code
      name
      nameRef
      SuperType {
        guid
        code
        nameRef
      }
    }
    Reference {
      nameRef
      Entity {
        id
        guid
        name
      }
      Region {
        id
        guid
        name
      }
      Location {
        id
        guid
        name
      }
      Person {
        id
        guid
        displayName
      }
    }
  }
}
`;

@Injectable()
export class SnapshotEffects {

  // @Effect()
  public snapshotAdded$ = createEffect(() => this._action$
  .pipe(
    ofType(ADD_SNAPSHOT_USING_CONTEXT),
    switchMap(async (action: AddSnapshotUsingContext) => {
    return this.saveSnapshot(action);
    })
  ));

  // @Effect()
  public snapshotUpdated$ = createEffect(() => this._action$
  .pipe(
    ofType(ACTIVE_SNAPSHOT_CHANGED),
    switchMap(async (obj: any) => {
      const {payload} = obj;
      if (null == payload || null == payload.Context || null == payload.Context.context) {
        return new Noop();
      }

      const context = JSON.parse(payload.Context.context);
      const rangeAndAggregation = null != context.temporal ? { ...context.temporal } : null;

      if (null == rangeAndAggregation) {
        return new Noop();
      }

      const temporalContext =
        await this._temporalService.HydrateInitialBaselineDatesAndRanges(
          rangeAndAggregation.range,
          rangeAndAggregation.aggregation,
          ContextConfiguration.LOADED.temporalAggregations
        );
      return new CurrentBusinessDateChangeComplete(temporalContext);
    })
  ));

  // @Effect()
  public SnapshotToContext$ = createEffect(() => this._action$
  .pipe(
    ofType(SNAPSHOT_TO_CONTEXT),
    withLatestFrom(this._store),
    switchMap(async ([, context]) => {
      return new ActiveSnapshotChanged(context.reference.ActiveSnapshot);
    })
  ));

  private _user: User;

  public constructor(
    private _action$: Actions,
    private _temporalService: TemporalService,
    private _store: Store<fromContext.Context>,
    private _dataService: DataService) {
    this._store.select("user").subscribe((newUserContext: fromUser.UserContext) => {
      this._user = newUserContext.profile;
    });
  }

  private async saveSnapshot(action: AddSnapshotUsingContext): Promise<MutationSuccessful | Noop> {
    const snapshotGUID: string = action.payload.snapshotGUID;

    const params = {
      ownerID: this._user.id,
      reference: this._dataService.ActiveReferenceInput,
      snapshotGUID
    };

    try {
      const result = await this._dataService.mutate(_LOG_ID, _ADD_SNAPSHOT_MUTATION, params);
      console.log("SNAPSHOT SAVED");

      return new MutationSuccessful([MutationType.Snapshot]);
    } catch (err) {
      console.log(`SNAPSHOT __NOT__ SAVED!:

      ${err}`);
      return new Noop();
    }
  }

}
