import { __decorate, __metadata } from "tslib";
import { JsonObject, JsonProperty } from "json2typescript";
import { ThresholdConfig } from "./threshold-config.model";
let ThresholdFormatterConfig = class ThresholdFormatterConfig {
    constructor() {
        this.default = undefined;
        this.thresholds = undefined;
        this.nullDefault = true;
    }
};
__decorate([
    JsonProperty("default", ThresholdConfig, true),
    __metadata("design:type", ThresholdConfig)
], ThresholdFormatterConfig.prototype, "default", void 0);
__decorate([
    JsonProperty("thresholds", [ThresholdConfig], false),
    __metadata("design:type", Array)
], ThresholdFormatterConfig.prototype, "thresholds", void 0);
__decorate([
    JsonProperty("null-default", Boolean, true),
    __metadata("design:type", Boolean)
], ThresholdFormatterConfig.prototype, "nullDefault", void 0);
ThresholdFormatterConfig = __decorate([
    JsonObject("ThresholdFormatterConfig")
], ThresholdFormatterConfig);
export { ThresholdFormatterConfig };
